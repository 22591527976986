import React from "react";

export default function XeerDEXDev() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Partner with Xeer Technology for Order Book DEX Development
            </h2>
            <p className="text-white py-4">
              With Xeer Technology’s Order Book DEX development services, you
              can create a decentralized trading platform that combines
              reliability, security, and user empowerment. Our comprehensive
              solutions position your project for success in the ever-evolving
              DeFi ecosystem.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
