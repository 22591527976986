import React from "react";
import Slider from "react-slick";

export default function IconCurve() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="contact-info-section centred pt_80 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Popular DeFi Yield Farming Platforms</h2>
            <p className="py-3">
              Yield farming has become a cornerstone in the decentralized
              finance (DeFi) ecosystem, allowing users to earn passive income by
              providing liquidity to various protocols. Below are some of the
              most popular platforms that have revolutionized yield farming,
              offering diverse rewards and risk profiles.
            </p>
          </div>
          <div className="slider-container ">
            <Slider {...settings}>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/uniswap.svg"
                    alt="uniswap"
                  />
                  <p>Uniswap</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/curve.svg"
                    alt="curve"
                  />
                  <p>Curve</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/raydium.svg"
                    alt="raydium"
                  />
                  <p>Raydium</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/balancer.svg"
                    alt="balancer"
                  />
                  <p>Balancer</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/aave.svg"
                    alt="aave"
                  />
                  <p>Aave</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/panckswap.svg"
                    alt="panckswap"
                  />
                  <p>Pancakeswap</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/sushiswap.svg"
                    alt="sushiswaps"
                  />
                  <p>Sushiswap</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/yearn_finance.svg"
                    alt="yearn_finance"
                  />
                  <p>Yearn Finance</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/ether_fi.svg"
                    alt="ether_fi"
                  />
                  <p>Ether.fi</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/maker_dao.svg"
                    alt="maker_dao"
                  />
                  <p>MakerDAO</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="py-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/defi/defi-y-f/ethena.svg"
                    alt="ethena"
                  />
                  <p>Ethena</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
