import React from "react";
import Header from "../Coman/Header";
import LookingD from "../About-Us/LookingD";
import Footar from "../Coman/Footar";
import TradingBotDevCom from "./TradingBotDevCom";
import Automated from "./Automated";
import MarginTradingBots from "./MarginTradingBots";
import TradingBotDevPro from "./TradingBotDevPro";
import AutomateYour from "./AutomateYour";
import WhyCXeerTforYourCrypto from "./WhyCXeerTforYourCrypto";
import { Helmet } from "react-helmet";

export default function TradingBot() {
  return (
    <>
      <Helmet>
        <title>Crypto Trading Bot Development | Xeer Technology</title>
        <meta
          name="description"
          content="Automate trading strategies with Xeer’s custom trading bot solutions. High-performance, secure, and tailored to your trading needs."
        />
      </Helmet>
      <div>
        <Header />
        <TradingBotDevCom />
        <Automated />
        <MarginTradingBots />
        <TradingBotDevPro />
        <AutomateYour />
        <WhyCXeerTforYourCrypto />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
