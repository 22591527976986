import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DefiWalletHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="defi_wallet_development_hero_section_image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/wallet/defi_wallet_development_hero_section_image.png)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column my_80">
              <div className="content-box ">
                <div data-animation-box className="sec-title  mb_25">
                  <h2 className="text-white">DeFi Wallet Development</h2>
                </div>
                <p className="text-white">
                  At Xeer Technology, we specialize in developing advanced,
                  secure, and customizable DeFi wallets that empower users to
                  interact seamlessly with decentralized finance (DeFi)
                  platforms.
                </p>
              </div>
              <div className="btn-box pt-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
