import React from "react";

export default function Comprehensive() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Stunning features of our DeFi Wallet</h2>
            <p className="pt-2">
              Following are all the key features that Xeer Technology offers
              with DeFi wallet development service;
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "300px" }}>
                  <h3>Multi-Blockchain Compatibility</h3>
                  <p>
                    Our DeFi wallets support multiple blockchain networks,
                    including Ethereum, Binance Smart Chain (BSC), Solana,
                    Polygon, and more. This allows users to access and interact
                    with various DeFi ecosystems without limitations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "300px" }}>
                  <h3>Non-Custodial Wallets</h3>
                  <p>
                    Xeer Technology develops non-custodial wallets, ensuring
                    that users retain full control over their private keys and
                    funds. We prioritize security and ownership, providing peace
                    of mind while transacting on DeFi platforms.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "300px" }}>
                  <h3>Seamless Integration with DeFi Protocols</h3>
                  <p>
                    Our DeFi wallets are built for seamless interaction with
                    decentralized finance protocols, enabling users to easily
                    perform activities like staking, yield farming, lending,
                    borrowing, and liquidity provision across DeFi platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    Designed with a focus on ease of use, our wallets offer a
                    simple and intuitive interface that ensures users can
                    navigate the world of decentralized finance effortlessly,
                    regardless of their experience level.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <h3>Integrated DApp Browser</h3>
                  <p>
                    Our wallets feature an integrated DApp (Decentralized
                    Application) browser, allowing users to explore and connect
                    with various DeFi applications directly from the wallet.
                    Whether you want to swap tokens, access decentralized
                    exchanges, or use yield farming platforms, everything is
                    available at your fingertips.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <h3>Enhanced Security Features</h3>
                  <p>
                    Security is paramount in the DeFi space. Our wallets come
                    equipped with multi-factor authentication (MFA), biometric
                    security, encryption, and secure private key storage. We
                    also support multi-signature authorization for added
                    protection.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <h3>Support for DeFi Tokens & Assets</h3>
                  <p>
                    Our wallets support a wide variety of DeFi tokens, including
                    ERC-20, BEP-20, and others. Additionally, users can manage
                    NFTs (non-fungible tokens) and other digital assets in the
                    same wallet, providing a comprehensive DeFi experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "287px" }}>
                  <h3>Real-Time Transaction Monitoring</h3>
                  <p>
                    Keep track of all your transactions with real-time
                    monitoring and detailed transaction history. This feature
                    helps users stay informed about their DeFi activities and
                    ensures transparency in every interaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <h3>Cross-Platform Syncing</h3>
                  <p>
                    Xeer Technology ensures that your DeFi wallet can be
                    accessed across multiple platforms, including mobile (iOS
                    and Android), desktop, and web applications. This ensures
                    convenience and continuity in managing your DeFi assets on
                    any device.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
