import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import EthereumSolutionHero from "./EthereumSolutionHero";
import BlockchainDevelopmentServices from "./BlockchainDevelopmentServices";
import ExpertstoDiscuss from "./ExpertstoDiscuss";
import Industriesthat from "./Industriesthat";
import EthereumDevelopmentServices from "./EthereumDevelopmentServices";
import { Helmet } from "react-helmet";

export default function EthereumSolution() {
  return (
    <>
      <Helmet>
        <title>
          Ethereum Blockchain Development Services | Xeer Technology
        </title>
        <meta
          name="description"
          content="Develop innovative blockchain solutions on Ethereum. Xeer offers smart contracts, dApps, and token development services."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <EthereumSolutionHero />
        <ExpertstoDiscuss />
        <BlockchainDevelopmentServices />
        <EthereumDevelopmentServices />
        <Industriesthat />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
