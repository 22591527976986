import React from "react";

export default function WhatIsAmmDEX() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is AMM DEX?</h2>
              <p>
                An AMM DEX (Automated Market Maker Decentralized Exchange) is a
                type of decentralized exchange that uses algorithms to
                facilitate trading between assets without relying on traditional
                order books. Instead of matching buy and sell orders between
                users, AMM DEXs employ liquidity pools and automated pricing
                algorithms to allow users to trade directly against pooled
                liquidity.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
