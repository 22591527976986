import React from "react";
import Header from "../Coman/Header";
import WhiteLabelNFTHero from "./WhiteLabelNFTHero";
import Footar from "../Coman/Footar";
import TheAverage from "./TheAverage";
import Hassle from "./Hassle";
import BuildARobust from "./BuildARobust";
import WeGoBeyond from "./WeGoBeyond";
import EventTicketing from "./EventTicketing";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function WhiteLabelNFT() {
  return (
    <>
      <Helmet>
        <title>NFT Collection Launch Services | Xeer Technology</title>
        <meta
          name="description"
          content=" Launch your NFT collection with ease. Xeer Technology offers end-to-end services for minting, marketing, and marketplace listing."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <WhiteLabelNFTHero />
        <TheAverage />
        <Hassle />
        <BuildARobust />
        <WeGoBeyond />
        <EventTicketing />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
