import React from "react";
import Slider from "react-slick";

export default function AssetManagement() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="working-style-three about-page centred  py_120">
        <div className="pattern-layer">
          <div
            className="pattern-1 float-bob-y"
            style={{ backgroundImage: "url(assets/images/shape/shape-39.png)" }}
          />
          <div
            className="pattern-2 float-bob-y"
            style={{ backgroundImage: "url(assets/images/shape/shape-31.png)" }}
          />
        </div>
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Explore Our DeFi Development <br /> Services & Platforms We Offer
            </h2>
            <p>
              At Xeer Technology, we offer a comprehensive suite of DeFi
              development services designed to cater to the diverse needs of
              businesses and entrepreneurs in the decentralized finance space.
            </p>
          </div>
          <div className="upper-box mb_50 p_relative">
            <div className="image-shape">
              <div
                className="shape-2 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-37.png)",
                }}
              />
              <div
                className="shape-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-38.png)",
                }}
              />
            </div>
            <div className="slider-container">
              <Slider {...settings}>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    Lending & Borrowing Platforms
                  </span>
                  <p className="pb-4 px-5">
                    We develop decentralized lending and borrowing platforms
                    that allow users to lend their assets or take out loans
                    without traditional intermediaries. Our solutions ensure
                    secure transactions, transparent interest rates, and smart
                    contract automation, providing a seamless experience for
                    both lenders and borrowers.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/Lending-&-Borrowing-Platforms.jpg"
                    alt="Lending-&-Borrowing-Platforms"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    Staking Solutions
                  </span>
                  <p className="pb-4 px-5">
                    Our staking platforms enable users to earn rewards by
                    locking their cryptocurrencies in a secure environment. We
                    create user-friendly interfaces that simplify the staking
                    process, allowing users to easily participate, manage their
                    staked assets, and track their earnings, all while ensuring
                    the highest level of security through robust smart contract
                    protocols.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/Staking-Solutions.jpg"
                    alt="defi-dev/Staking-Solutions"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    Yield Farming
                  </span>
                  <p className="pb-4 px-5">
                    With our yield farming solutions, users can maximize their
                    returns on investments by providing liquidity to various
                    DeFi protocols. We build platforms that automate the yield
                    farming process, allowing users to effortlessly switch
                    between pools to earn the best possible yields while
                    minimizing risks associated with impermanent loss.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/Yield-Farming.jpg"
                    alt="Yield-Farming"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    Crowdfunding Platforms
                  </span>
                  <p className="pb-4 px-5">
                    We design and develop decentralized crowdfunding platforms
                    that facilitate fundraising for startups and projects
                    without the need for intermediaries. Our solutions empower
                    project owners to raise capital directly from backers while
                    providing transparent tracking and reporting of funds
                    raised, ensuring trust and accountability.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/Crowdfunding-Platforms.jpg"
                    alt="Crowdfunding-Platforms"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    DeFi Loans
                  </span>
                  <p className="pb-4 px-5">
                    Our DeFi loan platform enables users to access quick and
                    easy loans by leveraging their crypto assets as collateral.
                    We create smart contract-based solutions that automate loan
                    issuance, repayment, and collateral management, ensuring a
                    secure and efficient borrowing experience with minimal
                    barriers.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/DeFi-Loans.jpg"
                    alt="DeFi-Loans"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    DeFi Gaming
                  </span>
                  <p className="pb-4 px-5">
                    We specialize in developing DeFi gaming platforms that
                    integrate blockchain technology with gaming mechanics,
                    allowing players to earn rewards through gameplay. Our
                    solutions enable players to trade, stake, and earn in-game
                    assets as NFTs, creating engaging experiences while driving
                    community growth.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/DeFi-Gaming.jpg"
                    alt="DeFi-Gaming"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    Betting Platforms
                  </span>
                  <p className="pb-4 px-5">
                    Our decentralized betting platforms revolutionize the gaming
                    industry by enabling users to place bets on various events
                    without the need for a central authority. We ensure secure,
                    transparent, and tamper-proof betting experiences by
                    leveraging smart contracts to manage bets, payouts, and odds
                    dynamically.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/Betting-Platforms.jpg"
                    alt="Betting-Platforms"
                  />
                </div>
                <div className="text-center p-4">
                  <span style={{ fontSize: "25px" }} className="sub-title-two">
                    And More
                  </span>
                  <p className="pb-4 px-5">
                    Beyond these core offerings, Xeer Technology provides a wide
                    range of additional DeFi development services tailored to
                    meet specific business needs. Whether you’re looking to
                    create automated trading systems, implement liquidity
                    management tools, or explore innovative financial products,
                    our team is ready to collaborate and bring your vision to
                    life.
                  </p>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/defi/defi-dev/and-more.jpg"
                    alt="and-more"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
