import React from "react";

export default function WhatInch() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is DEX Aggregator?</h2>
              <p>
                A DEX (Decentralized Exchange) aggregator is a platform or
                protocol that sources liquidity from multiple decentralized
                exchanges, allowing users to access the best prices and lowest
                slippage on their trades. Instead of a trader manually checking
                different exchanges for optimal rates, a DEX aggregator
                automates this by scanning multiple DEXs and executing the trade
                across the best combination of them to ensure the best outcome
                in terms of price, liquidity, and transaction fees.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
