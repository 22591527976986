import React from "react";

export default function TradingBotDevPro() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Crypto Trading Bot Development Process</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <div className="num-box">
                      <h1>01</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Consultation & Strategy Development
                  </h3>
                  <p className="text-start">
                    We begin by understanding your trading goals and defining a
                    bot strategy tailored to meet your requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <div className="num-box">
                      <h1>02</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Bot Algorithm Design & Development
                  </h3>
                  <p className="text-start">
                    Our developers create custom algorithms, integrating them
                    with the latest data feeds and AI-powered analytics for
                    real-time performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <div className="num-box">
                      <h1>03</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Testing & Quality Assurance
                  </h3>
                  <p className="text-start">
                    Rigorous testing and backtesting ensure the bot’s
                    reliability, accuracy, and performance before it goes live.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <div className="num-box">
                      <h1>04</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Deployment & Ongoing Support
                  </h3>
                  <p className="text-start">
                    We assist with setup, provide training, and offer continuous
                    support and updates to keep your bot optimized for evolving
                    market conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
