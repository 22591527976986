import React from "react";

export default function UseExisting() {
  return (
    <>
      <section className="feature-section py_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  style={{ width: "400px" }}
                  src="assets/img/uUse-existing-wallet-designs-to-boost-your-credibility.png"
                  alt="uUse-existing-wallet-designs-to-boost-your-credibility"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title">
                  <h2>Use Existing Wallet Designs TO Boost Your Credibility</h2>
                </div>
                <div className="">
                  <p className="py-2">
                    Xeer Technology offers Crypto Wallet Clone app development
                    services that allow you to launch a fully customizable
                    wallet solution inspired by top-performing wallets like
                    MetaMask, Trust Wallet, or Coinbase Wallet. Our clone apps
                    come equipped with all the essential features, such as
                    secure storage, multi-asset support, private key management,
                    and seamless interaction with decentralized applications
                    (dApps).
                  </p>
                  <p>
                    Our development process ensures that your wallet clone app
                    is tailored to meet your specific requirements, offering
                    additional functionality like multi-signature
                    authentication, in-app token swaps, and integrated staking
                    or yield farming features.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
