import React from "react";

export default function BeAPart() {
  return (
    <>
      <section className="feature-section  py_150">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  style={{ width: "450px" }}
                  src="assets/img/web3_wallet_development_service_by_xeer_technology.svg"
                  alt="web3_wallet_development_service_by_xeer_technology"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h1>Web3 Wallet Development Service by Xeer Technology</h1>
                <p className="pt-3">
                  Xeer Technology offers cutting-edge Web3 wallet development
                  services, providing businesses with secure, scalable, and
                  user-friendly wallets that support seamless interaction with
                  decentralized applications (dApps) and blockchain networks.
                  Our solutions are designed to give users full control over
                  their private keys, ensuring maximum security and privacy.
                </p>
                <p className="pt-3">
                  We integrate multi-currency support, advanced encryption,
                  KYC/AML compliance, and cross-platform compatibility, creating
                  a versatile wallet that can handle transactions and engage
                  with the broader Web3 ecosystem. From DeFi and NFTs to smart
                  contract interaction, our Web3 wallets are optimized for
                  performance, security, and a superior user experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
