import React from "react";

export default function ProjectSerum() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our Crypto Gaming Solutions</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "325px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/crypto-g-dev/svg/nft_integration.svg"
                      alt="nft_integration"
                    />
                  </div>
                  <h3>NFT Integration</h3>
                  <p>
                    Create in-game assets as NFTs that players can own, earn or
                    trade in secondary markets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "325px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/crypto-g-dev/svg/play_to_earn_models.svg"
                      alt="play_to_earn_models"
                    />
                  </div>
                  <h3>Play-to-Earn Models</h3>
                  <p>
                    Reward systems that let players earn cryptocurrency,
                    increasing game engagement and retention.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "325px" }}>
                  <div className="pb-1">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/crypto-g-dev/svg/smart_contracts_blockchain.svg"
                      alt="smart_contracts_blockchain"
                    />
                  </div>
                  <h3>Smart Contracts & Blockchain</h3>
                  <p>
                    Ensuring seamless, secure transactions and asset management
                    with smart contracts on blockchain networks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "325px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/crypto-g-dev/svg/defi_integration.svg"
                      alt="defi_integration"
                    />
                  </div>
                  <h3>DeFi Integration</h3>
                  <p>
                    DeFi features like staking, yield farming, and liquidity
                    mining for a richer gaming experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
