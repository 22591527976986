import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CustomLayer2Hero from "./CustomLayer2Hero";
import CustomLayer2Main from "./CustomLayer2Main";
import CustomLayer2Ethereum from "./CustomLayer2Ethereum";
import CustomLayer2Wallet from "./CustomLayer2Wallet";
import CustomLayer2Development from "./CustomLayer2Development";
import { Helmet } from "react-helmet";

export default function CustomLayer2() {
  return (
    <>
      <Helmet>
        <title>Cardano Blockchain Development | Xeer Technology</title>
        <meta
          name="description"
          content="Secure and scalable Cardano solutions. Xeer delivers cutting-edge blockchain applications for the Cardano ecosystem."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <CustomLayer2Hero />
        <CustomLayer2Main />
        <CustomLayer2Ethereum />
        <CustomLayer2Development />
        <CustomLayer2Wallet />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
