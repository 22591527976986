import React from "react";

export default function CustomLayer2Development() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "rgb(7, 21, 34)" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Empower Your Project with Cardano’s Potential
            </h2>
            <p className="text-white py-3">
              Partner with Xeer Technology to bring your Cardano-based
              blockchain project to life with our tailored development
              solutions. Contact us to learn how Cardano can support your vision
              for a sustainable, scalable future.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one m_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
