import React from "react";

export default function TechnologyOffers() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container pt-5">
          <div data-animation-box className="sec-title mb_55">
            <div className="inner_wrap">
              <h2>Our Services</h2>
            </div>
            <p>
              Xeer Technology offers various services to help you build
              blockchain applications.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/project_design.svg"
                      alt="project_design"
                    />
                  </div>
                  <h3 className="pt-4">Project Design</h3>
                  <p>
                    Getting started from scratch to represent your idea into a
                    demo <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/development.svg"
                      alt="development"
                    />
                  </div>
                  <h3 className="pt-3">Development</h3>
                  <p>
                    Developing a complete blockchain project from your idea.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/consulting.svg"
                      alt="consulting"
                    />
                  </div>
                  <h3 className="pt-3">Consulting</h3>
                  <p>
                    How to move forward with blockchain? Connect to our experts!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/research_&_project_planning.svg"
                      alt="research_&_project_planning"
                    />
                  </div>
                  <h3 className="pt-3">Research & Project Planning</h3>
                  <p>
                    We will guide you in achieving desirable results for your
                    project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
