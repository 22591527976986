import React from "react";

export default function OPStack() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  className="img-fluid"
                  src="assets/img/polygon/scalable-ethereum-solutions-section-image.png"
                  alt="scalable-ethereum-solutions-section-image"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <div data-animation-box className="sec-title mb-3">
                  <h2>
                    OP Stack: The Foundation for Scalable Ethereum Solutions
                  </h2>
                </div>
                <p>
                  The OP Stack offers a modular, open-source development
                  framework designed for Ethereum-compatible applications. Its
                  modular architecture allows for the creation of layer-2
                  solutions that reduce congestion and transaction costs while
                  maintaining Ethereum’s security and decentralization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
