import React from "react";

export default function OrderBDEXByXeer() {
  return (
    <>
      <section className="faq-style-three py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_50 centred">
            <h2>Order Book DEX Development by Xeer Technology</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30">
                <h1>
                  Build a Robust Decentralized Trading Platform with Xeer
                  Technology
                </h1>
                <p className="py-3">
                  At Xeer Technology, we specialize in developing
                  high-performance Order Book Decentralized Exchanges (DEXs)
                  that combine the best features of traditional trading systems
                  with the advantages of blockchain technology. Our custom Order
                  Book DEX solutions provide users with a familiar trading
                  experience while ensuring security, transparency, and
                  decentralization.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_30">
                <figure className="image">
                  <img
                    style={{ width: "350px" }}
                    src="assets/img/dex/Build-a-Robust-Decentralized-Trading-Platform-with-Xeer-Technology-Section-Image.png"
                    alt="Build-a-Robust-Decentralized-Trading-Platform-with-Xeer-Technology-Section-Image"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
