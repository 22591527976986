import React from "react";

export default function Layer2Performance() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "rgb(7, 21, 34)" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Build on Polkadot with Xeer Technology
            </h2>
            <p className="text-white py-3">
              Experience the power of Polkadot with Xeer’s expert development
              services. Reach out today to explore how we can bring your
              cross-chain vision to life with Polkadot.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one m_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
