import React from "react";

export default function TalkToExperts() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              By bridging the gap between physical assets and the digital world,
              our services enable you to enhance liquidity, verify ownership,
              and create new value streams!
            </h2>
            <p className="text-white py-3">
              Whether you're looking to fractionalize ownership, streamline
              transactions, or create a marketplace for your assets, our NFT
              solutions are designed to unlock limitless potential and drive
              innovation in your ecosystem.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
