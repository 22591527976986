import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import NFTGameDev from "./NFTGameDev";
import PresentingNFT from "./PresentingNFT";
import GamingPlatform from "./GamingPlatform";
import AppWeUSE from "./AppWeUSE";
import PopularUse from "./PopularUse";
import WhyChooseNFTGaming from "./WhyChooseNFTGaming";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function NFTGaming() {
  return (
    <>
      <Helmet>
        <title>NFT Gaming Development | Xeer Technology</title>
        <meta
          name="description"
          content=" Revolutionize gaming with NFT integration. Xeer Technology creates secure and engaging blockchain-based gaming solutions."
        />
      </Helmet>
      <div>
        <Header />
        <NFTGameDev />
        <PresentingNFT />
        <GamingPlatform />
        <AppWeUSE />
        <PopularUse />
        <WhyChooseNFTGaming />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
