import React from "react";

export default function Essential() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Connect to us for your MLM Plan Development</h2>
            <p className="pt-3">
              Contact Xeer Technology today to discuss how we can create a
              solution that accelerates your MLM success.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/customized_mlm_plans.svg"
                      alt="customized_mlm_plans"
                      width={45}
                      height={45}
                      className="mb-3"
                    />
                  </div>
                  <h3>Customized MLM Plans</h3>
                  <p>
                    We offer a range of MLM plans including Binary, Matrix,
                    Unilevel, and more, allowing you to select a structure that
                    aligns with your business strategy and revenue model.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/scalable.svg"
                      alt="scalable"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Scalable, High-Performance Platform</h3>
                  <p>
                    Our solutions are built to scale, supporting high traffic
                    volumes and large user bases while ensuring smooth
                    performance and minimal downtime.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/user_friendly_interface.svg"
                      alt="user_friendly_interface"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    A simple, intuitive interface makes it easy for members to
                    join, track their network, and monitor earnings, enhancing
                    user engagement and satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/comprehensive_security.svg"
                      alt="comprehensive_security"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Comprehensive Security</h3>
                  <p>
                    Security is our top priority. Our platforms feature
                    multi-layered security measures, data encryption, and
                    regular audits to protect your users’ information and
                    transaction history.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
