import React from "react";

export default function Effective() {
  return (
    <>
     
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Ethereum Wallet Development Service</h2>
              <p>
                Xeer Technology specializes in creating secure, user-friendly
                wallets that facilitate seamless transactions and management of
                Ethereum-based assets. Our solutions incorporate advanced security
                features and a scalable architecture, ensuring a reliable
                experience for both novice and experienced users. Whether you’re
                launching a new platform or enhancing your existing services,
                trust us to deliver a future-proof wallet that meets your unique
                needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
