import React from "react";

export default function Exclusive() {
  return (
    <>
      <section className="contact-info-section centred pt_120 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title pt-5 m_55">
            <h2 className="p-3 ">
              Why Crypto Market Making Service is Essential
            </h2>
            <p className="pt-3">
              Crypto market making plays a crucial role in ensuring healthy
              market dynamics, enabling smoother trading experiences, and
              supporting the growth of the cryptocurrency ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/liquidity_enhancement.svg"
                      alt="liquidity_enhancement"
                    />
                  </div>
                  <h3>Liquidity Enhancement</h3>
                  <p>
                    Market making ensures that there are enough buy and sell
                    orders in the market, making it easier for traders to
                    execute their transactions without significantly affecting
                    the asset's price. This is especially important for new or
                    less liquid tokens, where trading activity might otherwise
                    be limited.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "110px" }}
                      src="assets/img/exchange/mms/price_stability.svg"
                      alt="price_stability"
                    />
                  </div>
                  <h3>Price Stability</h3>
                  <p>
                    By continuously providing buy and sell orders, market makers
                    help minimize price volatility. This stability is crucial
                    for maintaining investor confidence and encouraging more
                    trading activity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/efficient_price_discovery.svg"
                      alt="efficient_price_discovery"
                    />
                  </div>
                  <h3>Efficient Price Discovery</h3>
                  <p>
                    Market makers contribute to a more accurate and fair price
                    discovery process. Their constant activity helps reflect
                    real market conditions, allowing traders to see true asset
                    values.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/reduced_slippage.svg"
                      alt="reduced_slippage"
                    />
                  </div>
                  <h3>Reduced Slippage</h3>
                  <p>
                    With ample liquidity, traders experience less slippage—the
                    difference between the expected price of a trade and the
                    actual price—resulting in better execution prices and
                    overall trading experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/support_for_new_tokens.svg"
                      alt="support_for_new_tokens"
                    />
                  </div>
                  <h3>Support for New Tokens</h3>
                  <p>
                    Newly launched cryptocurrencies often face challenges in
                    gaining traction. Market making services provide the
                    necessary liquidity to attract traders and encourage market
                    participation, facilitating a successful launch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/market_depth.svg"
                      alt="market_depth"
                    />
                  </div>
                  <h3>Market Depth</h3>
                  <p>
                    Market makers enhance the overall depth of the market,
                    meaning that larger orders can be executed without
                    drastically impacting the asset's price. This is
                    particularly important for institutional traders who may
                    wish to buy or sell significant volumes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
