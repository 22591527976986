import React from "react";

export default function InitialMeet() {
  return (
    <>
      <section
        className="feature-style-two p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div data-animation-box className="sec-title">
            <h3 className="text-white">
              By bridging the gap between physical assets and the digital world,
              our services enable you to enhance liquidity, verify ownership,
              and create new value streams!
            </h3>
            <p className="text-white py-4">
              Whether you're looking to fractionalize ownership, streamline
              transactions, or create a marketplace for your assets, our NFT
              solutions are designed to unlock limitless potential and drive
              innovation in your ecosystem.
            </p>
          </div>
          <div className="btn-box">
            <a href="/contact-us" className="theme-btn btn-one">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
