import React from "react";

export default function Binance() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2> Top Crypto Exchange We Work With</h2>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/gate.svg"
                    alt="gate"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/kucoin.svg"
                    alt="kucoin"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div
                  className="py-3 px-3"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    className="rounded mx-auto d-block pt-4"
                    src="assets/img/listing/svg/bybit.svg"
                    alt="bybit"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/binance.svg"
                    alt="binance"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/coinw.svg"
                    alt="coinw"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block pt-3"
                    src="assets/img/listing/svg/mexc.svg"
                    alt="mexc"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/bitmart.svg"
                    alt="bitmart"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
