import React from "react";

export default function OurMLMScripts() {
  return (
    <>
      <section
        className="integrations-section centred py_60"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white pb-3">Our MLM Scripts</h2>
              <p className="text-white">
                Xeer Technology offers a wide range of ready-to-use MLM
                softwares that can be integrated to existing platforms!
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/investment_mlm_script.svg"
                        alt="investment_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Investment MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/binary_mlm_script.svg"
                        alt="binary_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Binary MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/bitcoin_mlm_script.svg"
                        alt="bitcoin_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Bitcoin MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/board_mlm_script.svg"
                        alt="board_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Board MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/cryptocurrency_mlm_script.svg"
                        alt="cryptocurrency_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Cryptocurrency MLM Script
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/donation_mlm_script.svg"
                        alt="donation_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Donation MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/ecommerce_mlm_script.svg"
                        alt="ecommerce_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Ecommerce MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/direct_selling_mlm_script.svg"
                        alt="direct_selling_mlm_script"
                        width={70}
                        height={70}
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Direct Selling MLM Script
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/mlm/gaming_mlm_script.svg"
                        alt="gaming_mlm_script"
                        width={55}
                        height={55}
                      />
                    </div>
                    <h5 className="pt-4 text-white">Gaming MLM Script</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
