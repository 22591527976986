import React from "react";

export default function DefiBCon() {
  return (
    <>
      <section className="contact-info-section centred p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Stunning Features of Our DeFi Lending & Borrowing Platform
              Development
            </h2>
            <p>
              At Xeer Technology, we build powerful DeFi lending and borrowing
              platforms with unique features that set them apart in
              functionality, security, and user experience.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/smart_contract_driven_operations.svg"
                      alt="smart_contract_driven_operations"
                    />
                  </div>
                  <h3>Smart Contract-Driven Operations</h3>
                  <p>
                    At the core of our development is the integration of highly
                    secure and automated smart contracts. These eliminate the
                    need for intermediaries, automating the lending and
                    borrowing processes and ensuring transparent, trustless
                    transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/collateralized_loans.svg"
                      alt="collateralized_loans"
                    />
                  </div>
                  <h3>Collateralized Loans</h3>
                  <p>
                    Our platform allows users to borrow against their crypto
                    assets by staking them as collateral. The collateral is
                    securely held in smart contracts until the loan is repaid,
                    giving borrowers quick access to liquidity without selling
                    their assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/customizable_interest_rates.svg"
                      alt="customizable_interest_rates"
                    />
                  </div>
                  <h3>Customizable Interest Rates</h3>
                  <p>
                    We implement algorithmic interest rate models based on
                    supply and demand, ensuring competitive rates for both
                    lenders and borrowers. You can customize rate models to suit
                    your platform’s specific requirements and market conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/non_custodial_framework.svg"
                      alt="non_custodial_framework"
                    />
                  </div>
                  <h3>Non-Custodial Framework</h3>
                  <p>
                    Your users maintain full control of their assets throughout
                    the lending and borrowing process. Our non-custodial design
                    ensures that funds are stored in user-owned wallets,
                    enhancing security and reducing risk.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/multi_asset_support.svg"
                      alt="multi_asset_support"
                    />
                  </div>
                  <h3>Multi-Asset Support</h3>
                  <p>
                    Our development supports a wide range of cryptocurrencies,
                    stablecoins, and tokens, allowing your platform to cater to
                    various asset types and broaden its appeal to a global
                    audience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/yield_generation_for_lenders.svg"
                      alt="yield_generation_for_lenders"
                    />
                  </div>
                  <h3>Yield Generation for Lenders</h3>
                  <p>
                    Lenders can deposit their crypto assets into liquidity pools
                    to generate passive income. With our secure and optimized
                    yield farming mechanisms, your platform will offer
                    sustainable and attractive rewards for liquidity providers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/cross_chain_integration.svg"
                      alt="cross_chain_integration"
                    />
                  </div>
                  <h3>Cross-Chain Integration</h3>
                  <p>
                    We develop DeFi platforms with cross-chain compatibility,
                    enabling users to lend and borrow assets across multiple
                    blockchain networks, improving liquidity and access to a
                    broader range of decentralized ecosystems.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/defi/lending/governance_models.svg"
                      alt="governance_models"
                    />
                  </div>
                  <h3>Governance Models</h3>
                  <p>
                    Incorporate decentralized governance with token-based voting
                    systems to give your platform's users the power to influence
                    key decisions. This promotes community-driven development
                    and ensures long-term platform sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
