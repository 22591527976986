import React from "react";

export default function Fundamental() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_25">
            <h3>
              Benefits of Binance Smart Chain Development with
              <span className="xeer-color"> Xeer Technology</span>
            </h3>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/low_fees_and_fast_transactions.svg"
                              alt="low_fees_and_fast_transactions"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">
                            Low Fees and <br /> Fast Transactions
                          </h5>
                        </div>
                        <p>
                          BSC’s efficient infrastructure enables low-cost, rapid
                          transactions—ideal for DeFi and NFT platforms.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/ethereum_compatibility.svg"
                              alt="ethereum_compatibility"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Ethereum Compatibility</h5>
                        </div>
                        <p>
                          BSC’s EVM compatibility allows for easy migration of
                          Ethereum-based projects to BSC, expanding your reach.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/scalable_and_secure.svg"
                              alt="scalable_and_secure"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Scalable and Secure</h5>
                        </div>
                        <p>
                          Xeer ensures scalability and top-tier security,
                          delivering robust solutions that accommodate high
                          transaction volumes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/comprehensive_support.svg"
                              alt="comprehensive_support"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Comprehensive Support</h5>
                        </div>
                        <p>
                          From development to deployment and beyond, Xeer
                          Technology provides end-to-end support for your BSC
                          project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="./assets/img/layer/benefits-of-binance-smart.png"
                  alt="benefits-of-binance-smart"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
