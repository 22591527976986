import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import LookingD from "../About-Us/LookingD";
import MlmHero from "./MlmHero";
import FrequentlyMlm from "./FrequentlyMlm";
import Essential from "./Essential";
import Engaging from "./Engaging";
import MlmCompiler from "./MlmCompiler";
import OurMLMScripts from "./OurMLMScripts";
import MLMDevProcess from "./MLMDevProcess";
import ReadytoMLMPlatform from "./ReadytoMLMPlatform";
import WhyCXeerforMLMPlan from "./WhyCXeerforMLMPlan";
import { Helmet } from "react-helmet";

export default function MLM() {
  return (
    <>
      <Helmet>
        <title> MLM Software Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Empower your network marketing business with Xeer’s cutting-edge MLM software. Seamless, secure, and scalable solutions."
        />
      </Helmet>
      <div>
        <Header />
        <MlmHero />
        <Essential />
        <Engaging />
        <FrequentlyMlm />
        <MlmCompiler />
        <OurMLMScripts />
        <MLMDevProcess />
        <ReadytoMLMPlatform />
        <WhyCXeerforMLMPlan />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
