import React from "react";

export default function WCDeFiLottery() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for DeFi Lottery Platform Development?
            </h2>
            <p className="p-3">
              Choose Xeer Technology to transform your DeFi lottery platform
              vision into reality with a powerful, user-focused, and
              future-ready solution that captivates and retains users. Partner
              with us to redefine lottery gaming in the DeFi space, offering
              transparency, fairness, and exciting new possibilities.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain_development.svg"
                      alt="blockchain_development"
                    />
                  </div>
                  <h3 className="pt-4">Blockchain Expertise</h3>
                  <p>
                    Our team brings extensive experience in developing
                    decentralized finance solutions, ensuring your staking
                    platform is secure, scalable, and future-proof.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/Seamless_ui_ux.svg"
                      alt="Seamless_ui_ux"
                    />
                  </div>
                  <h3 className="pt-4">User-Centric Design</h3>
                  <p>
                    We prioritize creating a seamless, intuitive user experience
                    that makes it easy for both new and experienced users to
                    engage with your platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/wallet/dw/end_to_end_services.svg"
                      alt="end_to_end_services"
                    />
                  </div>
                  <h3 className="pt-4">End-to-End Development</h3>
                  <p>
                    From initial concept design to post-launch support, we
                    handle every aspect of the development process to ensure the
                    success of your DeFi staking platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
