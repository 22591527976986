import React from "react";

export default function WebCampaigns() {
  return (
    <>
      <section className="feature-section py_50" id="web-campaigns">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">Web3 Campaigns</h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h1>Run Dynamic Campaigns That Capture Attention </h1>
                  <p className="py-3">
                    Xeer Technology runs cross-channel Web3 campaigns that
                    resonate with blockchain communities. We incorporate digital
                    channels like social media, email, and influencer outreach
                    to create cohesive campaigns that amplify your brand’s
                    voice.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Targeted Digital Outreach</h4>
                      <p>Reach users across multiple digital touchpoints.</p>
                    </li>
                    <li>
                      <h4>Creative Content Marketing</h4>
                      <p>Engage audiences with innovative content.</p>
                    </li>
                    <li>
                      <h4>Community-Centric Campaigns</h4>
                      <p>
                        Build authentic relationships with engaged Web3
                        communities. .
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  See How Web3 Campaigns Work
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/Web3-Campaigns.png"
                      alt="Web3-Campaigns"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
