import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function TradingBotDevCom() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="crypto-trading-bot-development"
        style={{
          backgroundImage:
            "url(assets/img/crypto-g-dev/crypto-trading-bot-development.jpg)",
        }}
      >
        <div className="auto-container ">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Crypto Trading Bot Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology specializes in developing advanced crypto
                    trading bots tailored to optimize trading strategies and
                    deliver faster, more precise market executions. Our bots are
                    designed for both beginners and experienced traders,
                    empowering them to automate trading, capture market
                    opportunities, and maximize returns in the volatile crypto
                    market!
                  </p>
                </div>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
