import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import MarginTradingExchangeHero from "./MarginTradingExchangeHero";
import AmplifyRevenue from "./AmplifyRevenue";
import LeverageAndMargin from "./LeverageAndMargin";
import TradingExchange from "./TradingExchange";
import SecurityPractices from "./SecurityPractices";
import WhatISMargin from "./WhatISMargin";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function MarginTradingExchange() {
  return (
    <>
      <Helmet>
        <title>Margin Trading Exchange Development | Xeer Technology </title>
        <meta
          name="description"
          content=" Enable leverage trading on your platform with our margin trading exchange development services. Secure, efficient, and high-performance solutions."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <MarginTradingExchangeHero />
        <WhatISMargin />
        <AmplifyRevenue />
        <LeverageAndMargin />
        <TradingExchange />
        <SecurityPractices />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
