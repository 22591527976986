import React from "react";

export default function Industriesthat() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_25">
            <h2>
              Benefits of Ethereum Development with
              <span className="xeer-color"> Xeer Technology</span>
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/proven_reliable_platform.svg"
                              alt="proven_reliable_platform"
                              className="mb-2"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">
                            Proven, Reliable <br /> Platform
                          </h5>
                        </div>
                        <p>
                          Ethereum’s established platform provides the security,
                          scalability, and innovation necessary for developing
                          robust blockchain applications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/developer_support.svg"
                              alt="developer_support"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Developer Support</h5>
                        </div>
                        <p>
                          With access to the Ethereum developer community and
                          extensive libraries, our experts bring advanced tools
                          to expedite your project.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/end_to_end_support.svg"
                              alt="end_to_end_support"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">End-to-End Support</h5>
                        </div>
                        <p>
                          Xeer Technology guides your project from concept to
                          completion, including ongoing support for maintenance
                          and updates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/innovation_driven_solutions.svg"
                              alt="innovation_driven_solutions"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">
                            Innovation <br /> Driven Solutions
                          </h5>
                        </div>
                        <p>
                          We leverage Ethereum’s latest advancements to ensure
                          your project remains competitive and adaptable in the
                          fast-evolving blockchain space.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="assets/img/layer/benefits-of-ethereum.png"
                  alt="benefits-of-ethereum"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
