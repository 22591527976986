import React from "react";

export default function BlockchainOffer() {
  return (
    <>
      <section className="feature-style-two pb_120 centred hero-bg-img">
        <div className="auto-container pt-5">
          <div data-animation-box className="sec-title mb_55">
            <div className="inner_wrap">
              <h2>Blockchain Networks We Offer</h2>
              <span class="border_btm"></span>
            </div>
            <p>Network-wise, here’s what we provide to our clients.</p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "60px" }}
                      src="assets/img/Public.svg"
                      alt="Public"
                    />
                  </div>
                  <h3>Public</h3>
                  <p>
                    We provide public blockchain networks, which form the basis
                    of the enormous and rapidly expanding field of decentralised
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "60px" }}
                      src="assets/img/private.svg"
                      alt="private"
                    />
                  </div>
                  <h3>Private</h3>
                  <p>
                    We provide private blockchain services, which are
                    decentralised distributed ledgers that may be controlled by
                    a
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "60px" }}
                      src="assets/img/hybrid.svg"
                      alt="hybrid"
                    />
                  </div>
                  <h3>Hybrid</h3>
                  <p>
                    The characteristics of both public and private blockchains
                    are combined in a hybrid blockchain. In a hybrid
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "60px" }}
                      src="assets/img/consortium.svg"
                      alt="consortium"
                    />
                  </div>
                  <h3>Consortium</h3>
                  <p>
                    A consortium blockchain is made up of many different
                    financial organisations, each of which has its own private
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
