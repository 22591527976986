import React from "react";

export default function Forward() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_40">
              <h1 className="text-white">
                Unlock the full potential of decentralized finance with our
                expert DeFi Yield Farming platform development
              </h1>
         
            </div>
            <div className="btn-box pb-5">
              <a href="/contact-us" className="theme-btn btn-one">
              contact us today to get started!
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
