import React from "react";

export default function PopularUse() {
  return (
    <>
      <section className="news-section pb_120 pt-5 bg-black">
        <div className="auto-container">
          <div data-animation-box className="sec-title centred mb_55">
            <h2 className="text-white">
              Explore NFT gaming categories & accelerate your journey
            </h2>
            <p className="pt-5">
              Explore these diverse gaming categories to unlock new
              opportunities for monetization, player engagement, and asset
              ownership. Let Xeer Technology accelerate your journey into the
              future of gaming by providing tailored NFT gaming solutions that
              blend creativity, innovation, and blockchain technology
              seamlessly.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/arcade_games.jpg"
                      alt="arcade_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Arcade Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/action_adventure.jpg"
                      alt="action_adventure"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Action & Adventure
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/casino_cards.jpg"
                      alt="casino_cards"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Casino & Cards
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/board_games.jpg"
                      alt="board_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Board Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/pvp_battle.jpg"
                      alt="pvp_battle"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        PvP Battle
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/sports_and_fantasy_leagues.jpg"
                      alt="sports_and_fantasy_leagues"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Sports and Fantasy Leagues
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/battle_games.jpg"
                      alt="battle_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Battle Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/play_to_earn.jpg"
                      alt="play_to_earn"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Play to Earn
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/nft_rpg_games.jpg"
                      alt="nft_rpg_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        NFT RPG Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/simulation_games.jpg"
                      alt="simulation_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Simulation Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/adventure_games.jpg"
                      alt="adventure_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Adventure Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="assets/img/nft_dev/game/move_to_earn_games.jpg"
                      alt="move_to_earn_games"
                    />
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a className="text-white" href="/contact-us">
                        Move to Earn Games
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
