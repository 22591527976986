import React from "react";

export default function Opportunities() {
  return (
    <>
      <section className="about-style-three pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box mr_80">
                  <figure className="image">
                    <img
                      src="assets/img/defi/DeFi-Staking-Platform-Development-by-Xeer-Technology.png"
                      alt="DeFi-Staking-Platform-Development-by-Xeer-Technology"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="sec-title mb_25">
                    <h2>
                      DeFi Staking Platform Development by Xeer Technology
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      At Xeer Technology, we specialize in creating robust and
                      feature-rich DeFi staking platforms that allow users to
                      securely stake their assets and earn passive income. Our
                      expertise in blockchain development ensures that your
                      platform is not only reliable but also scalable, offering
                      seamless integration with multiple blockchain networks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
