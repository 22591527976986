import React from "react";
import Header from "../Coman/Header";
import WebDevlopmentHero from "./WebDevlopmentHero";
import Footar from "../Coman/Footar";
import Widen from "./Widen";
import Empowering from "./Empowering";
import LookingD from "../About-Us/LookingD";
import ConnectwithSMEs from "./ConnectwithSMEs";
import PackageofWEB from "./PackageofWEB";
import WhatMakes from "./WhatMakes";
import { Helmet } from "react-helmet";

export default function WebDevlopment() {
  return (
    <>
      <Helmet>
        <title>Web 3.0 Development Services | Xeer Technolog</title>
        <meta
          name="description"
          content="Build decentralized web applications with Xeer’s Web 3.0 development services. Advanced blockchain integration for future-ready platforms."
        />
      </Helmet>
      <div>
        <Header />
        <WebDevlopmentHero />
        <Widen />
        <Empowering />
        <ConnectwithSMEs />
        <PackageofWEB />
        <WhatMakes />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
