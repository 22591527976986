import React from "react";

export default function OurVision() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>
                    Upscaling Real-world Economy With Blockchain Developments
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    With a strong commitment to quality and innovation, Xeer
                    Technology empowers organizations to harness the full
                    potential of blockchain technology. We aim to transform
                    industries and pave the way for a decentralized future.
                  </p>
                  <p>
                    Our mission is to provide businesses with the tools and
                    expertise they need to succeed in the evolving digital
                    economy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box">
                <figure className="image image-1">
                  <img
                    className="vert-move"
                    src="assets/img/upscaling-real-world-economy-with-blockchain-developments.png"
                    alt="upscaling-real-world-economy-with-blockchain-developments"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
