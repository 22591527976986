import React from "react";

export default function CustodialTrading() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Features & Functionalities of our Decentralized Exchange
              Development
            </h2>
            <p className="pt-3">
              Following features ensure that Xeer Technology’s DEX solutions are
              secure, scalable, and fully decentralized, meeting the evolving
              demands of the decentralized finance (DeFi) space.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/non_custodial_trading.svg"
                      alt="non_custodial_trading"
                    />
                  </div>
                  <h3 className="pt-3">Non-Custodial Trading</h3>
                  <p>
                    Xeer Technology’s DEX ensures users retain full control of
                    their assets. Trades are executed directly from users'
                    wallets without the need for a third-party to hold funds,
                    enhancing security and privacy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/automated_market_making.svg"
                      alt="automated_market_making"
                    />
                  </div>
                  <h3 className="pt-3">Automated Market Making (AMM)</h3>
                  <p>
                    The DEX employs AMM protocols, enabling liquidity pools
                    where users can provide liquidity in exchange for rewards.
                    This ensures efficient trading without relying on
                    traditional order books.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/cross_chain_asset_trading.svg"
                      alt="cross_chain_asset_trading"
                    />
                  </div>
                  <h3 className="pt-3">Cross-Chain Asset Trading</h3>
                  <p>
                    Xeer Technology integrates cross-chain functionality,
                    allowing users to trade assets across multiple blockchain
                    networks, such as Ethereum, Binance Smart Chain (BSC), and
                    others, using secure bridges.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/smart_contract_based_transactions.svg"
                      alt="smart_contract_based_transactions"
                    />
                  </div>
                  <h3 className="pt-3">Smart Contract-Based Transactions</h3>
                  <p>
                    All trades are executed through smart contracts, ensuring
                    transparent and automated transaction processing without
                    intermediaries, providing greater trust and security for
                    users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/liquidity_pools_and_yield_farming.svg"
                      alt="liquidity_pools_and_yield_farming"
                    />
                  </div>
                  <h3 className="pt-3">Liquidity Pools and Yield Farming</h3>
                  <p>
                    The DEX offers liquidity pools where users can stake their
                    assets to earn rewards. Additionally, yield farming
                    opportunities are available, allowing users to generate
                    passive income by providing liquidity to the platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/governance_and_voting_mechanism.svg"
                      alt="governance_and_voting_mechanism"
                    />
                  </div>
                  <h3 className="pt-3">Governance and Voting Mechanism</h3>
                  <p>
                    Xeer Technology integrates decentralized governance
                    features, enabling users to participate in decision-making
                    processes, such as protocol upgrades, fee structures, or new
                    feature rollouts, by staking governance tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/advanced_security_features.svg"
                      alt="advanced_security_features"
                    />
                  </div>
                  <h3 className="pt-3">Advanced Security Features</h3>
                  <p>
                    The platform is built with a focus on security,
                    incorporating features such as multi-signature wallets,
                    end-to-end encryption, and on-chain audits to ensure the
                    integrity of the platform and user funds.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/low_transaction_fees_and_high_scalability.svg"
                      alt="low_transaction_fees_and_high_scalability"
                    />
                  </div>
                  <h3 className="pt-3">
                    Low Transaction Fees and High Scalability
                  </h3>
                  <p>
                    Xeer Technology’s DEX ensures minimal transaction fees by
                    optimizing smart contracts and employing scalable solutions
                    like layer-2 technology, which allows for faster
                    transactions at lower costs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div>
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dde/user_friendly_interface_and_customizable_dashboards.svg"
                      alt="user_friendly_interface_and_customizable_dashboards"
                    />
                  </div>
                  <h3 className="pt-3">
                    User-Friendly Interface and Customizable Dashboards
                  </h3>
                  <p>
                    The DEX offers an intuitive user interface with customizable
                    dashboards, allowing traders of all experience levels to
                    access essential tools, real-time data, and personalized
                    trading experiences effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
