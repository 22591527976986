import React from "react";

export default function Requiremen() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 py_50 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Development Approach</h2>
            <p className="">
              At Xeer Technology, our development approach sticks to delivering
              high-quality, secure, and future-ready NFT wallet solutions.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow "
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box ">
                      <img
                        src="assets/img/client_centric_requirement.svg"
                        alt="client_centric_requirement"
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">
                        Client-Centric Requirement Gathering
                      </h4>
                      <p className="text-start">
                        We start by understanding the client’s specific needs,
                        including the blockchain network(s) to support and
                        desired wallet functionalities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow "
                data-wow-delay="0s"
                style={{
                  height: "200px",
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box ">
                      <img
                        src="assets/img/agile_development_proces.svg"
                        alt="agile_development_proces"
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Agile Development Proces</h4>
                      <p className="text-start">
                        Utilize agile development methodologies for flexibility
                        and efficiency, ensuring rapid iteration and
                        adaptability to client feedback.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow "
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box ">
                      <img
                        src="assets/img/designing_architecture.svg"
                        alt="designing_architecture"
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Designing Architecture</h4>
                      <p className="text-start">
                        Design a flexible, modular architecture that allows easy
                        integration with various decentralized applications
                        (dApps) and NFT marketplaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="box-white-wrap4-tow "
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box ">
                      <img
                        src="assets/img/rigorous_testing.svg"
                        alt="rigorous_testing"
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Rigorous Testing</h4>
                      <p className="text-start">
                        Conduct extensive testing to ensure the wallet's
                        performance, security, and scalability are optimized
                        before deployment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="justify-content-center"> */}
          <div
            className="box-white-wrap4-tow "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpX",
            }}
          >
            <div className="row">
              <div className="col-lg-3">
                <div className="num-box ">
                  <img
                    src="assets/img/ongoing_support.svg"
                    alt="ongoing_support"
                    style={{ height: "60px", width: "60px" }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="fugu--iconbox-data">
                  <h4 className="text-start">Ongoing Support and Upgrades</h4>
                  <p className="text-start">
                    Provide continuous post-launch support, regular updates, and
                    enhancements to keep the wallet aligned with industry
                    developments and user needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
}
