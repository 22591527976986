import React from "react";

export default function SolanaOffered() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_25">
            <h3>
              Benefits of Solana Development with
              <span className="xeer-color"> Xeer Technology?</span>
            </h3>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "280px" }}>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/high_speed_low_cost_transactions.svg"
                              alt="high_speed_low_cost_transactions"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">
                            High-Speed, <br /> Low-Cost Transactions
                          </h5>
                        </div>
                        <p>
                          Solana's PoH mechanism supports high-speed
                          transactions at low fees, ideal for high-demand
                          applications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "280px" }}>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/scalability_for_growth.svg"
                              alt="scalability_for_growth"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Scalability for Growth</h5>
                        </div>
                        <p>
                          Solana's architecture is built to scale, enabling
                          projects to handle thousands of transactions per
                          second without compromising security.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "280px" }}>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/developer_friendly.svg"
                              alt="developer_friendly"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Developer-Friendly</h5>
                        </div>
                        <p>
                          Our Solana developers bring expertise in Rust and the
                          Solana SDK, enabling rapid development and deployment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "280px" }}>
                        <div className="d-flex align-items-center">
                          <div className="">
                            <img
                              src="assets/img/comprehensive_support.svg"
                              alt="comprehensive_support"
                              style={{ width: "60px" }}
                            />
                          </div>
                          <h5 className="ms-3">Comprehensive Support</h5>
                        </div>
                        <p>
                          From project conception to deployment and beyond, Xeer
                          provides full-cycle support for every phase of Solana
                          development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="assets/img/layer/benefits-of-solana.png"
                  alt="benefits-of-solana"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
