import React from "react";
import Header from "../Coman/Header";
import DefiStakingHero from "./DefiStakingHero";
import Footar from "../Coman/Footar";
import Opportunities from "./Opportunities";
import PremiumDeF from "./PremiumDeF";
import LookingD from "../About-Us/LookingD";
import TransAndSecure from "./TransAndSecure";
import PassiveIncome from "./PassiveIncome";
import EndToEnd from "./EndToEnd";
import { Helmet } from "react-helmet";

export default function DefiStaking() {
  return (
    <>
      <Helmet>
        <title>DeFi Staking Platform Development | Xeer Technology</title>
        <meta
          name="description"
          content="Launch DeFi staking platforms with Xeer. Empower users to earn rewards securely and efficiently in the decentralized ecosystem."
        />
      </Helmet>
      <div>
        <Header />
        <DefiStakingHero />
        <PassiveIncome />
        <Opportunities />
        <PremiumDeF />
        <TransAndSecure />
        <EndToEnd />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
