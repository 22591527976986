import React from "react";

export default function EthereumSolutionHero() {
  return (
    <div>
      <section
        className="feature-section py_150"
        aria-label="power-your-project-with-ethereum-blockchain-development"
        style={{
          backgroundImage:
            "url(assets/img/layer/power-your-project-with-ethereum-blockchain-development.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">
                    Power Your Project with Ethereum Blockchain Development
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology delivers robust and scalable Ethereum-based
                    solutions tailored to accelerate your growth in the
                    decentralized ecosystem.
                  </p>
                </div>
                <div className="btn-box mt_40">
                  <a href="/contact-us" className="theme-btn btn-one mr_20">
                    Hire Blockchain Expert
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
