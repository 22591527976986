import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MobileWalletAppHero() {
  return (
    <>
      <section
        className="feature-section py_120 "
        aria-label="mobile_wallet_app_hero_section_image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets//img/wallet/mobile_wallet_app_hero_section_image.png)",
        }}
      >
        <div className="auto-container p-5">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <h1 className="py-3 text-white">
                  Crypto Wallet Mobile App Development
                </h1>
                <p className="text-white">
                  At Xeer Technology, we deliver secure, scalable, and
                  user-friendly wallets for mobile platforms. Our wallet
                  solutions support a wide range of cryptocurrencies and provide
                  advanced features that meet the growing demands of the crypto
                  ecosystem.
                </p>
              </div>
              <div className="btn-box pt-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
