import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CloneTheSuccess() {
  return (
    <>
      <section className="clients-section alternat-2 pt_80 pb_80 centred">
        <div className="auto-container">
          <div className="inner-container">
            <h3>
              Interested? Experience our white label crypto exchange platform
              firsthand. Schedule a personalized demo to explore its features &
              see how it fulfills your needs.
            </h3>
          </div>
          <TalkToOurTeamBTN/>
        </div>
      </section>
    </>
  );
}
