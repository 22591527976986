import React from "react";

export default function AutomateYour() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">
              Automate Your Trading with Xeer Technology
            </h1>
            <p className="text-white py-3">
              At Xeer Technology, our crypto trading bot solutions empower you
              to trade smarter, faster, and more profitably. Partner with us to
              leverage cutting-edge technology and take your trading to the next
              level with a reliable, high-performance bot tailored to your
              needs.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
