import React from "react";
import Header from "../Coman/Header";
import PaymentGatewayHero from "./PaymentGatewayHero";
import Footar from "../Coman/Footar";
import Enhance from "./Enhance";
import OurRange from "./OurRange";
import Gateways from "./Gateways";
import BeforeChoosing from "./BeforeChoosing";
import GlobalReach from "./GlobalReach";
import LookingD from "../About-Us/LookingD";
import GetStarted from "./GetStarted";
import WhyChoosePayGateway from "./WhyChoosePayGateway";
import { Helmet } from "react-helmet";

export default function PaymentGateway() {
  return (
    <>
      <Helmet>
        <title>
          Cryptocurrency Payment Gateway Development | Xeer Technology
        </title>
        <meta
          name="description"
          content=" Facilitate secure and fast crypto transactions with Xeer’s payment gateway solutions. Support for multiple blockchains and currencies."
        />
      </Helmet>
      <div>
        <Header />
        <PaymentGatewayHero />
        <Enhance />
        <BeforeChoosing />
        <OurRange />
        <Gateways />
        <GlobalReach />
        <GetStarted />
        <WhyChoosePayGateway />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
