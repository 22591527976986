import React from "react";

export default function Gateways() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Start Accepting a Wide Range of Cryptocurrencies</h2>
            <p className="pt-3">
              Easily expand your payment options by accepting multiple
              cryptocurrencies, from popular assets like Bitcoin and Ethereum to
              stablecoins, allowing your customers the flexibility to pay with
              their preferred digital currency.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/bitcoin.svg"
                    alt="bitcoin"
                  />
                  <h5 className="py-3">Bitcoin</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/eth.svg"
                    alt="eth"
                  />
                  <h5 className="py-3">Ethereum</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/usdt.svg"
                    alt="usdt"
                  />
                  <h5 className="py-3">USDT</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/solana.svg"
                    alt="solana"
                  />
                  <h5 className="py-3">Solana</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/xrp.svg"
                    alt="xrp"
                  />
                  <h5 className="py-2">XRP</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-5">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "100px" }}
                    src="assets/img/crypto-g-dev/usdc.svg"
                    alt="usdc"
                  />
                  <h5 className="py-2">USDC </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
