import React from "react";

export default function SmartContractDevHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="custom-smart-contract-development-for-blockchain-excellence"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/smart-con/custom-smart-contract-development-for-blockchain-excellence.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Custom Smart Contract Development for Blockchain Excellence
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Empower your business with Xeer Technology’s specialized
                    smart contract services, delivering tailored solutions that
                    automate processes, enhance security, and drive innovation
                    across industries.
                  </p>
                </div>
              </div>
              <a href="/contact-us" className="theme-btn btn-one my_20">
                Contact Us to Start Your Project
              </a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
