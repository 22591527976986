import React, { useState } from "react";

export default function Decentralized() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      <section className="faq-style-three pt_100 pb_140">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <h2>Features of a Decentralized Exchange</h2>
            <p className="pt-3">
              Followings are the core features of a decentralized exchange!
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box pt_100 ml_30">
                <figure className="image">
                  <img
                    src="assets/img/smart_contract(1).svg"
                    alt="smart_contract(1)"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30">
                <ul className="accordion-box">
                  <li className="accordion block active-block">
                    <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(0)} />
                      <h4>Non-Custodial</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 0 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Users retain full control over their funds, as DEXs do
                          not require them to deposit assets into a centralized
                          wallet. All transactions are handled directly from the
                          users' wallets.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(1)} />
                      <h4>Smart Contract-Based Trading</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 1 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Trades on a DEX are executed through smart contracts,
                          which automate the trading process based on predefined
                          conditions, ensuring transparency and security.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(2)} />
                      <h4>Privacy and Anonymity</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 2 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          DEXs typically do not require users to create
                          accounts, perform KYC (Know Your Customer) checks, or
                          provide personal information, offering higher levels
                          of privacy than centralized exchanges.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(3)} />
                      <h4>Liquidity Pools</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 3 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Many DEXs use liquidity pools, where users deposit
                          their crypto assets to provide liquidity for trades.
                          In return, liquidity providers earn rewards from
                          transaction fees or other incentives.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(4)} />
                      <h4>Lower Counterparty Risk</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 4 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Since users maintain control of their assets and do
                          not need to trust a central entity, DEXs reduce the
                          risk of hacks or fraud associated with centralized
                          exchanges.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(5)} />
                      <h4>Cross-Chain Trading</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 5 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Some advanced DEXs enable cross-chain trading,
                          allowing users to exchange assets from different
                          blockchain networks through bridges or interoperable
                          protocols.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
