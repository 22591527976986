import React from "react";

export default function ProjectwithUs() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_40 pb_40 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="">
            <h1 className="text-white">
              Benefit With Consistent Gains from Market Volatility
            </h1>
            <p className="text-white p-3">
              Maximize your crypto trading profits with cutting-edge automation,
              delivering consistent returns from price volatility in a highly
              competitive market.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one m_20">
            Connect Us
          </a>
        </div>
      </section>
    </>
  );
}
