import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CryptoGamingPlatformHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="empowering-your-security-token-offering-with-end-to-end-solutions"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/securitization/empowering-your-security-token-offering-with-end-to-end-solutions.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Empowering Your Security Token Offering with End-to-End
                    Solutions
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Transforming traditional assets into secure, compliant
                    digital securities for the modern investor.
                  </p>
                </div>
              </div>
              <div className="btn-box pt-3">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=""></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
