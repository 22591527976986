import React from "react";

export default function SelfCustody() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Get Script For DeFi Lending & Borrowing Platform</h2>
            <p className="pt-3">
              Unlock quick launch of a DeFi lending-borrowing platform with our
              ready-to-use scripts!
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/aave.svg"
                    alt="aave"
                  />
                  <h5 className="py-1">Aave Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 ">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/panckswap.svg"
                    alt="panckswap"
                  />
                  <h5 className="py-1">Pancakeswap Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 ">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/compound.svg"
                    alt="compound"
                  />
                  <h5 className="py-1">Compound</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/maker_dao.svg"
                    alt="maker_dao"
                  />
                  <h5 className="py-1">MakerDAO Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 ">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    style={{ width: "80px" }}
                    src="assets/img/defi/lending/yearn_finance.svg"
                    alt="yearn_finance"
                  />
                  <h5 className="py-1">Yearn Finance</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
