import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import NFTLoanHero from "./NFTLoanHero";
import NFTLending from "./NFTLending";
import AntiersExpertise from "./AntiersExpertise";
import LoanPlatform from "./LoanPlatform";
import TalkToExperts from "../Coman/TalkToExperts";
import BenefitsNFT from "./BenefitsNFT";
import LookingD from "../About-Us/LookingD";
import WhyChoNFTDevS from "./WhyChoNFTDevS";
import { Helmet } from "react-helmet";

export default function NFTLoan() {
  return (
    <>
      <Helmet>
        <title>NFT Loan Platform Development | Xeer Technology</title>
        <meta
          name="description"
          content="Enable NFT-backed loans with Xeer’s innovative platform solutions. Seamless integration with NFT ecosystems for better liquidity."
        />
      </Helmet>
      <div>
        <Header />
        <NFTLoanHero />
        <NFTLending />
        <AntiersExpertise />
        <LoanPlatform />
        <TalkToExperts />
        <BenefitsNFT />
        <WhyChoNFTDevS />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
