import React, { useState } from "react";

export default function Unlocking() {
  const [toggleTab, setToggleTab] = useState(0);

  console.log("toggleTab", toggleTab);

  return (
    <>
      <section className="service-section py_100">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Features & Functionalities of our Cryptocurrency Wallet</h2>
            <p className="pt-3">
              At Xeer Technology, we specialize in building highly secure,
              scalable, and user-centric cryptocurrency wallets. From a
              developer's perspective, our crypto wallets are designed to
              deliver cutting-edge functionalities that ensure seamless digital
              asset management while maintaining robust security protocols. Key
              features include:
            </p>
          </div>
          <div className="tabs-box">
            <div className="tab-btn-box p_relative mb_60 d-flex justify-content-center">
              <ul className="tab-btns tab-buttons ">
                <li
                  className={`tab-btn ${toggleTab === 0 ? "active-btn" : ""}`}
                  data-tab="#tab-1"
                  onClick={() => setToggleTab(0)}
                >
                  Default Features
                </li>
                <li
                  className={`tab-btn ${toggleTab === 1 ? "active-btn" : ""}`}
                  data-tab="#tab-2"
                  onClick={() => setToggleTab(1)}
                >
                  Advanced Features
                </li>
              </ul>
            </div>
            <div className="tabs-content centred">
              {toggleTab === 0 && (
                <div
                  className={`tab ${
                    toggleTab === 0 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-1"
                >
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={700}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/multi_currency_support.svg"
                              alt="multi_currency_support"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Multi-Currency Support</h3>
                          <p>
                            Develop wallets that support multiple blockchain
                            networks, enabling users to manage a wide range of
                            cryptocurrencies (e.g., Bitcoin, Ethereum, Solana)
                            within a single interface.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={900}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/cross_platform_compatibility.svg"
                              alt="cross_platform_compatibility"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Cross-Platform Compatibility</h3>
                          <p>
                            Wallets are built to work seamlessly across
                            platforms, including mobile (iOS/Android), desktop,
                            and web, ensuring a consistent user experience on
                            any device.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1100}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/private_key_management.svg"
                              alt="private_key_management"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Private Key Management</h3>
                          <p>
                            Implement advanced cryptographic algorithms to
                            securely generate and manage private keys. Offer
                            users the option to store private keys in secure
                            hardware wallets, or within encrypted local storage.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1300}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/ui.svg"
                              alt="ui"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>User-Friendly Interface</h3>
                          <p>
                            Design intuitive, easy-to-navigate UI/UX for both
                            novice and experienced users, allowing for smooth
                            management of digital assets and execution of
                            transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={700}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/real_time_transaction_tracking.svg"
                              alt="real_time_transaction_tracking"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Real-Time Transaction Tracking</h3>
                          <p>
                            Provide real-time updates on transaction statuses,
                            confirmations, and fees across blockchain networks,
                            allowing users to monitor the progress of their
                            transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={900}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/push_notifications.svg"
                              alt="push_notifications"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Push Notifications & Alerts</h3>
                          <p>
                            Implement real-time push notifications for
                            transaction updates, market fluctuations, and other
                            key wallet events, keeping users informed at all
                            times.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1100}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/dapps.svg"
                              alt="dapps"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>
                            Support for Decentralized Applications (dApps)
                          </h3>
                          <p>
                            Integrate support for interacting with decentralized
                            applications (dApps), allowing users to engage in
                            DeFi protocols, NFT marketplaces, and more directly
                            from the wallet.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1300}
                      >
                        <div className="inner-box" style={{ height: "437px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/qr_code_support.svg"
                              alt="qr_code_support"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>QR Code Support</h3>
                          <p>
                            Enable QR code scanning for fast and easy
                            cryptocurrency transactions, simplifying the process
                            for both sending and receiving digital assets.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {toggleTab === 1 && (
                <div
                  className={`tab ${
                    toggleTab === 1 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-2"
                >
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={700}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/enhanced_security_protocols.svg"
                              alt="enhanced_security_protocols"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Enhanced Security Protocols</h3>
                          <p>
                            Incorporate cutting-edge security measures like
                            multi-signature authentication, biometric security,
                            and two-factor authentication (2FA) for additional
                            protection.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={900}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/backup_and_recovery_mechanism.svg"
                              alt="backup_and_recovery_mechanism"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Backup and Recovery Mechanism</h3>
                          <p>
                            Integrate robust backup options, such as encrypted
                            recovery phrases or private key export, ensuring
                            users can recover their wallets in case of data
                            loss.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1100}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/cold_and_hot_wallet_options.svg"
                              alt="cold_and_hot_wallet_options"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Cold and Hot Wallet Options</h3>
                          <p>
                            Develop both hot wallets for easy access to funds
                            via the internet and cold wallets for offline
                            storage to maximize security for long-term holdings.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1300}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/push_notifications.svg"
                              alt="push_notifications"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Push Notifications & Alerts</h3>
                          <p>
                            Implement real-time push notifications for
                            transaction updates, market fluctuations, and other
                            key wallet events, keeping users informed at all
                            times.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={700}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/integrated_exchange_functionality.svg"
                              alt="integrated_exchange_functionality"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Integrated Exchange Functionality</h3>
                          <p>
                            Integrate with decentralized or centralized
                            exchanges (DEX/CEX) to allow seamless in-wallet
                            trading, conversion of assets, and price monitoring.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={900}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/multi_signature_support_02.svg"
                              alt="multi_signature_support_02"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Multi-Signature Support</h3>
                          <p>
                            Add multi-signature features, enabling wallets to
                            require multiple private keys for transaction
                            authorization, perfect for businesses or
                            collaborative funds.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1100}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/customizable_fee_structure.svg"
                              alt="customizable_fee_structure"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>Customizable Fee Structure</h3>
                          <p>
                            Allow users to adjust transaction fees according to
                            their priorities—whether they prefer faster
                            confirmations or lower transaction costs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                      <div
                        className="feature-block-one"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration={1300}
                      >
                        <div className="inner-box" style={{ height: "388px" }}>
                          <div className="icon-box">
                            <img
                              src="assets/img/api.svg"
                              alt="api"
                              style={{ width: "45px" }}
                            />
                          </div>
                          <h3>API Integration</h3>
                          <p>
                            Provide APIs for seamless integration with
                            third-party services such as payment gateways,
                            blockchain explorers, and external applications to
                            expand wallet functionality.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
