import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MarginTradingBots() {
  return (
    <>
      <section className="feature-section py_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="text-center">
            <div data-animation-box className="sec-title mb_55">
              <h2>We Deliver Best Crypto Bot Development Service</h2>
              <p className="pt-3">
                By leveraging sophisticated algorithms, real-time data
                processing, and customizable features, our bots adapt to any
                trading style or market condition.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="">
                  <div className="image-inner">
                    <figure className="">
                      <img
                        src="assets/img/crypto-g-dev/we-deliver-best-crypto-bot-development-service.png"
                        alt="we-deliver-best-crypto-bot-development-service"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div className="text-box">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 pt-3">
                      <ul className="list-style-one clearfix">
                        <li>Margin Trading Bots</li>
                        <li>Arbitrage Bots</li>
                        <li>Sniper Bots</li>
                        <li>Copy Trading Bots</li>
                        <li>MEV Bots</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pt-3">
                      <ul className="list-style-one clearfix">
                        <li>Flashloan Bots</li>
                        <li>AI-trading Bots</li>
                        <li>Inter-exchange Trading Bots</li>
                        <li>Farming Bots</li>
                        <li>Signal Trading Bots</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box pt-5 justify-content-center">
                <TalkToOurTeamBTN />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
