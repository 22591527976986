import React from "react";

export default function MLMDevProcess() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our MLM Development Process</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <div className="num-box">
                      <h1>01</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Consultation & Requirements Gathering
                  </h3>
                  <p className="text-start">
                    We work closely with you to understand your business model,
                    define objectives, and determine the best MLM structure for
                    your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <div className="num-box">
                      <h1>02</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    MLM Plan Selection & Customization
                  </h3>
                  <p className="text-start">
                    Based on your goals, we help select and customize an MLM
                    plan that ensures optimal functionality and profitability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <div className="num-box">
                      <h1>03</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Platform Development & Integration
                  </h3>
                  <p className="text-start">
                    Our expert developers build and integrate essential
                    features, payment gateways, and security measures to deliver
                    a seamless user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <div className="num-box">
                      <h1>04</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">
                    Testing & Quality Assurance
                  </h3>
                  <p className="text-start">
                    We rigorously test the platform for performance, security,
                    and scalability to ensure it’s ready for a successful
                    launch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <div className="num-box">
                      <h1>05</h1>
                    </div>
                  </div>
                  <h3 className="text-start pt-3">Launch & Ongoing Support</h3>
                  <p className="text-start">
                    Our team assists with deployment and provides continuous
                    support, maintenance, and upgrades to keep your platform
                    running smoothly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
