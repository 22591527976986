import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import TransportandLogisticsHero from "./TransportandLogisticsHero";
import BlockchainforTransportation from "./BlockchainforTransportation";
import BlockchaininTransport from "./BlockchaininTransport";
import { Helmet } from "react-helmet";

export default function TransportandLogistics() {
  return (
    <>
      <Helmet>
        <title>Enterprise Blockchain Solutions | Xeer Technology</title>
        <meta
          name="description"
          content="Transform your business with enterprise-grade blockchain solutions by Xeer. Tailored for security, scalability, and innovation."
        />
      </Helmet>
      <div>
        <Header />
        <TransportandLogisticsHero />
        <BlockchaininTransport />
        <BlockchainforTransportation />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
