import React from "react";

export default function ConnectwithSMEs() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_40">
              <h2>Let’s Build the Future Together</h2>
              <p>
                Explore the vast potential of decentralized technologies with
                Xeer Technology. Our Web3 development services are designed to
                align with your business goals, helping you create innovative,
                secure, and efficient solutions that resonate with today’s
                digital-first consumers!
              </p>
            </div>
            <div className="btn-box pb-5">
              <a href="/contact-us" className="theme-btn btn-one">
                Get a quote
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
