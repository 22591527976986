import React from "react";

export default function Layer2Mechanisms() {
  return (
    <>
      <section className="feature-section py_50">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">
              Benefits of Polkadot Development with Xeer Technology
            </h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Interoperability and Connectivity</h4>
                      <p>
                        Polkadot’s multi-chain structure allows communication
                        and data sharing between various blockchains.
                      </p>
                    </li>
                    <li>
                      <h4>High Performance and Scalability</h4>
                      <p>
                        By enabling parallel processing, Polkadot provides a
                        scalable solution for high-traffic applications.
                      </p>
                    </li>
                    <li>
                      <h4>Future-Proof Solutions</h4>
                      <p>
                        Xeer ensures your blockchain solution is equipped for
                        future integrations and advancements within the Polkadot
                        ecosystem.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt="why_choose_xeer_technology_for_margin_trading"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
