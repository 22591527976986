import React from "react";

export default function TransAndSecure() {
  return (
    <>
      <section className="feature-section  pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title text-center mb_55">
            <h2>
              Launch DeFi Staking Platforms & Unleash New Way of Financing
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ms-md-5 ps-md-5">
                <div className="image-box ms-md-5 ps-md-5">
                  <img
                    src="assets/img/defi/Launch-DeFi-Staking-Platforms-Image.png"
                    alt="Launch-DeFi-Staking-Platforms-Image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    DeFi staking platforms have become popular due to their
                    ability to offer high returns and flexibility in a
                    decentralized environment.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h5>Earning passive income</h5>
                      <p> Users earn rewards based on their staked assets.</p>
                    </li>
                    <li>
                      <h5>Participation in governance</h5>
                      <p> Some platforms allow stakers to vote on important </p>
                    </li>
                    <li>
                      <h5>Enhancing liquidity</h5>
                      <p>
                        By staking assets, users contribute to the platform’s
                        liquidity pools, enabling smoother transactions and
                        exchanges.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="btn-box justify-content-center">
                  <a href="/contact-us" className="theme-btn btn-one">
                    Get a quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
