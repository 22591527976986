import React from "react";

export default function WhatIsMarket() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is Market Making Service?</h2>
              <p>
                A crypto market making service is a service provided by
                specialized firms or platforms that helps maintain liquidity and
                stabilize prices in cryptocurrency markets. Market makers play a
                crucial role in trading by continuously offering to buy and sell
                assets at specified prices (called bid and ask prices) to ensure
                there is sufficient liquidity in the market.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
