import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import SemiFungibleToken from "./SemiFungibleToken";
import SemiFungible from "./SemiFungible";
import AntierSemi from "./AntierSemi";
import SemiBenefits from "./SemiBenefits";
import LookingD from "../About-Us/LookingD";
import ReadyTransform from "./ReadyTransform";
import { Helmet } from "react-helmet";

export default function SemiToken() {
  return (
    <>
      <Helmet>
        <title>P2P Lending Software Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build peer-to-peer lending platforms with Xeer Technology. Secure, scalable, and feature-rich solutions for fintech."
        />
      </Helmet>
      <div>
        <Header />
        <SemiFungibleToken />
        <SemiFungible />
        <SemiBenefits />
        <ReadyTransform />
        <AntierSemi />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
