import React from "react";

export default function SemiFungible() {
  return (
    <>
      <section className="feature-section pt_80">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div>
                  <img
                    src="assets/img/securitization/p2p-lending-software-development-with-xeer-technology.png"
                    alt="securitization/p2p-lending-software-development-with-xeer-technology"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>P2P Lending Software Development with Xeer Technology</h2>
                </div>
                <div className="text-box">
                  <p>
                    Peer-to-peer lending platforms are transforming the
                    financial ecosystem by offering direct, decentralized
                    lending options. At Xeer Technology, we specialize in custom
                    P2P lending software development, enabling seamless lending
                    experiences backed by blockchain’s transparency, security,
                    and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
