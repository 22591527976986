import React from "react";

export default function Pioneers() {
  return (
    <>
      <section className="feature-section py_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="">
                  <div className="image-inner">
                    <img
                      src="assets/img/partner-with-us-for-seamless-trading-and-growth.png"
                      alt="partner-with-us-for-seamless-trading-and-growth"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Partner with us for seamless trading and growth!</h2>
                </div>
                <div className="">
                  <p>
                    Xeer Technology is a pioneering company in the world of
                    crypto market making, dedicated to providing cutting-edge
                    solutions that enhance liquidity and stabilize prices for
                    digital assets. By leveraging advanced algorithms and custom
                    strategies, Xeer Technology ensures seamless market
                    activity, reduces volatility, and supports token projects in
                    achieving sustainable growth.
                  </p>
                  <p className="pt-2">
                    Whether you're launching a new token or optimizing liquidity
                    for an established asset, Xeer Technology delivers reliable
                    and efficient market making services to drive success in the
                    dynamic cryptocurrency landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
