import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import ICOdevelopmentHero from "./ICOdevelopmentHero";
import TopRated from "./TopRated";
import LookingD from "../About-Us/LookingD";
import WhyChooseICODev from "./WhyChooseICODev";
import OurICODev from "./OurICODev";
import UnlockYourProject from "./UnlockYourProject";
import { Helmet } from "react-helmet";

export default function ICOdevelopment() {
  return (
    <>
      <Helmet>
        <title> ICO Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Launch your cryptocurrency project with Xeer’s ICO development services. Ensure secure fundraising with expertly crafted platforms."
        />
      </Helmet>
      <div>
        <Header />
        <ICOdevelopmentHero />
        <TopRated />
        <OurICODev />
        <UnlockYourProject />
        <WhyChooseICODev />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
