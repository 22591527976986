import React from "react";

export default function PremiumDeF() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features & Functionalities of our DeFi Staking Platform</h2>
            <p className="pt-3">
              These features ensure that your DeFi staking platform is both
              secure and user-centric, attracting a wide range of users looking
              to earn passive income with minimal effort.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/secure_smart_contracts.svg"
                      alt="secure_smart_contracts"
                    />
                  </div>
                  <h3 className="">Secure Smart Contracts</h3>
                  <p>
                    Automating staking and ensuring transparency, security, and
                    immutability. These contracts lock user assets and
                    distribute rewards automatically without the need for
                    intermediaries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/multi_chain_staking_support.svg"
                      alt="multi_chain_staking_support"
                    />
                  </div>
                  <h3>Multi-Chain Staking Support</h3>
                  <p>
                    Enable users to stake assets across multiple blockchain
                    networks such as Ethereum, Binance Smart Chain, Solana, and
                    Polygon, providing flexibility and maximizing earning
                    opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/flexible_staking_models.svg"
                      alt="flexible_staking_models"
                    />
                  </div>
                  <h3 className="">Flexible Staking Models</h3>
                  <p>
                    Offer users various staking options such as fixed-term,
                    flexible-term, or liquidity staking, catering to different
                    levels of risk tolerance and reward preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/real_time_reward_tracking.svg"
                      alt="real_time_reward_tracking"
                    />
                  </div>
                  <h3>Real-Time Reward Tracking</h3>
                  <p>
                    Users can monitor their staked assets and rewards in
                    real-time through a user-friendly dashboard, offering
                    insights into staking performance, accrued rewards, and
                    potential future earnings.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/governance_integration.svg"
                      alt="governance_integration"
                    />
                  </div>
                  <h3>Governance Integration</h3>
                  <p>
                    Allow stakers to participate in governance decisions by
                    using their staked tokens to vote on protocol upgrades, new
                    features, or changes to the platform, thus enhancing
                    community engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/yield_optimization_tools.svg"
                      alt="yield_optimization_tools"
                    />
                  </div>
                  <h3>Yield Optimization Tools</h3>
                  <p className="pb-5">
                    Integrated with yield farming features, users can optimize
                    their returns by staking and farming simultaneously, further
                    boosting passive income through multiple DeFi mechanisms.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/automated_reward_distribution.svg"
                      alt="automated_reward_distribution"
                    />
                  </div>
                  <h3>Automated Reward Distribution</h3>
                  <p>
                    Ensure seamless reward distribution to stakers with
                    automated, scheduled payouts based on the staking pool’s
                    reward mechanism, allowing users to earn without manual
                    intervention.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/tiered_staking_systems.svg"
                      alt="tiered_staking_systems"
                    />
                  </div>
                  <h3>Tiered Staking Systems</h3>
                  <p className="pb-3">
                    Implement a tiered reward system where higher staking
                    amounts unlock additional benefits such as increased APY
                    (Annual Percentage Yield) and exclusive staking pools.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/liquidity_pool_integration.svg"
                      alt="liquidity_pool_integration"
                    />
                  </div>
                  <h3>Liquidity Pool Integration</h3>
                  <p>
                    Link staking to liquidity pools, enabling users to earn not
                    only staking rewards but also fees from decentralized
                    exchanges or liquidity mining platforms, enhancing income
                    streams.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/defi/defi-staking/mobile_and_web_access.svg"
                      alt="mobile_and_web_access"
                    />
                  </div>
                  <h3>Mobile and Web Access</h3>
                  <p>
                    Provide a responsive, user-friendly interface accessible
                    from both mobile and desktop, allowing users to stake and
                    manage their assets on the go, ensuring convenience and
                    engagement across all devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
