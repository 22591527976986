import React from "react";

export default function SemiFungibleToken() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="p2p-lending-platform-lending-platform-hero-section."
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/layer/p2p-lending-platform-lending-platform-hero-section.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="">
                <h1 className="text-white pb-3">
                  Build a Robust, Transparent P2P Lending Platform
                </h1>
                <p className="text-white">
                  Launch a secure, efficient platform for peer-to-peer lending
                  with cutting-edge blockchain solutions.
                </p>
              </div>
              <div className="btn-box pt-5">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Schedule a Free Consultation Today!
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
