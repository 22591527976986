import React from "react";

export default function FullNFTMarketplace() {
  return (
    <section className="feature-section py_100">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_four">
              <div className="image-inner">
                <figure className="">
                  <img
                    src="assets/img/nft_dev/NFT-Marketplace-Development-Service-By-Xeer-Technology.png"
                    alt="NFT-Marketplace-Development-Service-By-Xeer-Technology"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content-box ml_40">
              <div data-animation-box className="sec-title mb_25">
                <h2>NFT Marketplace Development Service By Xeer Technology</h2>
              </div>
              <div className="text-box">
                <p>
                  At Xeer Technology, we specialize in crafting cutting-edge NFT
                  marketplace solutions that cater to diverse industries and
                  audiences. Our NFT Marketplace Development Service encompasses
                  every aspect of creating a thriving platform where users can
                  mint, buy, sell, and trade non-fungible tokens with ease and
                  security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
