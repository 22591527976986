import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function SubStrateHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="Substrate-Development-Services-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/framework/Substrate-Development-Services-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-3">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Substrate Development Services</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    At Xeer, we utilize the versatile Rust-based Substrate
                    framework to build high-performance blockchain solutions
                    tailored to your needs. Our expert team crafts robust,
                    future-proof applications, enabling you to enter the market
                    swiftly and efficiently.
                  </p>
                </div>
              </div>
              <div className="btn-box mt_40">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Hire Blockchain Expert
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
