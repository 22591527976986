import React from "react";

export default function Protocols() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-71.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Components of DeFi Yield Farming Platforms</h2>
            <p>
              A DeFi yield farming platform comprises several sophisticated
              components, each fulfilling a specific role to ensure the
              platform’s functionality, security, and user engagement. These
              elements are integral to achieving a seamless and secure user
              experience in decentralized finance.
            </p>
          </div>
          <div className="row clearfix text-start">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/blockchain_infrastructure.svg"
                      alt="blockchain_infrastructure"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Blockchain Infrastructure</h3>
                    <p>
                      The underlying blockchain choice, such as Ethereum, BNB
                      Chain, or Polygon, influences transaction costs, speed,
                      and the platform’s compatibility with other DeFi
                      applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/smart_contract_framework.svg"
                      alt="smart_contract_framework"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Smart Contract Framework</h3>
                    <p>
                      Smart contracts form the backbone of any DeFi platform,
                      automating processes like staking, reward distribution,
                      and liquidity pooling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/liquidity_&_incentives.svg"
                      alt="liquidity_&_incentives"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Liquidity & Incentives</h3>
                    <p>
                      To attract liquidity providers, platforms often employ
                      incentive mechanisms, including competitive Annual
                      Percentage Yields (APYs) and governance tokens, which
                      foster community involvement and enhance liquidity
                      sustainability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix text-start">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/Seamless_ui_ux.svg"
                      alt="Seamless_ui_ux"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Seamless UI/UX</h3>
                    <p>
                      The UI/UX design significantly impacts user retention,
                      particularly by simplifying staking, withdrawal, and
                      rewards claim functionalities to cater to both novice and
                      experienced users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/security_mechanisms.svg"
                      alt="security_mechanisms"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Security Mechanisms </h3>
                    <p>
                      Given the susceptibility of DeFi platforms to hacks,
                      implementing rigorous security practices—such as regular
                      smart contract audits by reputable firms, multi-signature
                      wallets, and decentralized insurance protocols—is
                      paramount.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "390px" }}>
                  <div className="">
                    <img
                      style={{ width: "65px" }}
                      src="assets/img/defi/defi-y-f/yield_programs.svg"
                      alt="yield_programs"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3">Yield Programs</h3>
                    <p>
                      Staking programs offer yield rewards for users who lock
                      their assets, while liquidity mining incentivizes users to
                      provide liquidity in token pairs, creating sustainable
                      liquidity sources.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
