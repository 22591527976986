import React from "react";
import Header from "../Coman/Header";
import MobileWalletAppHero from "./MobileWalletAppHero";
import Footar from "../Coman/Footar";
import WalletApps from "./WalletApps";
import Attributes from "./Attributes";
import LookingD from "../About-Us/LookingD";
import WhyCmwad from "./WhyCmwad";
import Letushelp from "./Letushelp";
import { Helmet } from "react-helmet";

export default function MobileWalletApp() {
  return (
    <>
      <Helmet>
        <title>Mobile Crypto Wallet App Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build secure and user-friendly mobile wallet apps for seamless cryptocurrency transactions. Trust Xeer Technology for advanced wallet solutions."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <MobileWalletAppHero />
        <Letushelp />
        <Attributes />
        <WalletApps />
        <WhyCmwad />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
