import React from "react";

export default function BlockchaininSupply() {
  return (
    <>
      <section className="feature-section py_50">
        <div className="auto-container">
          <h1 className="text-center">
            Benefits of TON Development with Xeer Technology
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Exceptional Speed and Scalability</h4>
                      <p>
                        TON’s multi-threaded architecture supports high
                        transaction speeds and scalable solutions, ideal for
                        high-traffic applications.
                      </p>
                    </li>
                    <li>
                      <h4>Low Latency and Fast Settlement</h4>
                      <p>
                        Benefit from near-instant transaction finality, ensuring
                        quick and seamless user interactions.
                      </p>
                    </li>
                    <li>
                      <h4>End-to-End Development Solutions</h4>
                      <p>
                        Xeer offers comprehensive support from development to
                        deployment, testing, and maintenance.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt="why_choose_xeer_technology_for_margin_trading"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
