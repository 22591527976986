import React from "react";

export default function Remunerative() {
  return (
    <>
      <section className="feature-section py_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="">
                  <figure className="image">
                    <img
                      src="assets/img/wallet/defi-wallet-development-service.png"
                      alt="defi-wallet-development-service"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>DeFi Wallet Development Service</h2>
                </div>
                <div className="">
                  <p className="pb-3">
                    At Xeer Technology, we provide cutting-edge DeFi Wallet
                    Development Services designed to empower users with secure,
                    user-friendly, and feature-rich wallets that seamlessly
                    integrate with the decentralized finance ecosystem. Our team
                    of experts creates customized wallet solutions that enable
                    users to store, manage, and interact with their digital
                    assets across multiple DeFi platforms.
                  </p>
                  <p>
                    With Xeer Technology’s DeFi Wallet Development Service, you
                    can provide your users with a secure, scalable, and
                    feature-rich wallet that enhances their DeFi experience.
                    Whether you’re an individual or a business, we offer
                    tailor-made solutions to meet your specific needs. Contact
                    us today to start building your custom DeFi wallet solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
