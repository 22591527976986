import React from "react";

export default function TopRated() {
  return (
    <>
      <section className="faq-style-three py_140">
        <div className="auto-container">
          <div className="sec-title mb_40">
            <h2 className="text-center">
              ICO Development Service by Xeer Technology
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_80">
                <figure className="image">
                  <img
                    style={{ width: "400px" }}
                    src="assets/img/coin-dev/drive-your-vision-to-success-with-xeer-technologys-ico-services.png"
                    alt="drive-your-vision-to-success-with-xeer-technologys-ico-services"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div>
                <h1>
                  Drive Your Vision to Success with Xeer Technology’s ICO
                  Services
                </h1>
                <p className="py-3">
                  Launching an Initial Coin Offering (ICO) can be
                  transformative, enabling startups and established ventures
                  alike to raise substantial capital and expand their projects.
                  At Xeer Technology, we specialize in end-to-end ICO services
                  designed to make your token sale secure, compliant, and
                  effective. From token development to community outreach, our
                  team of blockchain experts ensures a seamless ICO journey,
                  empowering you to focus on growth and innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
