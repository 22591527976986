import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [showtab, setShowTab] = useState(null);

  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }

    // Cleanup to avoid memory leaks when the component unmounts
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [isHeaderOpen]);
  const toggleHeader = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };

  const currentPath = window.location.pathname;
  return (
    <>
      <header className="main-header header-style-one">
        {/* header-lower */}
        <div className="header-lower">
          <div
            className="auto px-2 px-md-4 px-lg-5 py-3"
            style={{
              backgroundColor: "#071522",
            }}
          >
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="assets/img/logo.svg"
                      alt="logo"
                      style={{ height: "50px" }}
                    />
                  </a>
                </figure>
              </div>
              <div className="menu-area ">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler" onClick={toggleHeader}>
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/exchange" ||
                            currentPath === "/white-label-exchanges" ||
                            currentPath === "/margin-trading-exchange" ||
                            currentPath === "/security-exchangees" ||
                            currentPath === "/p2p-exchange" ||
                            currentPath === "/decentralized-exchange" ||
                            currentPath === "/derivatives-exchange" ||
                            currentPath === "/arbitrage-trading-bots" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/cryptocurrency-wallet" ||
                            currentPath === "/web3-wallet" ||
                            currentPath === "/multicurrency-wallet" ||
                            currentPath === "/nft-wallet" ||
                            currentPath === "/ethereum-wallet" ||
                            currentPath === "/bitcoin-wallet" ||
                            currentPath === "/mobile-wallet-app" ||
                            currentPath === "/white-label-wallet" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/nft-development" ||
                            currentPath === "/nft-collection-launch" ||
                            currentPath === "/nft-marketplace" ||
                            currentPath === "/nft-gaming" ||
                            currentPath === "/nft-loan" ||
                            currentPath === "/defi-development" ||
                            currentPath === "/defi-staking" ||
                            currentPath === "/dapp-development-compoany" ||
                            currentPath === "/defi-lottery" ||
                            currentPath === "/dao-blockchain" ||
                            currentPath === "/defi-lending-and-borrowing" ||
                            currentPath === "/defi-yield-farming" ||
                            currentPath === "/olympus-dao-development" ||
                            currentPath === "/web-30-devlopment" ||
                            currentPath === "/web3-game-devlopment" ||
                            currentPath === "/dex-aggregator" ||
                            currentPath === "/amm-dex" ||
                            currentPath === "/order-book-dexs" ||
                            currentPath === "/crypto-banking" ||
                            currentPath === "/cryptocurrency-payment-gateway" ||
                            currentPath === "/token-development" ||
                            currentPath === "/ido-development" ||
                            currentPath === "/crypto-launchpad" ||
                            currentPath === "/ico-development" ||
                            currentPath === "/trading-bot" ||
                            currentPath === "/flash-loan-bot" ||
                            currentPath === "/arbitrage-bots" ||
                            currentPath === "/mev-bot"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Solutions <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/exchange" ||
                                currentPath === "/white-label-exchanges" ||
                                currentPath === "/margin-trading-exchange" ||
                                currentPath === "/security-exchangees" ||
                                currentPath === "/p2p-exchange" ||
                                currentPath === "/decentralized-exchange" ||
                                currentPath === "/derivatives-exchange" ||
                                currentPath === "/arbitrage-trading-bots" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/exchange.svg"
                                alt="exchange"
                                className="mx-2"
                              />
                              Exchange
                            </Link>
                            {/* </div> */}
                            <ul>
                              <li>
                                <a
                                  href="/exchange"
                                  className={`${
                                    currentPath === "/exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/white-label-exchange"
                                  className={`${
                                    currentPath === "/white-label-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/margin-trading-exchange"
                                  className={`${
                                    currentPath === "/margin-trading-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Margin Trading Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/security-exchange"
                                  className={`${
                                    currentPath === "/security-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Security Exchange
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/p2p-exchange"
                                  className={`${
                                    currentPath === "/p2p-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  P2P Exchange
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/derivatives-exchange"
                                  className={`${
                                    currentPath === "/derivatives-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Derivatives Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/arbitrage-trading-bots"
                                  className={`${
                                    currentPath === "/arbitrage-trading-bots"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Arbitrage Trading Bots
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/market-making-services"
                                  className={`${
                                    currentPath === "/market-making-services"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Market Making Services
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/cryptocurrency-wallet" ||
                                currentPath === "/web3-wallet" ||
                                currentPath === "/multicurrency-wallet" ||
                                currentPath === "/nft-wallet" ||
                                currentPath === "/ethereum-wallet" ||
                                currentPath === "/bitcoin-wallet" ||
                                currentPath === "/mobile-wallet-app" ||
                                currentPath === "/white-label-wallet" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/wallet.svg"
                                alt="wallet"
                                className="mx-2"
                              />
                              Wallet
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/cryptocurrency-wallet"
                                  className={`${
                                    currentPath === "/cryptocurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/web3-wallet"
                                  className={`${
                                    currentPath === "/web3-walle"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/multicurrency-wallet"
                                  className={`${
                                    currentPath === "/multicurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Wallet Clone App
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-wallet"
                                  className={`${
                                    currentPath === "/nft-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ethereum-wallet"
                                  className={`${
                                    currentPath === "/ethereum-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Ethereum Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/bitcoin-wallet"
                                  className={`${
                                    currentPath === "/bitcoin-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Bitcoin Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/mobile-wallet-app"
                                  className={`${
                                    currentPath === "/mobile-wallet-app"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Mobile Wallet App
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-wallet"
                                  className={`${
                                    currentPath === "/defi-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/white-label-wallet"
                                  className={`${
                                    currentPath === "/white-label-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Wallet
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/nft-development" ||
                                currentPath === "/nft-collection-launch" ||
                                currentPath === "/nft-marketplace" ||
                                currentPath === "/nft-gaming" ||
                                currentPath === "/nft-loan"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/nft.svg"
                                alt="nft"
                                className="mx-2"
                              />
                              NFT
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/nft-development"
                                  className={`${
                                    currentPath === "/nft-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-collection-launch"
                                  className={`${
                                    currentPath === "/nft-collection-launch"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label NFT Marketplace
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/nft-marketplace"
                                  className={`${
                                    currentPath === "/nft-marketplace"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Marketplace
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-loan"
                                  className={`${
                                    currentPath === "/nft-loan"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Loan
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/nft-gaming"
                                  className={`${
                                    currentPath === "/nft-gaming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Gaming Platform
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/defi-development" ||
                                currentPath === "/defi-staking" ||
                                currentPath === "/dapp-development-compoany" ||
                                currentPath === "/defi-lottery" ||
                                currentPath === "/dao-blockchain" ||
                                currentPath === "/defi-lending-and-borrowing" ||
                                currentPath === "/defi-yield-farming" ||
                                currentPath === "/olympus-dao-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/defi.svg"
                                alt="defi"
                                className="mx-2"
                              />
                              Defi
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/defi-development"
                                  className={`${
                                    currentPath === "/defi-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-staking"
                                  className={`${
                                    currentPath === "/defi-staking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Staking Platform
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/defi-lottery"
                                  className={`${
                                    currentPath === "/defi-lottery"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lottery
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/defi-lending-and-borrowing"
                                  className={`${
                                    currentPath ===
                                    "/defi-lending-and-borrowing"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lending And Borrowing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-yield-farming"
                                  className={`${
                                    currentPath === "/defi-yield-farming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi yield Farming
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/web-30-devlopment" ||
                                currentPath === "/web3-game-devlopment"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                alt="web3"
                                className="mx-2"
                              />
                              Web3
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/web-30-devlopment"
                                  className={`${
                                    currentPath === "/web-30-devlopment"
                                      ? "xeer-color"
                                      : " "
                                  }`}
                                >
                                  Web 3.0 Devlopment
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/web3-game-devlopment"
                                  className={`${
                                    currentPath === "/web3-game-devlopment"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Game Devlopment
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/dex-aggregator" ||
                                currentPath === "/amm-dex" ||
                                currentPath === "/order-book-dexs"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/dexs.svg"
                                alt="dexs"
                                className="mx-2"
                              />
                              DEXs
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/decentralized-exchange"
                                  className={`${
                                    currentPath === "/decentralized-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Decentralized Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/dex-aggregator"
                                  className={`${
                                    currentPath === "/dex-aggregator"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Dex Aggregator
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/amm-dex"
                                  className={`${
                                    currentPath === "/amm-dex"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  AMM Dex
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/order-book-dexs"
                                  className={`${
                                    currentPath === "/order-book-dexs"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Order Book Dexs
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/crypto-banking" ||
                                currentPath ===
                                  "/cryptocurrency-payment-gateway"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/crypto_banking.svg"
                                alt="crypto_banking"
                                className="mx-2"
                              />
                              Crypto Banking
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/crypto-banking"
                                  className={`${
                                    currentPath === "/crypto-banking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Banking
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/cryptocurrency-payment-gateway"
                                  className={`${
                                    currentPath ===
                                    "/cryptocurrency-payment-gateway"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Payment Gateway
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/token-development" ||
                                currentPath === "/ido-development" ||
                                currentPath === "/crypto-launchpad" ||
                                currentPath === "/ico-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/coin_development.svg"
                                alt="coin_development"
                                className="mx-2"
                              />
                              Coin Development
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/token-development"
                                  className={`${
                                    currentPath === "/token-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Coin/Token Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ido-development"
                                  className={`${
                                    currentPath === "/ido-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  IDO Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/crypto-launchpad"
                                  className={`${
                                    currentPath === "/crypto-launchpad"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Launchpad
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ico-development"
                                  className={`${
                                    currentPath === "/ico-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  ICO Development
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="">
                            <Link
                              to="/crypto-gaming-app"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/wallet/dw/gaming.svg"
                                alt="gaming"
                                className="mx-2"
                              />
                              Crypto Gaming
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/trading-bot"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/trading_bot.svg"
                                alt="trading_bot"
                                className="mx-2"
                              />
                              Trading Bot
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/mlm"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/mlm_platform.svg"
                                alt="mlm_platform"
                                className="mx-2"
                              />
                              MLM
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/Blockchain-development" ||
                            currentPath === "/harshgraph-development" ||
                            currentPath === "/blockchain-game-development" ||
                            currentPath === "/substrate" ||
                            currentPath === "/tezos" ||
                            currentPath === "/cosmos" ||
                            currentPath === "/polygon-edge" ||
                            currentPath === "/polygon-blockchain"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Blockchain <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/Blockchain-development" ||
                                currentPath === "/harshgraph-development" ||
                                currentPath ===
                                  "/blockchain-game-development" ||
                                currentPath === "/dag-acyclic-graph"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/Asseblockchain_developmentt 1.svg"
                                alt="Asseblockchain_developmentt"
                                className="mx-2"
                              />
                              Blockchain Development
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/Blockchain-development"
                                  className={`${
                                    currentPath === "/Blockchain-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/harshgraph-development"
                                  className={`${
                                    currentPath === "/harshgraph-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Harshgraph Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/blockchain-game-development"
                                  className={`${
                                    currentPath ===
                                    "/blockchain-game-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Game Development
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/substrate" ||
                                currentPath === "/tezos" ||
                                currentPath === "/cosmos" ||
                                currentPath === "/polygon-edge" ||
                                currentPath === "/polygon-blockchain"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/blockchain_framworks.svg"
                                alt="blockchain_framworks"
                                className="mx-2"
                              />
                              Blockchain Frameworks
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/substrate"
                                  className={`${
                                    currentPath === "/substrate"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  SubStrate
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/tezos"
                                  className={`${
                                    currentPath === "/tezos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Tezos
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/cosmos"
                                  className={`${
                                    currentPath === "/cosmos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cosmos
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/polygon-edge"
                                  className={`${
                                    currentPath === "/polygon-edge"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon Edge
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/polygon-blockchain"
                                  className={`${
                                    currentPath === "/polygon-blockchain"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon BlockChain
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/layer_section.svg"
                                alt="layer_section"
                                className="mx-2"
                              />
                              Layer 1 & 2 Solution
                            </Link>
                            <ul>
                              <li>
                                <a href="/bsc">BSC</a>
                              </li>
                              <li>
                                <a href="/ethereum">Ethereum</a>
                              </li>
                              <li>
                                <a href="/solana">Solana</a>
                              </li>

                              <li>
                                <a href="/cardano">Cardano</a>
                              </li>
                              <li>
                                <a href="/polkadot">Polkadot</a>
                              </li>
                              <li>
                                <a href="/ton">TON</a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/smart_contract.svg"
                                alt="smart_contract"
                                className="mx-2"
                              />
                              Smart Contract
                            </Link>
                            <ul>
                              <li>
                                <a href="/smart-contract-development">
                                  Smart Contract Development
                                </a>
                              </li>
                              <li>
                                <a href="/deFi-smart-contract">
                                  DeFi Smart Contract
                                </a>
                              </li>
                              <li>
                                <a href="/smart-contract-audit">
                                  Smart Contract Audit
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/asset_tokenization.svg"
                                alt="asset_tokenization"
                                className="mx-2"
                              />
                              Assent Tekenization
                            </Link>
                            <ul>
                              <li>
                                <a href="/asset-tokenization">
                                  Asset Tokenization
                                </a>
                              </li>
                              <li>
                                <a href="/crypto-asset-management">
                                  Crypto Asset Management
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="">
                            <Link
                              to="/enterprise-solution"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/enterprise_solutions.svg"
                                alt="enterprise_solutions"
                                className="mx-2"
                              />
                              Enterprise Solution
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/securitization"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/securitization.svg"
                                alt="securitization"
                                className="mx-2"
                              />
                              Securitization
                            </Link>
                          </li>
                          <li className="p2p-lending">
                            <Link
                              to="/p2p-lending"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/p_to_p_leading.svg"
                                alt="p_to_p_leading"
                                className="mx-2"
                              />
                              P2P Lending
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link>
                          Listing <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li>
                            <a href="/exchange-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/exchange_listing.svg"
                                alt="exchange_listing"
                                className="mx-2"
                              />
                              Exchange Listing
                            </a>
                          </li>
                          <li>
                            <a href="/token-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/token_listing.svg"
                                alt="token_listing"
                                className="mx-2"
                              />
                              Token Listing
                            </a>
                          </li>
                          <li>
                            <a href="/coingecko-and-cmc-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/cmc_listing.svg"
                                alt="cmc_listing"
                                className="mx-2"
                              />
                              CoinGecko & CMC Listing
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="/marketing"> Marketing</a>
                        <ul>
                          <li>
                            <a href="#crypto-token-marketing">
                              Crypto Token Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#pr-and-media">PR & Media</a>
                          </li>
                          <li>
                            <a href="#marketing-strategies">
                              Marketing Strategies
                            </a>
                          </li>
                          <li>
                            <a href="#social-media-marketing">
                              Social Media Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#influence-marketing">
                              Influence Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#web-campaigns">Web3 Campaigns</a>
                          </li>
                          <li>
                            <a href="#content-marketing">Content Marketing</a>
                          </li>
                          <li>
                            <a href="#community-building-and-management">
                              Community Building & Management
                            </a>
                          </li>
                          <li>
                            <a href="#airdrop-marketing">Airdrop Marketing</a>
                          </li>
                          <li>
                            <a href="#rebranding">Rebranding</a>
                          </li>
                          <li>
                            <a href="#pitch-deck-design">Pitch Deck Design</a>
                          </li>
                          <li>
                            <a href="#fund-raising">Fund Raising</a>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="/blogs">Blog</a>
                      </li> */}
                      <li>
                        <a href="/contact-us">Hire Developer</a>
                      </li>
                      <li className="dropdown">
                        <Link
                          to="/about-us"
                          className={`${
                            currentPath === "/about-us" ? "xeer-color  " : " "
                          }`}
                        >
                          About Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="btn-box">
                  <a href="/contact-us" className="theme-btn btn-one">
                    Get a Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* sticky Header*/}
        <div className="sticky-header ">
          <div
            className="auto"
            style={{ paddingLeft: "2%", paddingRight: "2%", height: "75px" }}
          >
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="assets/img/logo.svg"
                      style={{ height: "50px" }}
                      alt="logo"
                    />
                  </a>
                </figure>
              </div>
              <div className="menu-area ">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler" onClick={toggleHeader}>
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/exchange" ||
                            currentPath === "/white-label-exchanges" ||
                            currentPath === "/margin-trading-exchange" ||
                            currentPath === "/security-exchangees" ||
                            currentPath === "/p2p-exchange" ||
                            currentPath === "/decentralized-exchange" ||
                            currentPath === "/derivatives-exchange" ||
                            currentPath === "/arbitrage-trading-bots" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/cryptocurrency-wallet" ||
                            currentPath === "/web3-wallet" ||
                            currentPath === "/multicurrency-wallet" ||
                            currentPath === "/nft-wallet" ||
                            currentPath === "/ethereum-wallet" ||
                            currentPath === "/bitcoin-wallet" ||
                            currentPath === "/mobile-wallet-app" ||
                            currentPath === "/white-label-wallet" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/nft-development" ||
                            currentPath === "/nft-collection-launch" ||
                            currentPath === "/nft-marketplace" ||
                            currentPath === "/nft-gaming" ||
                            currentPath === "/nft-loan" ||
                            currentPath === "/defi-development" ||
                            currentPath === "/defi-staking" ||
                            currentPath === "/dapp-development-compoany" ||
                            currentPath === "/defi-lottery" ||
                            currentPath === "/dao-blockchain" ||
                            currentPath === "/defi-lending-and-borrowing" ||
                            currentPath === "/defi-yield-farming" ||
                            currentPath === "/olympus-dao-development" ||
                            currentPath === "/web-30-devlopment" ||
                            currentPath === "/web3-game-devlopment" ||
                            currentPath === "/dex-aggregator" ||
                            currentPath === "/amm-dex" ||
                            currentPath === "/order-book-dexs" ||
                            currentPath === "/crypto-banking" ||
                            currentPath === "/cryptocurrency-payment-gateway" ||
                            currentPath === "/token-development" ||
                            currentPath === "/ido-development" ||
                            currentPath === "/crypto-launchpad" ||
                            currentPath === "/ico-development" ||
                            currentPath === "/trading-bot" ||
                            currentPath === "/flash-loan-bot" ||
                            currentPath === "/arbitrage-bots" ||
                            currentPath === "/mev-bot"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Solutions <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/exchange" ||
                                currentPath === "/white-label-exchanges" ||
                                currentPath === "/margin-trading-exchange" ||
                                currentPath === "/security-exchangees" ||
                                currentPath === "/p2p-exchange" ||
                                currentPath === "/decentralized-exchange" ||
                                currentPath === "/derivatives-exchange" ||
                                currentPath === "/arbitrage-trading-bots" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/exchange.svg"
                                alt="exchange"
                                className="mx-2"
                              />
                              Exchange
                            </Link>
                            {/* </div> */}
                            <ul>
                              <li>
                                <a
                                  href="/exchange"
                                  className={`${
                                    currentPath === "/exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/white-label-exchange"
                                  className={`${
                                    currentPath === "/white-label-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/margin-trading-exchange"
                                  className={`${
                                    currentPath === "/margin-trading-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Margin Trading Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/security-exchange"
                                  className={`${
                                    currentPath === "/security-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Security Exchange
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/p2p-exchange"
                                  className={`${
                                    currentPath === "/p2p-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  P2P Exchange
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/derivatives-exchange"
                                  className={`${
                                    currentPath === "/derivatives-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Derivatives Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/arbitrage-trading-bots"
                                  className={`${
                                    currentPath === "/arbitrage-trading-bots"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Arbitrage Trading Bots
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/market-making-services"
                                  className={`${
                                    currentPath === "/market-making-services"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Market Making Services
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/cryptocurrency-wallet" ||
                                currentPath === "/web3-wallet" ||
                                currentPath === "/multicurrency-wallet" ||
                                currentPath === "/nft-wallet" ||
                                currentPath === "/ethereum-wallet" ||
                                currentPath === "/bitcoin-wallet" ||
                                currentPath === "/mobile-wallet-app" ||
                                currentPath === "/white-label-wallet" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/wallet.svg"
                                alt="wallet"
                                className="mx-2"
                              />
                              Wallet
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/cryptocurrency-wallet"
                                  className={`${
                                    currentPath === "/cryptocurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/web3-wallet"
                                  className={`${
                                    currentPath === "/web3-walle"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/multicurrency-wallet"
                                  className={`${
                                    currentPath === "/multicurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Wallet Clone App
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-wallet"
                                  className={`${
                                    currentPath === "/nft-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ethereum-wallet"
                                  className={`${
                                    currentPath === "/ethereum-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Ethereum Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/bitcoin-wallet"
                                  className={`${
                                    currentPath === "/bitcoin-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Bitcoin Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/mobile-wallet-app"
                                  className={`${
                                    currentPath === "/mobile-wallet-app"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Mobile Wallet App
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-wallet"
                                  className={`${
                                    currentPath === "/defi-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Wallet
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/white-label-wallet"
                                  className={`${
                                    currentPath === "/white-label-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Wallet
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/nft-development" ||
                                currentPath === "/nft-collection-launch" ||
                                currentPath === "/nft-marketplace" ||
                                currentPath === "/nft-gaming" ||
                                currentPath === "/nft-loan"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/nft.svg"
                                alt="nft"
                                className="mx-2"
                              />
                              NFT
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/nft-development"
                                  className={`${
                                    currentPath === "/nft-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-collection-launch"
                                  className={`${
                                    currentPath === "/nft-collection-launch"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label NFT Marketplace
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/nft-marketplace"
                                  className={`${
                                    currentPath === "/nft-marketplace"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Marketplace
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-loan"
                                  className={`${
                                    currentPath === "/nft-loan"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Loan
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/nft-gaming"
                                  className={`${
                                    currentPath === "/nft-gaming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Gaming Platform
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/defi-development" ||
                                currentPath === "/defi-staking" ||
                                currentPath === "/dapp-development-compoany" ||
                                currentPath === "/defi-lottery" ||
                                currentPath === "/dao-blockchain" ||
                                currentPath === "/defi-lending-and-borrowing" ||
                                currentPath === "/defi-yield-farming" ||
                                currentPath === "/olympus-dao-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/defi.svg"
                                alt="defi"
                                className="mx-2"
                              />
                              Defi
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/defi-development"
                                  className={`${
                                    currentPath === "/defi-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-staking"
                                  className={`${
                                    currentPath === "/defi-staking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Staking Platform
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/defi-lottery"
                                  className={`${
                                    currentPath === "/defi-lottery"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lottery
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/defi-lending-and-borrowing"
                                  className={`${
                                    currentPath ===
                                    "/defi-lending-and-borrowing"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lending And Borrowing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/defi-yield-farming"
                                  className={`${
                                    currentPath === "/defi-yield-farming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi yield Farming
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/web-30-devlopment" ||
                                currentPath === "/web3-game-devlopment"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                alt="web3"
                                className="mx-2"
                              />
                              Web3
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/web-30-devlopment"
                                  className={`${
                                    currentPath === "/web-30-devlopment"
                                      ? "xeer-color"
                                      : " "
                                  }`}
                                >
                                  Web 3.0 Devlopment
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/web3-game-devlopment"
                                  className={`${
                                    currentPath === "/web3-game-devlopment"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Game Devlopment
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/dex-aggregator" ||
                                currentPath === "/amm-dex" ||
                                currentPath === "/order-book-dexs"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/dexs.svg"
                                alt="dexs"
                                className="mx-2"
                              />
                              DEXs
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/decentralized-exchange"
                                  className={`${
                                    currentPath === "/decentralized-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Decentralized Exchange
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/dex-aggregator"
                                  className={`${
                                    currentPath === "/dex-aggregator"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Dex Aggregator
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/amm-dex"
                                  className={`${
                                    currentPath === "/amm-dex"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  AMM Dex
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/order-book-dexs"
                                  className={`${
                                    currentPath === "/order-book-dexs"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Order Book Dexs
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/crypto-banking" ||
                                currentPath ===
                                  "/cryptocurrency-payment-gateway"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/crypto_banking.svg"
                                alt="crypto_banking"
                                className="mx-2"
                              />
                              Crypto Banking
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/crypto-banking"
                                  className={`${
                                    currentPath === "/crypto-banking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Banking
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/cryptocurrency-payment-gateway"
                                  className={`${
                                    currentPath ===
                                    "/cryptocurrency-payment-gateway"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Payment Gateway
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/token-development" ||
                                currentPath === "/ido-development" ||
                                currentPath === "/crypto-launchpad" ||
                                currentPath === "/ico-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/coin_development.svg"
                                alt="coin_development"
                                className="mx-2"
                              />
                              Coin Development
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/token-development"
                                  className={`${
                                    currentPath === "/token-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Coin/Token Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ido-development"
                                  className={`${
                                    currentPath === "/ido-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  IDO Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/crypto-launchpad"
                                  className={`${
                                    currentPath === "/crypto-launchpad"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Launchpad
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ico-development"
                                  className={`${
                                    currentPath === "/ico-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  ICO Development
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="">
                            <Link
                              to="/crypto-gaming-app"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/wallet/dw/gaming.svg"
                                alt="gaming"
                                className="mx-2"
                              />
                              Crypto Gaming
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/trading-bot"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/trading_bot.svg"
                                alt="trading_bot"
                                className="mx-2"
                              />
                              Trading Bot
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/mlm"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/mlm_platform.svg"
                                alt="mlm_platform"
                                className="mx-2"
                              />
                              MLM
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/Blockchain-development" ||
                            currentPath === "/harshgraph-development" ||
                            currentPath === "/blockchain-game-development" ||
                            currentPath === "/substrate" ||
                            currentPath === "/tezos" ||
                            currentPath === "/cosmos" ||
                            currentPath === "/polygon-edge" ||
                            currentPath === "/polygon-blockchain"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Blockchain <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/Blockchain-development" ||
                                currentPath === "/harshgraph-development" ||
                                currentPath ===
                                  "/blockchain-game-development" ||
                                currentPath === "/dag-acyclic-graph"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/Asseblockchain_developmentt 1.svg"
                                alt="Asseblockchain_developmentt"
                                className="mx-2"
                              />
                              Blockchain Development
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/Blockchain-development"
                                  className={`${
                                    currentPath === "/Blockchain-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/harshgraph-development"
                                  className={`${
                                    currentPath === "/harshgraph-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Harshgraph Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/blockchain-game-development"
                                  className={`${
                                    currentPath ===
                                    "/blockchain-game-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Game Development
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/substrate" ||
                                currentPath === "/tezos" ||
                                currentPath === "/cosmos" ||
                                currentPath === "/polygon-edge" ||
                                currentPath === "/polygon-blockchain"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/blockchain_framworks.svg"
                                alt="blockchain_framworks"
                                className="mx-2"
                              />
                              Blockchain Frameworks
                            </Link>
                            <ul>
                              <li>
                                <a
                                  href="/substrate"
                                  className={`${
                                    currentPath === "/substrate"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  SubStrate
                                </a>
                              </li>

                              <li>
                                <a
                                  href="/tezos"
                                  className={`${
                                    currentPath === "/tezos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Tezos
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/cosmos"
                                  className={`${
                                    currentPath === "/cosmos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cosmos
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/polygon-edge"
                                  className={`${
                                    currentPath === "/polygon-edge"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon Edge
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/polygon-blockchain"
                                  className={`${
                                    currentPath === "/polygon-blockchain"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon BlockChain
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/layer_section.svg"
                                alt="layer_section"
                                className="mx-2"
                              />
                              Layer 1 & 2 Solution
                            </Link>
                            <ul>
                              <li>
                                <a href="/bsc">BSC</a>
                              </li>
                              <li>
                                <a href="/ethereum">Ethereum</a>
                              </li>
                              <li>
                                <a href="/solana">Solana</a>
                              </li>

                              <li>
                                <a href="/cardano">Cardano</a>
                              </li>
                              <li>
                                <a href="/polkadot">Polkadot</a>
                              </li>
                              <li>
                                <a href="/ton">TON</a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/smart_contract.svg"
                                alt="smart_contract"
                                className="mx-2"
                              />
                              Smart Contract
                            </Link>
                            <ul>
                              <li>
                                <a href="/smart-contract-development">
                                  Smart Contract Development
                                </a>
                              </li>
                              <li>
                                <a href="/deFi-smart-contract">
                                  DeFi Smart Contract
                                </a>
                              </li>
                              <li>
                                <a href="/smart-contract-audit">
                                  Smart Contract Audit
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/asset_tokenization.svg"
                                alt="asset_tokenization"
                                className="mx-2"
                              />
                              Assent Tekenization
                            </Link>
                            <ul>
                              <li>
                                <a href="/asset-tokenization">
                                  Asset Tokenization
                                </a>
                              </li>
                              <li>
                                <a href="/crypto-asset-management">
                                  Crypto Asset Management
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="">
                            <Link
                              to="/enterprise-solution"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/enterprise_solutions.svg"
                                alt="enterprise_solutions"
                                className="mx-2"
                              />
                              Enterprise Solution
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/securitization"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/securitization.svg"
                                alt="securitization"
                                className="mx-2"
                              />
                              Securitization
                            </Link>
                          </li>
                          <li className="p2p-lending">
                            <Link
                              to="/p2p-lending"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/p_to_p_leading.svg"
                                alt="p_to_p_leading"
                                className="mx-2"
                              />
                              P2P Lending
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link>
                          Listing <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li>
                            <a href="/exchange-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/exchange_listing.svg"
                                alt="exchange_listing"
                                className="mx-2"
                              />
                              Exchange Listing
                            </a>
                          </li>
                          <li>
                            <a href="/token-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/token_listing.svg"
                                alt="token_listing"
                                className="mx-2"
                              />
                              Token Listing
                            </a>
                          </li>
                          <li>
                            <a href="/coingecko-and-cmc-listing">
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/one/cmc_listing.svg"
                                alt="cmc_listing"
                                className="mx-2"
                              />
                              CoinGecko & CMC Listing
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="/marketing"> Marketing</a>
                        <ul>
                          <li>
                            <a href="#crypto-token-marketing">
                              Crypto Token Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#pr-and-media">PR & Media</a>
                          </li>
                          <li>
                            <a href="#marketing-strategies">
                              Marketing Strategies
                            </a>
                          </li>
                          <li>
                            <a href="#social-media-marketing">
                              Social Media Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#influence-marketing">
                              Influence Marketing
                            </a>
                          </li>
                          <li>
                            <a href="#web-campaigns">Web3 Campaigns</a>
                          </li>
                          <li>
                            <a href="#content-marketing">Content Marketing</a>
                          </li>
                          <li>
                            <a href="#community-building-and-management">
                              Community Building & Management
                            </a>
                          </li>
                          <li>
                            <a href="#airdrop-marketing">Airdrop Marketing</a>
                          </li>
                          <li>
                            <a href="#rebranding">Rebranding</a>
                          </li>
                          <li>
                            <a href="#pitch-deck-design">Pitch Deck Design</a>
                          </li>
                          <li>
                            <a href="#fund-raising">Fund Raising</a>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <a href="/blogs">Blog</a>
                      </li> */}
                      <li>
                        <a href="/contact-us">Hire Developer</a>
                      </li>
                      <li className="dropdown">
                        <Link
                          to="/about-us"
                          className={`${
                            currentPath === "/about-us" ? "xeer-color  " : " "
                          }`}
                        >
                          About Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="btn-box">
                  <a href="/contact-us" className="theme-btn btn-one">
                    Get a Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop" />
          <div>
            <div className="close-btn" onClick={toggleHeader}>
              <i className="fas fa-times" />
            </div>
          </div>
          <nav className="menu-box pt-2">
            <div>
              <a href="#" className="m-3">
                <img
                  src="assets/img/logo.svg"
                  style={{ width: "150px" }}
                  alt="logo"
                />
              </a>
            </div>
            <div className="menu-outer mt-4">
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
              <div
                className="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li className="dropdown">
                    <a
                      onClick={() => {
                        showtab?.split(".")[0] !== "1"
                          ? setShowTab("1.0")
                          : setShowTab(null);
                      }}
                    >
                      Solutions
                    </a>
                    <ul
                      className={
                        showtab?.split(".")[0] === "1" ? "d-block " : "d-none"
                      }
                    >
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.1"
                              ? setShowTab("1.0")
                              : setShowTab("1.1");
                          }}
                        >
                          Exchange
                        </a>
                        <ul
                          className={showtab === "1.1" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/exchange">Cryptocurrency Exchange</a>
                          </li>
                          <li>
                            <a href="/white-label-exchange">
                              White Label Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/margin-trading-exchange">
                              Margin Trading Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/security-exchange">Security Exchange</a>
                          </li>

                          <li>
                            <a href="/p2p-exchange">P2P Exchange</a>
                          </li>
                          <li>
                            <a href="/decentralized-exchange">
                              Decentralized Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/derivatives-exchange">
                              Derivatives Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/arbitrage-trading-bots">
                              Arbitrage Trading Bots
                            </a>
                          </li>
                          <li>
                            <a href="/market-making-services">
                              Market Making Services
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.2"
                              ? setShowTab("1.0")
                              : setShowTab("1.2");
                          }}
                        >
                          Wallet
                        </a>
                        <ul
                          className={showtab === "1.2" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/cryptocurrency-wallet">
                              Cryptocurrency Wallet
                            </a>
                          </li>
                          <li>
                            <a href="/web3-wallet">Web3 Wallet</a>
                          </li>
                          <li>
                            <a href="/multicurrency-wallet">
                              Crypto Wallet Clone App
                            </a>
                          </li>
                          <li>
                            <a href="/nft-wallet">NFT Wallet</a>
                          </li>
                          <li>
                            <a href="/ethereum-wallet">Ethereum Wallet </a>
                          </li>
                          <li>
                            <a href="/bitcoin-wallet">Bitcoin Wallet</a>
                          </li>
                          <li>
                            <a href="/mobile-wallet-app">Mobile Wallet App</a>
                          </li>
                          <li>
                            <a href="/defi-wallet">Defi Wallet</a>
                          </li>
                          <li>
                            <a href="/white-label-wallet">White Label Wallet</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.3"
                              ? setShowTab("1.0")
                              : setShowTab("1.3");
                          }}
                        >
                          NFT
                        </a>
                        <ul
                          className={showtab === "1.3" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/nft-development">NFT Development</a>
                          </li>
                          <li>
                            <a href="/nft-collection-launch">
                              White Label NFT Marketplace
                            </a>
                          </li>

                          <li>
                            <a href="/nft-marketplace">NFT Marketplace</a>
                          </li>
                          <li>
                            <a href="/nft-loan">NFT Loan</a>
                          </li>

                          <li>
                            <a href="/nft-gaming">NFT Gaming</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.4"
                              ? setShowTab("1.0")
                              : setShowTab("1.4");
                          }}
                        >
                          Defi
                        </a>
                        <ul
                          className={showtab === "1.4" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/defi-development">Defi Development</a>
                          </li>
                          <li>
                            <a href="/defi-staking">Defi Staking</a>
                          </li>

                          <li>
                            <a href="/defi-lottery">Defi Lottery</a>
                          </li>

                          <li>
                            <a href="/defi-lending-and-borrowing">
                              Defi Lending And Borrowing
                            </a>
                          </li>
                          <li>
                            <a href="/defi-yield-farming">Defi yield Farming</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.5"
                              ? setShowTab("1.0")
                              : setShowTab("1.5");
                          }}
                        >
                          Web3
                        </a>
                        <ul
                          className={showtab === "1.5" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/web-30-devlopment">Web 3.0 Devlopment</a>
                          </li>
                          <li>
                            <a href="/web3-game-devlopment">
                              Web3 Game Devlopment
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.6"
                              ? setShowTab("1.0")
                              : setShowTab("1.6");
                          }}
                        >
                          DEXs
                        </a>
                        <ul
                          className={showtab === "1.6" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/decentralized-exchange">
                              Decentralized Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/dex-aggregator">Dex Aggregator</a>
                          </li>
                          <li>
                            <a href="/amm-dex">AMM Dex</a>
                          </li>
                          <li>
                            <a href="/order-book-dexs">Order Book Dexs</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.7"
                              ? setShowTab("1.0")
                              : setShowTab("1.7");
                          }}
                        >
                          Crypto Banking
                        </a>
                        <ul
                          className={showtab === "1.7" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/crypto-banking">Crypto Banking</a>
                          </li>
                          <li>
                            <a href="/cryptocurrency-payment-gateway">
                              Cryptocurrency Payment Gateway
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>

                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "1.8"
                              ? setShowTab("1.0")
                              : setShowTab("1.8");
                          }}
                        >
                          Coin Development
                        </a>
                        <ul
                          className={showtab === "1.8" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/token-development">
                              Coin/Token Development
                            </a>
                          </li>
                          <li>
                            <a href="/ido-development">IDO Development</a>
                          </li>
                          <li>
                            <a href="/crypto-launchpad">Crypto Launchpad</a>
                          </li>
                          <li>
                            <a href="/ico-development">ICO Development</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/crypto-gaming-app">Crypto Gaming</a>

                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/trading-bot">Trading Bot</a>

                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/mlm"> MLM</a>
                      </li>
                    </ul>
                    <div className="dropdown-btn">
                      <span className="fas fa-angle-down" />
                    </div>
                  </li>
                  {/* <li>
                    <a href="about.html">About</a>
                  </li> */}
                  <li className="dropdown">
                    <a
                      onClick={() => {
                        showtab?.split(".")[0] !== "2"
                          ? setShowTab("2.0")
                          : setShowTab(null);
                      }}
                    >
                      Blockchain
                    </a>
                    <ul
                      className={
                        showtab?.split(".")[0] === "2" ? "d-block " : "d-none"
                      }
                    >
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "2.1"
                              ? setShowTab("2.0")
                              : setShowTab("2.1");
                          }}
                        >
                          Blockchain Development
                        </a>
                        <ul
                          className={showtab === "2.1" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/Blockchain-development">
                              Blockchain Development
                            </a>
                          </li>
                          <li>
                            <a href="/harshgraph-development">
                              Harshgraph Development
                            </a>
                          </li>
                          <li>
                            <a href="/blockchain-game-development">
                              Blockchain Game Development
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "2.2"
                              ? setShowTab("2.0")
                              : setShowTab("2.2");
                          }}
                        >
                          Blockchain Frameworks
                        </a>
                        <ul
                          className={showtab === "2.2" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/substrate">SubStrate</a>
                          </li>

                          <li>
                            <a href="/tezos">Tezos</a>
                          </li>
                          <li>
                            <a href="/cosmos">Cosmos</a>
                          </li>
                          <li>
                            <a href="/polygon-edge">Polygon Edge</a>
                          </li>
                          <li>
                            <a href="/polygon-blockchain">Polygon BlockChain</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "2.3"
                              ? setShowTab("2.0")
                              : setShowTab("2.3");
                          }}
                        >
                          Layer 1 & 2 Solution
                        </a>
                        <ul
                          className={showtab === "2.3" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/bsc">BSC</a>
                          </li>
                          <li>
                            <a href="/ethereum">Ethereum</a>
                          </li>
                          <li>
                            <a href="/solana">Solana</a>
                          </li>

                          <li>
                            <a href="/cardano">Cardano</a>
                          </li>
                          <li>
                            <a href="/polkadot">Polkadot</a>
                          </li>
                          <li>
                            <a href="/ton">TON</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "2.4"
                              ? setShowTab("2.0")
                              : setShowTab("2.4");
                          }}
                        >
                          Smart Contract
                        </a>
                        <ul
                          className={showtab === "2.4" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/smart-contract-development">
                              Smart Contract Development
                            </a>
                          </li>
                          <li>
                            <a href="/deFi-smart-contract">
                              DeFi Smart Contract
                            </a>
                          </li>
                          <li>
                            <a href="/smart-contract-audit">
                              Smart Contract Audit
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a
                          onClick={() => {
                            showtab === "2.5"
                              ? setShowTab("2.0")
                              : setShowTab("2.5");
                          }}
                        >
                          Assent Tekenization
                        </a>
                        <ul
                          className={showtab === "2.5" ? "d-block " : "d-none"}
                        >
                          <li>
                            <a href="/asset-tokenization">Asset Tokenization</a>
                          </li>
                          <li>
                            <a href="/crypto-asset-management">
                              Crypto Asset Management
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/enterprise-solution">Enterprise Blockchain</a>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/securitization">Securitization</a>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/p2p-lending">P2P Lending</a>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-btn">
                      <span className="fas fa-angle-down" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      onClick={() => {
                        showtab === "3.1"
                          ? setShowTab("3.0")
                          : setShowTab("3.1");
                      }}
                    >
                      Listing
                    </a>
                    <ul className={showtab === "3.1" ? "d-block " : "d-none"}>
                      <li>
                        <a href="/exchange-listing">Exchange Listing</a>
                      </li>
                      <li>
                        <a href="/token-listing">Token Listing</a>
                      </li>
                      <li>
                        <a href="/coingecko-and-cmc-listing">
                          Coingecko & CMC Listing
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <a href="/marketing">Marketing</a>
                  </li>
                  <li className="dropdown">
                    <a href="/contact-us">Hire Developer</a>
                  </li>
                </ul>
                <a class="theme-btn btn-one py-2 ms-3" href="/contact-us">
                  Get a Quote
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
