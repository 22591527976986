import React from "react";

export default function HowDoWork() {
  return (
    <>
      <section
        className="feature-section p-5"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="inner_wrap text-center pb-5">
          <div data-animation-box="true" className="sec-title pb-2">
            <h2>How do we work</h2>
          </div>
          <p>We work with focusing on your desirable outcome</p>
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <h4 style={{ color: "#1a73e8" }}>
                    1. Project Idea Discussion
                  </h4>
                  <p>
                    We meet customers in set place to discuss the details about
                    needs and demands before proposing a plan.
                  </p>
                  <h4 style={{ color: "#1a73e8" }}>
                    2. Conceptualization & Product Blue Print
                  </h4>
                  <p>
                    Our experts come up with all kinds of ideas and initiatives
                    for delivering the best solutions for IT services chosen.
                  </p>
                  <h4 style={{ color: "#1a73e8" }}>
                    3. Development, Testing & Amendments
                  </h4>
                  <p>
                    After agreeing on the ideas and plans, we will conduct as
                    scheduled and give comments on the results & adaptations.
                  </p>
                  <h4 style={{ color: "#1a73e8" }}>
                    4. Final Execution & Product Release
                  </h4>
                  <p>
                    Once the final plan is approved, everything will be
                    conducted according to the agreed contract.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 pt-3">
              <div className="">
                <img
                  src="assets/img/how_do_we_work.svg"
                  alt="how_do_we_work"
                  style={{ height: "506px", width: "610px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
