import React from "react";

export default function BoostBottom() {
  return (
    <>
      <section className="clients-section alternat-2 py_30 centred" style={{ backgroundColor: "#071522" }}>
        <div className="auto-container">
          <div className="inner-container pb-4">
            <h1 className="text-white">
              Use our script & quickly launch your crypto exchange 🚀
            </h1>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
