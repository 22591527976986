import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function CryptoAssetManHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="simplify-and-secure-your-crypto-asset-management"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/asset-tok/simplify-and-secure-your-crypto-asset-management.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center py_50">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Simplify and Secure Your Crypto Asset Management
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Empowering organizations with comprehensive solutions for
                    secure and efficient crypto asset management.
                  </p>
                </div>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
