import React from "react";

export default function ContentMarketing() {
  return (
    <>
      <section
        className="feature-section py_50"
        id="content-marketing"
        style={{
          backgroundImage: "url(assets/img/04.png",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
        }}
      >
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">Content Marketing</h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/content_marketing.png"
                      alt="content_marketing"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h1>
                    Drive Awareness with High-Quality, Informative Content
                  </h1>
                  <p className="py-3">
                    Content is at the core of any successful marketing strategy.
                    Our content marketing services provide valuable,
                    educational, and engaging material that positions your
                    project as a thought leader in the crypto space.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Blog & Article Writing</h4>
                      <p>
                        Inform and engage your audience with well-researched
                        content.
                      </p>
                    </li>
                    <li>
                      <h4>Video Content Creation</h4>
                      <p>Produce engaging video content for broader reach.</p>
                    </li>
                    <li>
                      <h4>SEO Optimization</h4>
                      <p>
                        Maximize visibility with optimized content strategies.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Explore Content Marketing Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
