import React from "react";

export default function WhatisCryWallet() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Cryptocurrency Wallet</h2>
              <p>
                A cryptocurrency wallet or crypto wallet is a digital tool that
                enables users to securely store, send, and receive
                cryptocurrencies, functioning as a critical gateway to
                interacting with blockchain networks. Unlike traditional wallets
                that physically hold cash, a crypto wallet stores the public and
                private keys—unique cryptographic credentials used to access and
                manage digital assets stored on the blockchain. These keys are
                essential for verifying ownership and authorizing transactions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
