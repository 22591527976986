import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import DefiYienldFarmingHero from "./DefiYienldFarmingHero";
import Yield from "./Yield";
import LookingD from "../About-Us/LookingD";
import DeFiConS from "./DeFiConS";
import Forward from "./Forward";
import Protocols from "./Protocols";
import IconCurve from "./IconCurve";
import WhyYield from "./WhyYield";
import { Helmet } from "react-helmet";

export default function DefiYienldFarming() {
  return (
    <>
      <Helmet>
        <title> DeFi Yield Farming Development | Xeer Technology</title>
        <meta
          name="description"
          content="Enable users to maximize returns with yield farming platforms by Xeer Technology. Secure, scalable, and user-friendly solutions."
        />
      </Helmet>
      <div>
        <Header />
        <DefiYienldFarmingHero />
        <Yield />
        <DeFiConS />
        <Forward />
        <Protocols />
        <IconCurve />
        <WhyYield />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
