import React from "react";

export default function UnleashNew() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_40 pb_40 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="">
            <h1 className="text-white">Unleash New Revenue Opportunities</h1>
            <p className="text-white p-4">
              Businesses can monetize Web3 wallets through transaction fees,
              token swaps, staking services, and offering premium features,
              capitalizing on the growing demand for decentralized solutions.
            </p>
          </div>
          <div className="">
            <a href="/contact-us" className="theme-btn btn-one m_20">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
