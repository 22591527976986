import React from "react";

export default function GlobalReach() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Key Features & Functionalities of Our Crypto Payment Gateway
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/multi_currency_support.svg"
                      alt="multi_currency_support"
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <h3>Multi-Currency Support</h3>
                  <p>
                    Accept a wide range of cryptocurrencies, including Bitcoin,
                    Ethereum, and stablecoins, enabling customers to pay using
                    their preferred digital assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/instant_conversion_to_fiat.svg"
                      alt="instant_conversion_to_fiat"
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <h3>Instant Conversion to Fiat</h3>
                  <p>
                    Automatically convert crypto payments into fiat currency in
                    real-time, reducing volatility risks for merchants and
                    ensuring stable revenue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/seamless_dapp_integration.svg"
                      alt="seamless_dapp_integration"
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <h3>Seamless Integration</h3>
                  <p>
                    Easily integrate our payment gateway with popular e-commerce
                    platforms, POS systems, and custom websites, allowing
                    businesses to start accepting crypto payments quickly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/comprehensive_security.svg"
                      alt="comprehensive_security"
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <h3>Enhanced Security</h3>
                  <p>
                    Built with advanced security protocols, including encryption
                    and multi-signature wallets, to protect transactions and
                    safeguard sensitive customer information.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/Seamless_ui_ux_01.svg"
                      alt="Seamless_ui_ux_01"
                      width={30}
                      height={30}
                      className="mb-2"
                    />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    A simple and intuitive interface for both merchants and
                    customers, ensuring a smooth payment experience from start
                    to finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/robust_admin_and_user_dashboard.svg"
                      alt="robust_admin_and_user_dashboard"
                      width={30}
                      height={30}
                      className="mb-2"
                    />
                  </div>
                  <h3>Comprehensive Analytics Dashboard</h3>
                  <p>
                    Provide merchants with valuable insights into transaction
                    history, customer behavior, and payment trends to inform
                    business decisions and strategies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/global_reach.svg"
                      alt="global_reach"
                      width={40}
                      height={40}
                      className="mb-2"
                    />
                  </div>
                  <h3>Global Reach</h3>
                  <p>
                    Enable businesses to tap into the growing global
                    cryptocurrency market, attracting new customers and
                    expanding their reach beyond traditional payment methods.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/fiat_payment_gateway.svg"
                      alt="fiat_payment_gateway"
                      width={30}
                      height={30}
                      className="mb-2"
                    />
                  </div>
                  <h3>Flexible Payment Options</h3>
                  <p>
                    Support various payment methods, including QR code payments,
                    online checkouts, and in-store transactions, enhancing
                    convenience for users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
