import React from "react";

export default function SolanaHero() {
  return (
    <div>
      <section
        className="feature-section py_150"
        aria-label="unlock-the-potential-of-solana-with-xeers-blockchain-solutions"
        style={{
          backgroundImage:
            "url(assets/img/layer/unlock-the-potential-of-solana-with-xeers-blockchain-solutions.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">
                    Unlock the Potential of Solana with Xeer’s Blockchain
                    Solutions
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology specializes in building scalable,
                    high-performance applications on the Solana blockchain,
                    known for its lightning-fast transactions and low fees.
                  </p>
                </div>
                <div className="btn-box mt_40">
                  <a href="/contact-us" className="theme-btn btn-one mr_20">
                    Hire Blockchain Expert
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
