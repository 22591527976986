import React from "react";
import Header from "../../Coman/Header";
import SubStrateHero from "./SubStrateHero";
import ConnectwithUsExpert from "./ConnectwithUsExpert";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import { Helmet } from "react-helmet";

export default function SubStrate() {
  return (
    <>
      <Helmet>
        <title>Substrate Blockchain Development | Xeer Technology</title>
        <meta
          name="description"
          content="Leverage Substrate for customizable blockchain solutions. Xeer specializes in building scalable and efficient Substrate-powered networks."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <SubStrateHero />
        <ConnectwithUsExpert />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
