import React from "react";

export default function Simplification() {
  return (
    <>
      <section className="feature-style-three pb_150 centred pt-5">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-34.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-35.png)" }}
          />
        </div>
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25">
            <h2>Our DeFi Development Services</h2>
          </div>
          <p className="pb-3">
            Explore our 360 degree DeFi development services that serve all
            needs in the DeFi industry. Whether a lending-borrowing protocol or
            yield farming platform, Xeer Technology offers development services
            for all!
          </p>
          <div className="inner-container">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/decentralized_exchange.svg"
                        alt="decentralized_exchange"
                      />
                    </div>
                    <h3>Decentralized Exchange (DEX) Development</h3>
                    <p>
                      We develop robust DEX platforms that allow users to trade
                      assets directly, eliminating intermediaries and reducing
                      transaction costs while maintaining the highest security
                      standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/defi_lending _borrowing_platforms.svg"
                        alt="defi_lending _borrowing_platforms"
                      />
                    </div>
                    <h3>DeFi Lending & Borrowing Platforms</h3>
                    <p>
                      Build peer-to-peer lending and borrowing platforms where
                      users can securely lend their assets or access
                      decentralized loans, leveraging collateralized
                      cryptocurrencies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/yield_farming _staking_platforms.svg"
                        alt="yield_farming _staking_platforms"
                      />
                    </div>
                    <h3>Yield Farming & Staking Platforms</h3>
                    <p>
                      Enable users to maximize their returns by offering secure
                      yield farming and staking platforms. Our smart
                      contract-based solutions ensure automated rewards and
                      transparent operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/liquidity_pooll.svg"
                        alt="liquidity_pooll"
                      />
                    </div>
                    <h3>Liquidity Pool & AMM Development</h3>
                    <p>
                      Create automated market-making (AMM) platforms and
                      liquidity pools that facilitate smooth and efficient
                      trading, ensuring liquidity providers earn incentives
                      while improving market depth.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/defi_wallet_development.svg"
                        alt="defi_wallet_development"
                      />
                    </div>
                    <h3>DeFi Wallet Development</h3>
                    <p>
                      We build secure, non-custodial wallets that allow users to
                      manage their DeFi assets, interact with decentralized
                      applications (dApps), and participate in various DeFi
                      protocols seamlessly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/smart_contract_development.svg"
                        alt="smart_contract_development"
                      />
                    </div>
                    <h3>Smart Contract Development & Auditing</h3>
                    <p>
                      Xeer Technology ensures the development of secure,
                      transparent, and self-executing smart contracts, and we
                      provide rigorous smart contract auditing services to
                      eliminate vulnerabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/liquidity.svg"
                        alt="liquidity"
                      />
                    </div>
                    <h3>Liquidity</h3>
                    <p>
                      DeFi promotes financial inclusion by enabling access to
                      financial services to the underbanked or unbanked
                      population.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/innovation_enablement.svg"
                        alt="innovation_enablement"
                      />
                    </div>
                    <h3>Innovation Enablement</h3>
                    <p>
                      DeFi’s open, permissionless, and programmable architecture
                      enables the creation of financial services and instruments
                      that meet users’ needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-two">
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "60px", paddingBlockEnd: "15px" }}
                        src="assets/img/defi/defi-dev/enhanced_security.svg"
                        alt="enhanced_security"
                      />
                    </div>
                    <h3>Enhanced Security</h3>
                    <p>
                      Blockchain architecture ensures tamper proof, secure and
                      auditable data while bringing users on the safer side.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
