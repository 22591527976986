import React from "react";

export default function TraditionalSupply() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "rgb(7, 21, 34)" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Build on TON with Xeer Technology</h2>
            <p className="text-white py-3">
              Let Xeer Technology be your partner in creating advanced
              blockchain solutions on TON. Reach out to our team to learn more
              about how our TON development services can drive growth for your
              project in this dynamic ecosystem.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one m_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
