import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function CryptoMarketingHero() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="unlock-growth"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/marketing/unlock-growth.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mb_55">
                <h2 className="text-black py-3">
                  Unlock Growth with Xeer Technology’s Crypto Marketing
                  Expertise
                </h2>
                <p className="text-black">
                  From token launches to brand visibility, we offer tailored
                  marketing solutions to drive your project's success in the
                  Web3 ecosystem.
                </p>
                <div className="btn-box mt_40">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="image-box ml_40 mr_50"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
