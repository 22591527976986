import React from "react";
import Header from "../Coman/Header";
import OrderBookDexsHero from "./OrderBookDexsHero";
import Footar from "../Coman/Footar";
import WhatOrderDex from "./WhatOrderDex";
import RoleofOrderB from "./RoleofOrderB";
import LookingD from "../About-Us/LookingD";
import WhyChooseOBD from "./WhyChooseOBD";
import WhatOrderBookDEX from "./WhatOrderBookDEX";
import XeerDEXDev from "./XeerDEXDev";
import OrderBDEXByXeer from "./OrderBDEXByXeer";
import { Helmet } from "react-helmet";

export default function OrderBookDexs() {
  return (
    <>
      <Helmet>
        <title>Order Book DEX Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build high-performance order book DEX platforms with Xeer. Seamlessly manage orders, trades, and liquidity with advanced blockchain integration."
        />
      </Helmet>
      <div>
        <Header />
        <OrderBookDexsHero />
        <WhatOrderBookDEX />
        <RoleofOrderB />
        <OrderBDEXByXeer />
        <WhatOrderDex />
        <XeerDEXDev />
        <WhyChooseOBD />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
