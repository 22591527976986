import React from "react";

export default function OurWorldClass() {
  return (
    <>
      <section className="contact-info-section centred pt_70 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Stunning Features of our Web3 Wallet</h2>
            <p>
              Innovative solutions designed for secure asset management,
              seamless dApp interactions, and enhanced user control, ensuring a
              robust and user-friendly experience in the decentralized
              ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/user_control.svg"
                      alt="user_control"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>User Control</h3>
                  <p>
                    Users retain full control over their private keys, ensuring
                    ownership and security of their assets. <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "358px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/multi_blockchain_support.svg"
                      alt="multi_blockchain_support"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Multi-Blockchain Support</h3>
                  <p>
                    Compatibility with multiple blockchain networks, allowing
                    users to manage various cryptocurrencies and tokens in one
                    place.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/seamless_dapp_integration.svg"
                      alt="seamless_dapp_integration"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Seamless dApp Integration</h3>
                  <p>
                    Easy connection to decentralized applications (dApps) for
                    activities like trading, lending, and gaming.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/enhanced_security.svg"
                      alt="enhanced_security"
                      width={55}
                      height={55}
                    />
                  </div>
                  <h3>Enhanced Security</h3>
                  <p>
                    Advanced security measures, including encryption, biometric
                    authentication, and multi-signature support to protect user
                    assets.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "383px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/built_in_swap_functionality.svg"
                      alt="built_in_swap_functionality"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Built-in Swap Functionality</h3>
                  <p>
                    Allowing users to swap tokens directly within the wallet for
                    greater convenience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "383px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/multi_blockchain_support.svg"
                      alt="multi_blockchain_support"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Multi-Currency Support</h3>
                  <p>
                    Ability to store and manage different types of
                    cryptocurrencies and tokens, including NFTs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "383px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/user_control.svg"
                      alt="user_control"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    Intuitive and accessible design for users of all experience
                    levels, from beginners to advanced traders.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/kyc_and_aml_compliance.svg"
                      alt="kyc_and_aml_compliance"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>KYC and AML Compliance</h3>
                  <p>
                    Integration of Know Your Customer (KYC) and Anti-Money
                    Laundering (AML) protocols to ensure regulatory compliance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "342px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/backup.svg"
                      alt="backup"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Backup and Recovery Options</h3>
                  <p>
                    Secure backup features and recovery phrases to help users
                    restore access to their wallets if needed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "342px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/transaction_history.svg"
                      alt="transaction_history"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Transaction History</h3>
                  <p>
                    Detailed transaction records and analytics for users to
                    track their assets and activities. <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "342px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/cross_platform_access.svg"
                      alt="cross_platform_access"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Cross-Platform Access</h3>
                  <p>
                    Availability on multiple platforms, including web, mobile,
                    and desktop, for easy asset management on the go.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "342px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/customizable_Features.svg"
                      alt="customizable_Features"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Customizable Features</h3>
                  <p>
                    Options for users to customize settings, themes, and
                    notification preferences for a personalized experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/support_for_staking_and_yield_farming.svg"
                      alt="support_for_staking_and_yield_farming"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>Support for Staking and Yield Farming</h3>
                  <p>
                    Features that allow users to stake their assets or
                    participate in yield farming directly from the wallet.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="pb-3">
                    <img
                      src="assets/img/wallet/in_app_support_and_resources.svg"
                      alt="in_app_support_and_resources"
                      width={60}
                      height={60}
                    />
                  </div>
                  <h3>In-App Support and Resources</h3>
                  <p>
                    Access to customer support and educational resources to help
                    users navigate and maximize their wallet experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
