import React from "react";

export default function ModulesofAsset() {
  return (
    <>
      <section className="feature-section py_50">
        <div className="auto-container">
          <h1 className="text-center">Our Asset Tokenization Services</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Real Estate Tokenization</h4>
                      <p>
                        Unlock property liquidity by tokenizing real estate
                        assets, enabling fractional ownership and 24/7 trading
                        opportunities for investors.
                      </p>
                    </li>
                    <li>
                      <h4>Commodities Tokenization</h4>
                      <p>
                        Tokenize precious metals, energy commodities, and other
                        tangible assets to enable secure, global trading and
                        efficient ownership transfer.
                      </p>
                    </li>
                    <li>
                      <h4>Intellectual Property Tokenization</h4>
                      <p>
                        Make intellectual property rights accessible and
                        divisible, allowing creators and investors to monetize
                        IP securely.
                      </p>
                    </li>
                    <li>
                      <h4>Collectibles and Fine Art</h4>
                      <p>
                        Make high-value art and collectibles investment-ready by
                        transforming ownership into digital tokens, bringing
                        transparency and fractional ownership.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/asset-tok/our-asset-tokenization-services.png"
                      alt="our-asset-tokenization-services"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
