import React from "react";

export default function Seamless() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Finance Redefined with Crypto Banking Industry</h2>
              <p>
                The crypto banking industry represents a transformative shift in
                the way financial services are accessed and managed. By
                integrating blockchain technology with traditional banking
                functions, crypto banking enables secure, transparent, and
                efficient financial transactions, making financial freedom more
                accessible globally. As cryptocurrencies gain acceptance, the
                crypto banking industry is bridging the gap between digital
                assets and mainstream financial services, redefining how users
                interact with their money.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
