import React from "react";

export default function AmmDEXDev() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white pb-4">
              Start building your AMM DEX with Xeer Technology <br /> contact us
              today to bring your DeFi vision to life!
            </h2>
          </div>
          <a href="/contact-us" className="theme-btn btn-one  mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
