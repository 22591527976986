import React from "react";

export default function BlockchainforTransportation() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Industry-Specific Blockchain Solutions</h2>
            <p></p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/healthcare_medicine.svg"
                      alt="healthcare_medicine"
                    />
                  </div>
                  <h3 className="text-start pt-3">Healthcare & Medicine</h3>
                  <p className="text-start">
                    Blockchain improves patient data security, interoperability,
                    and transparency in healthcare. From managing patient
                    records to tracking pharmaceutical supply chains, our
                    blockchain solutions ensure data integrity and compliance
                    with health regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/fintech_banking.svg"
                      alt="fintech_banking"
                    />
                  </div>
                  <h3 className="text-start pt-3">Fintech & Banking</h3>
                  <p className="text-start">
                    Streamline transactions, reduce fraud, and improve financial
                    transparency. Xeer Technology’s blockchain solutions for
                    Fintech and Banking enable secure, real-time payments, smart
                    contracts, and decentralized financial services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/supply_chain.svg"
                      alt="supply_chain"
                    />
                  </div>
                  <h3 className="text-start pt-3">Supply Chain</h3>
                  <p className="text-start">
                    Enhance traceability, efficiency, and trust within your
                    supply chain. Our blockchain solutions track products from
                    origin to end-consumer, reducing fraud, ensuring quality,
                    and building consumer trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/edtech_el_earning.svg"
                      alt="edtech_el_earning"
                    />
                  </div>
                  <h3 className="text-start pt-3">EdTech & E-Learning</h3>
                  <p className="text-start">
                    Provide secure and verifiable credentialing, transparent
                    tracking, and student data security. We help educational
                    platforms implement blockchain to streamline certifications,
                    attendance, and student data management.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/marketing_sales.svg"
                      alt="marketing_sales"
                    />
                  </div>
                  <h3 className="text-start pt-3">Marketing & Sales</h3>
                  <p className="text-start">
                    Blockchain redefines digital advertising by ensuring ad
                    transparency and data privacy. Our solutions empower
                    marketers with tamper-proof tracking, fair compensation, and
                    decentralized digital identity solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/e_commerce_retail.svg"
                      alt="e_commerce_retail"
                    />
                  </div>
                  <h3 className="text-start pt-3">E-Commerce & Retail</h3>
                  <p className="text-start">
                    Build customer trust through transparent transactions,
                    streamlined logistics, and better inventory management.
                    Blockchain enhances traceability and offers decentralized
                    marketplaces, ensuring secure, reliable operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/arts_entertainment.svg"
                      alt="arts_entertainment"
                    />
                  </div>
                  <h3 className="text-start pt-3">Arts & Entertainment</h3>
                  <p className="text-start">
                    Empower creators with secure digital ownership and fair
                    compensation through NFTs and blockchain-based royalties.
                    Our solutions create secure marketplaces for digital art,
                    music, and other entertainment assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/customer_service_hr.svg"
                      alt="customer_service_hr"
                    />
                  </div>
                  <h3 className="text-start pt-3">Customer Service & HR</h3>
                  <p className="text-start">
                    Optimize recruitment, background checks, and employee data
                    management with blockchain. We offer solutions that simplify
                    onboarding, secure employee data, and improve payroll
                    transparency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/legal_compliance.svg"
                      alt="legal_compliance"
                    />
                  </div>
                  <h3 className="text-start pt-3">Legal & Compliance</h3>
                  <p className="text-start">
                    Automate contract execution, improve record-keeping, and
                    ensure regulatory compliance. Xeer Technology’s blockchain
                    solutions make legal processes faster, more secure, and more
                    transparent.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="text-start">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/securitization/svg/real_estate.svg"
                      alt="real_estate"
                    />
                  </div>
                  <h3 className="text-start pt-3">Real Estate</h3>
                  <p className="text-start">
                    Reduce paperwork and improve property transaction
                    transparency. Our blockchain solutions support tokenized
                    assets, digital deeds, and efficient property management,
                    making real estate transactions simpler and more secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
