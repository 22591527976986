import React from "react";

export default function DailyWith() {
  return (
    <>
      <section className="glance-section centred py_120">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Web3 Gaming Market Insight</h2>
              <p>
                The Web3 gaming market is experiencing explosive growth, with
                market valuation projected to reach approximately $65.7 billion
                by 2027, growing at a CAGR of 21.9% from the past year. This
                surge is driven by the increasing popularity of decentralized
                gaming ecosystems, NFTs, and play-to-earn models, where players
                have true ownership of in-game assets.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
