import React from "react";

export default function MarketingStrategies() {
  return (
    <>
      <section className="feature-section py_50" id="marketing-strategies">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">Marketing Strategies</h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <p className="py-3">
                    <h2>
                      Tailored Marketing Strategies to Align with Your Vision
                    </h2>
                    Every project is unique, and so is our approach. We develop
                    comprehensive, data-driven marketing strategies designed to
                    meet your specific goals, whether it’s brand awareness, user
                    acquisition, or engagement.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Research-Driven Campaigns</h4>
                      <p>
                        In-depth market and competitor analysis to guide
                        strategy.
                      </p>
                    </li>
                    <li>
                      <h4>Performance Tracking</h4>
                      <p>Monitor key metrics to optimize campaigns.</p>
                    </li>
                    <li>
                      <h4>End-to-End Execution</h4>
                      <p>From planning to implementation, we handle it all.</p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Uncover Strategic Marketing Solutions
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/marketing/marketing-strategies.png"
                      alt="marketing-strategies"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
