import React from "react";

export default function OurBestSelling() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Best Selling White Label Crypto Exchange Scripts</h2>
          </div>

          <div className="d-flex gap-2 flex-wrap justify-content-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "80px" }}
                        src="assets/img/exchange/wle/bnb.svg"
                        alt="bnb"
                      />
                    </div>
                    <h5>Binance </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "80px" }}
                        src="assets/img/exchange/wle/coinbase.svg"
                        alt="coinbase"
                      />
                    </div>
                    <h5>Coinbase</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "80px" }}
                        src="assets/img/exchange/wle/bybit.svg"
                        alt="bybit"
                      />
                    </div>
                    <h5>ByBit</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "80px" }}
                        src="assets/img/exchange/wle/wazirx.svg"
                        alt="wazirx"
                      />
                    </div>
                    <h5>WazirX</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="">
                      <img
                        style={{ width: "80px" }}
                        src="assets/img/exchange/wle/paxful.svg"
                        alt="paxful"
                      />
                    </div>
                    <h5>Paxful</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
