import React from "react";

export default function WhiteLabelE() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_50">
            <h2 className="text-center">
              Decentralized Exchange Development by Xeer Technology
            </h2>
            <p className="text-center pt-3">
              As a blockchain development company, Xeer Technology provides
              end-to-end solutions from concept to deployment, ensuring
              compliance, security, and scalability for the next generation of
              decentralized trading platforms.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Custom DEX Development</h4>
                      <p className="pt-2">
                        Tailored platforms supporting various assets and trading
                        pairs.
                      </p>
                    </li>
                    <li>
                      <h5>Smart Contract Integration</h5>
                      <p className="pt-2">
                        {" "}
                        Automated and secure transaction processing.
                      </p>
                    </li>
                    <li>
                      <h5>Liquidity Pool Setup</h5>
                      <p className="pt-2">
                        Enabling decentralized liquidity for efficient trading.
                      </p>
                    </li>
                    <li>
                      <h5>Cross-Chain Compatibility</h5>
                      <p className="pt-2">
                        Allowing seamless asset trading across different
                        blockchain networks.
                      </p>
                    </li>
                    <li>
                      <h5>Governance & Staking Mechanisms</h5>
                      <p className="pt-2">
                        Adding features like decentralized governance and
                        staking to enhance user engagement.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  src="assets/img/decentralized_exchange_development_by_xeer_technology(1).svg"
                  alt="decentralized_exchange_development_by_xeer_technology(1)"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
