import React from "react";

export default function CoinMarketCap() {
  return (
    <>
      <section className="feature-section py_100">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="image-box ">
                  <div>
                    <img
                      src="assets/img/listing/coinmarkectcap-coingecko-listing-services-with-xeer-technology.png"
                      alt="coinmarkectcap-coingecko-listing-services-with-xeer-technology"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>
                    CoinMarketCap & CoinGecko Listing Services with Xeer
                    Technology
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    Visibility on platforms like CoinMarketCap and CoinGecko is
                    vital for establishing a strong presence in the crypto
                    space. At Xeer Technology, we simplify the listing process,
                    helping your token achieve a reputable position on these
                    leading tracking platforms, enhancing market trust and
                    accessibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
