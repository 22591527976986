import React from "react";
import Header from "../Coman/Header";
import LookingD from "../About-Us/LookingD";
import Footar from "../Coman/Footar";
import CryptoGamingAppHero from "./CryptoGamingAppHero";
import Resource from "./Resource";
import Finance from "./Finance";
import ProjectSerum from "./ProjectSerum";
import Corporates from "./Corporates";
import TakeYourGame from "./TakeYourGame";
import CentricDesign from "./CentricDesign";
import { Helmet } from "react-helmet";

export default function CryptoGamingApp() {
  return (
    <>
      <Helmet>
        <title>Crypto Gaming App Development | Xeer Technology</title>
        <meta
          name="description"
          content="Transform your gaming vision with Xeer Technology's advanced crypto gaming app development services. Build immersive, blockchain-powered gaming apps with seamless token integration and enhanced security. Explore innovative solutions for the future of gaming."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoGamingAppHero />
        <Finance />
        <Resource />
        <Corporates />
        <ProjectSerum />
        <TakeYourGame />
        <CentricDesign />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
