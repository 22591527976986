import React from "react";

export default function Hero() {
  return (
    <>
      <section
        className="feature-section p_50 "
        aria-label="Hero31"
        style={{
          backgroundImage: "url(assets/img/Hero31.png)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="content-box ">
                <h4 className="text-white ">Hire Us For</h4>
                <h1 style={{ fontSize: "50px" }} className="text-white ">
                  Decentralized Applications & Blockchain Development
                </h1>
                <div className=" d-flex justify-content text-center py-4">
                  <div className="box">
                    <div className="content">
                      <div className="content__box">
                        <ul className="content__box__list">
                          <li className="content__box__list__item">
                            Blockchain
                          </li>
                          <li className="content__box__list__item">
                            Smart Contract
                          </li>
                          <li className="content__box__list__item">Exchange</li>
                          <li className="content__box__list__item">dApps</li>
                          {/* <li className="content__box__list__item">Coin-Token</li>
                          <li className="content__box__list__item">defi</li>
                          <li className="content__box__list__item">Wallet</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-white">
                  We design & develop blockchain applications ranging from a
                  <br />
                  simple smart contract to a whole new customized blockchain
                  network!
                </p>
                <div className="btn-box pt-5">
                  <a href="#contact-us" className="theme-btn btn-one mr_20">
                    Talk To Our Team
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="vert-move">
                <img
                  src="assets/img/hero_section_blockchain.png"
                  alt="hero_section_blockchain"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
