import React from "react";

export default function HighLiquidity() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Stunning Features & Functionalities offered by Xeer Technology’s
              DEX Aggregator Development Service
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/dex/SVG/Seamless_ui_ux.svg"
                      alt="Seamless_ui_ux"
                    />
                  </div>
                  <h3>Customizable UI/UX</h3>
                  <p>
                    Tailor-made interfaces designed for a smooth trading
                    experience, customizable to suit your brand’s identity and
                    enhance user engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/dex/SVG/real_time_monitoring.svg"
                      alt="real_time_monitoring"
                    />
                  </div>
                  <h3>Real-Time Data Feeds</h3>
                  <p>
                    Integrated price feeds and liquidity updates ensure that
                    users receive accurate, up-to-the-moment data on all trades
                    and liquidity sources.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/dex/SVG/smart_contract_framework.svg"
                      alt="smart_contract_framework"
                    />
                  </div>
                  <h3>Secure Smart Contract Architecture</h3>
                  <p>
                    Our DEX aggregators are backed by robust, audited smart
                    contracts, ensuring secure transactions and safeguarding
                    user funds throughout the trading process.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/dex/SVG/multi_chain.svg"
                      alt="multi_chain"
                    />
                  </div>
                  <h3>Multi-Chain Asset Swapping</h3>
                  <p>
                    Enable users to seamlessly swap assets across chains with
                    our multi-chain integration, enhancing flexibility and
                    trading opportunities within your aggregator.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/dex/SVG/analytics.svg"
                      alt="analytics"
                    />
                  </div>
                  <h3>Analytics and Performance Tracking</h3>
                  <p>
                    Detailed analytics features allow users to monitor
                    transaction history, trading volume, and slippage, providing
                    insight into trading performance and trends.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
