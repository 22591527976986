import React from "react";

export default function MarketingGlance() {
  return (
    <>
      <section className="feature-section py_50" id="fund-raising">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">Fund Raising</h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h1>
                    Comprehensive Fundraising Solutions for Blockchain Projects
                  </h1>
                  <p className="py-3">
                    From investor outreach to campaign management, Xeer
                    Technology provides end-to-end support to secure necessary
                    funding. Our team ensures your project gains the financial
                    backing it needs to succeed.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Investor Targeting</h4>
                      <p>
                        Identify and approach relevant investors for your
                        project.
                      </p>
                    </li>
                    <li>
                      <h4>Fundraising Strategy</h4>
                      <p>Tailored strategies to optimize funding potential.</p>
                    </li>
                    <li>
                      <h4>Campaign Execution</h4>
                      <p>
                        Manage outreach and communications for a streamlined
                        fundraising process.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Begin Your Fundraising Journey
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <div className="image-box">
                    <figure className="image">
                      <img
                        src="assets/img/marketing/Crypto-Token-Marketing-Section-Image-01.png"
                        alt="Crypto-Token-Marketing-Section-Image-01"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
