import React from "react";

export default function WebGame() {
  return (
    <>
      <section className="feature-section pb_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="">
                  <div className="image-inner">
                    <img
                      src="assets/img/web3/web3-game-development-services-by-xeer-technology.png"
                      alt="web3-game-development-services-by-xeer-technology"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Web3 Game Development Services by Xeer Technology</h2>
                </div>
                <div className="text-box">
                  <p>
                    Unlock the next level of gaming with Xeer Technology’s
                    comprehensive Web3 game development services. We empower
                    developers and creators to bring their gaming visions to
                    life, incorporating blockchain technology for decentralized
                    ownership, play-to-earn models, and immersive, player-driven
                    experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
