import React from "react";

export default function ApplicationsonPolygon() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_25">
            <h3>
              Why Polygon zkEVM with
              <span className="xeer-color"> Xeer Technology?</span>
            </h3>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "220px" }}>
                        <div className="d-flex align-items-center">
                          <div className="pb-2">
                            <img
                              style={{ width: "50px" }}
                              src="assets/img/framework/SVG/ethereum.svg"
                              alt="ethereum"
                            />
                          </div>
                          <h5 className="ms-3">Ethereum Compatibility</h5>
                        </div>
                        <p>
                          zkEVM maintains Ethereum’s structure, allowing
                          seamless migration of DApps and smart contracts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "220px" }}>
                        <div className="d-flex align-items-center">
                          <div className="pb-2">
                            <img
                              style={{ width: "50px" }}
                              src="assets/img/blockchain-dev/SVG/enhanced_security.svg"
                              alt="enhanced_security"
                            />
                          </div>
                          <h5 className="ms-3">Enhanced Security</h5>
                        </div>
                        <p>
                          Zero-knowledge rollups provide robust security,
                          preserving data privacy and protecting user assets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "220px" }}>
                        <div className="d-flex align-items-center">
                          <div className="pb-2">
                            <img
                              style={{ width: "50px" }}
                              src="assets/img/exchange/mms/efficient_price_discovery.svg"
                              alt="efficient_price_discovery"
                            />
                          </div>
                          <h5 className="ms-3">Cost Efficiency</h5>
                        </div>
                        <p>
                          Lower gas fees and faster transactions make Polygon
                          zkEVM ideal for large-scale applications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "220px" }}>
                        <div className="d-flex align-items-center">
                          <div className="pb-2">
                            <img
                              style={{ width: "50px" }}
                              src="assets/img/blockchain-dev/SVG/scalability.svg"
                              alt="scalability"
                            />
                          </div>
                          <h5 className="ms-3">Sustainable Scaling</h5>
                        </div>
                        <p>
                          Polygon zkEVM is designed to meet the demands of
                          growing blockchain networks without compromising on
                          decentralization.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="assets/img/framework/Why-Polygon-EVM-with-Xeer-Technology-Section-Image.png"
                  alt="Why-Polygon-EVM-with-Xeer-Technology-Section-Image"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
