import React from "react";

export default function UnlockYourProject() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">
              Unlock Your Project’s Potential with Xeer Technology’s ICO
              Services
            </h1>
            <p className="text-white py-3">
              Position your ICO for success with our tailored solutions. Connect
              with Xeer Technology today to discuss how we can support your <br />
              token sale and help you achieve your fundraising goals!
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
