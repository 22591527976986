import React from "react";
import Header from "../Coman/Header";
import Web3GameDevlopmentHero from "./Web3GameDevlopmentHero";
import Footar from "../Coman/Footar";
import DailyWith from "./DailyWith";
import WebGame from "./WebGame";
import WebGameDevS from "./WebGameDevS";
import WhyChooseWebdevGame from "./WhyChooseWebdevGame";
import LookingD from "../About-Us/LookingD";
import Partner from "./Partner";
import { Helmet } from "react-helmet";

export default function Web3GameDevlopment() {
  return (
    <>
      <Helmet>
        <title>Web3 Game Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Revolutionize gaming with Web3 technologies. Xeer Technology develops immersive blockchain-based games with NFT and metaverse integration."
        />
      </Helmet>
      <div>
        <Header />
        <Web3GameDevlopmentHero />
        <DailyWith />
        <WebGame />
        <WebGameDevS />
        <Partner />
        <WhyChooseWebdevGame />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
