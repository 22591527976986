import React from "react";

export default function PRandMedia() {
  return (
    <>
      <section
        className="feature-section py_80"
        id="pr-and-media"
        style={{
          backgroundImage: "url(assets/img/010.png",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
        }}
      >
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25">
            <h2 className="text-center">PR & Media</h2>
          </div>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/pr_media.png"
                      alt="pr_media"
                      className="img-fluid"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <p className="py-3">
                    <h2>
                      Boost Visibility with High-Impact PR and Media Outreach
                    </h2>
                    Through our extensive media network, we position your
                    project in influential crypto publications, enhancing
                    credibility and driving traffic. Our PR specialists craft
                    compelling narratives that resonate with crypto communities,
                    journalists, and investors alike.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Media Coverage</h4>
                      <p>
                        Gain exposure through articles, interviews, and press
                        releases in top crypto outlets.
                      </p>
                    </li>
                    <li>
                      <h4>Crisis Management</h4>
                      <p>
                        Manage your brand’s reputation effectively in all
                        scenarios.
                      </p>
                    </li>
                    <li>
                      <h4>Press Kit Development</h4>
                      <p>
                        Prepare professional press kits to engage media partners
                        seamlessly.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Discover Our PR & Media Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
