import React from "react";
import Header from "../Coman/Header";
import ArbitrageTradingBotsHero from "./ArbitrageTradingBotsHero";
import Footar from "../Coman/Footar";
import CryptoABot from "./CryptoABot";
import Harness from "./Harness";
import StatisticsDYK from "./StatisticsDYK";
import Stunning from "./Stunning";
import CryArbitrageTrad from "./CryArbitrageTrad";
import ProjectwithUs from "./ProjectwithUs";
import ArbitrageTBots from "./ArbitrageTBots";
import SkilledDevelopers from "./SkilledDevelopers";
import MultiExchange from "./MultiExchange";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function ArbitrageTradingBots() {
  return (
    <>
      <Helmet>
        <title>Arbitrage Trading Bot Development | Xeer Technology</title>
        <meta
          name="description"
          content="Maximize profits with Xeer's arbitrage trading bots. Advanced automation for seamless and efficient cryptocurrency trading."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <ArbitrageTradingBotsHero />
        <CryptoABot />
        <StatisticsDYK />
        <Harness />
        <ProjectwithUs />
        <Stunning />
        {/* add */}
        <MultiExchange />
        <ArbitrageTBots />
        <CryArbitrageTrad />
        <SkilledDevelopers />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
