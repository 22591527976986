import React from "react";
import Header from "../Coman/Header";
import NFTHero from "./NFTHero";
import Footar from "../Coman/Footar";
import Popularity from "./Popularity";
import Dedicated from "./Dedicated";
import Ensuring from "./Ensuring";
import LeadingBlockNET from "./LeadingBlockNET";
import InitialMeet from "./InitialMeet";
import Arts from "./Arts";
import LookingD from "../About-Us/LookingD";
import WhyChoNFTDev from "./WhyChoNFTDev";
import { Helmet } from "react-helmet";

export default function NFT() {
  return (
    <>
      <Helmet>
        <title>NFT Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Create unique, scalable, and secure NFT solutions with Xeer Technology. From token creation to marketplace integration, we’ve got you covered."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <NFTHero />
        <Popularity />
        <Dedicated />
        <Ensuring />
        {/* <LeadingBlockNET /> */}
        <InitialMeet />
        <Arts />
        <WhyChoNFTDev />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
