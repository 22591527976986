import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CryptoMarketingHero from "./CryptoMarketingHero";
import MarketingGlance from "./MarketingGlance";
import ATaGLANCE from "./ATaGLANCE";
import TokenMarketing from "./TokenMarketing";
import PRandMedia from "./PRandMedia";
import MarketingStrategies from "./MarketingStrategies";
import WebMarketing from "./WebMarketing";
import WebCampaigns from "./WebCampaigns";
import SocialMediaMarketing from "./SocialMediaMarketing";
import InfluenceMarketing from "./InfluenceMarketing";
import ContentMarketing from "./ContentMarketing";
import BuildingAndManagement from "./BuildingAndManagement";
import AirdropsMarketing from "./AirdropsMarketing";
import RebrandingM from "./RebrandingM";
import PitchDeckDesign from "./PitchDeckDesign";
import { Helmet } from "react-helmet";

export default function CryptoMarketing() {
  return (
    <>
      <Helmet>
        <title>Blockchain Marketing Services | Xeer Technology</title>
        <meta
          name="description"
          content="Drive engagement with Xeer’s blockchain marketing services. From social media campaigns to community building, we amplify your project’s reach globally."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoMarketingHero />
        {/* <ATaGLANCE/> */}
        <TokenMarketing />
        <PRandMedia />
        <MarketingStrategies />
        <WebMarketing />
        <WebCampaigns />
        <SocialMediaMarketing />
        <InfluenceMarketing />
        <ContentMarketing />
        <BuildingAndManagement />
        <AirdropsMarketing />
        <RebrandingM />
        <PitchDeckDesign />
        <MarketingGlance />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
