import React from "react";

export default function SolanaServicesPrice() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Solana for Your Blockchain Project?</h2>
              <p>
                Solana has emerged as one of the most efficient and scalable
                blockchain platforms, ideal for DeFi applications, NFT
                marketplaces, and gaming projects. Solana’s unique Proof of
                History (PoH) mechanism enables it to handle thousands of
                transactions per second at minimal costs, making it an optimal
                choice for businesses looking to scale.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
