import React from "react";

export default function CustomLayer2Hero() {
  return (
    <div>
      <section
        className="feature-section py_150"
        aria-label="cardano-blockchain-for-your-business-hero-section"
        style={{
          backgroundImage:
            "url(assets/img/layer/cardano-blockchain-for-your-business-hero-section.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mb_55">
                <h2 className="text-white">
                  Harness the Power of Cardano Blockchain for Your Business
                </h2>
                <p className="text-white pt-3">
                  Unlock the potential of Cardano with Xeer Technology’s
                  customized blockchain development services, optimized for
                  scalability, sustainability, and security.
                </p>
                <div className="btn-box mt_40">
                  <a href="/contact-us" className="theme-btn btn-one mr_20">
                    Hire Blockchain Expert
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 "></div>
          </div>
        </div>
      </section>
    </div>
  );
}
