import React from "react";
import Header from "../Coman/Header";
import CryptocurrencyWalletHero from "./CryptocurrencyWalletHero";
import Footar from "../Coman/Footar";
import TheGlobal from "./TheGlobal";
import Empowering from "./Empowering";
import Unlocking from "./Unlocking";
import LookingD from "../About-Us/LookingD";
import WhatisCryWallet from "./WhatisCryWallet";
import DedicatedS from "./DedicatedS";
import LaunchYourW from "./LaunchYourW";
import { Helmet } from "react-helmet";

export default function CryptocurrencyWallet() {
  return (
    <>
      <Helmet>
        <title>
          Secure Cryptocurrency Wallet Development | Xeer Technology
        </title>
        <meta
          name="description"
          content="Develop multi-currency cryptocurrency wallets with Xeer Technology. Advanced security, seamless transactions, and user-friendly design."
        />
      </Helmet>
      <div>
        <Header />
        <CryptocurrencyWalletHero />
        <TheGlobal />
        <WhatisCryWallet />
        <Empowering />
        <Unlocking />
        <LaunchYourW />
        <DedicatedS />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
