import React from "react";
import Header from "../Coman/Header";
import Web3WalletHero from "./Web3WalletHero";
import Footar from "../Coman/Footar";
import BeAPart from "./BeAPart";
import Remunerative from "./Remunerative";
import OurWorldClass from "./OurWorldClass";
import LookingD from "../About-Us/LookingD";
import WhatisWeb3Wallet from "./WhatisWeb3Wallet";
import UnleashNew from "./UnleashNew";
import WhyChoosWeb3 from "./WhyChoosWeb3";
import { Helmet } from "react-helmet";

export default function Web3Wallet() {
  return (
    <>
      <Helmet>
        <title>Web3 Wallet Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Empower decentralized experiences with Xeer’s Web3 wallet development. Seamless integration for DApps and blockchain interactions."
        />
      </Helmet>
      <div>
        <Header />
        <Web3WalletHero />
        <BeAPart />
        <WhatisWeb3Wallet />
        <Remunerative />
        <UnleashNew />
        <OurWorldClass />
        <WhyChoosWeb3 />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
