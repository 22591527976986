import React from "react";

export default function BuildPolygon() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  src="assets/img/framework/Polygon-EVM-The-Future-of-Ethereum-Scalability-sECTION-iMAGE.png"
                  alt="Polygon-EVM-The-Future-of-Ethereum-Scalability-sECTION-iMAGE"
                  // style={{ width: "400px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <div className="sec-title">
                  <h2>Polygon zkEVM: The Future of Ethereum Scalability</h2>
                </div>
                <p className="pt-3">
                  Polygon zkEVM offers Ethereum-compatible scalability solutions
                  through zero-knowledge rollups, optimizing transaction speed,
                  cost, and efficiency. This layer-2 solution empowers projects
                  to scale without compromising on security or decentralization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
