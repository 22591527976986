import React from "react";

export default function TezosLeads() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Tezos Powers a User-Centric Web3</h2>
              <p>
                Tezos Blockchain Development for the Web3 Revolution The future
                of the internet is here with Tezos a truly decentralized and
                user-driven network. Tezos allows users to exchange value and
                interact directly on a secure, intermediary-free platform,
                embodying the core values of a user-governed Web3 internet.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
