import React from "react";
import Header from "../Coman/Header";
import MultiCurrencyWalletHero from "./MultiCurrencyWalletHero";
import Footar from "../Coman/Footar";
import Exquisite from "./Exquisite";
import ReachXeer from "./ReachXeer";
import WhyChosseUsM from "./WhyChosseUsM";
import LookingD from "../About-Us/LookingD";
import UseExisting from "./UseExisting";
import Logos from "./Logos";
import { Helmet } from "react-helmet";

export default function MultiCurrencyWallet() {
  return (
    <>
      <Helmet>
        <title>Multi-Currency Wallet Development | Xeer Technology</title>
        <meta
          name="description"
          content="Manage multiple cryptocurrencies in one wallet. Xeer offers secure and customizable multi-currency wallet development."
        />
      </Helmet>
      <div>
        <Header />
        <MultiCurrencyWalletHero />
        <UseExisting />
        <Logos />
        <Exquisite />
        <ReachXeer />
        <WhyChosseUsM />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
