import React from "react";
import Header from "../Coman/Header";
import AMMDexHero from "./AMMDexHero";
import Footar from "../Coman/Footar";
import Advantageous from "./Advantageous";
import Providers from "./Providers";
import AMMSolution from "./AMMSolution";
import AmmDEXDev from "./AmmDEXDev";
import WhyChooAMM from "./WhyChooAMM";
import LookingD from "../About-Us/LookingD";
import WhatIsAmmDEX from "./WhatIsAmmDEX";
import { Helmet } from "react-helmet";

export default function AMMDex() {
  return (
    <>
      <Helmet>
        <title>
          Automated Market Maker (AMM) DEX Development | Xeer Technology
        </title>
        <meta
          name="description"
          content="Empower your trading platform with AMM technology. Xeer develops robust DEX platforms with automated liquidity and user-friendly interfaces."
        />
      </Helmet>
      <div>
        <Header />
        <AMMDexHero />
        <WhatIsAmmDEX />
        <AMMSolution />
        <Advantageous />
        <AmmDEXDev />
        <Providers />
        <WhyChooAMM />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
