import React from "react";

export default function Essential() {
  return (
    <>
      <section className="about-style-three py_50">
        <div className="auto-container">
          <div className="text-center pb-5">
            <h1>Applications of Crypto Banking: What Can Be Built?</h1>
          </div>
          <div className="content_block_four">
            <div className="content-box ml_40">
              <div className="inner-box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-item my_10">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/crypto_powered_payment_gateways.svg"
                          alt="crypto_powered_payment_gateways"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Crypto-Powered Payment Gateways</h3>
                      <p>
                        Enable seamless payments in crypto for online and
                        in-store purchases.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/crypto_backed_mortgage_and_loan_services.svg"
                          alt="crypto_backed_mortgage_and_loan_services"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Crypto-Backed Mortgage and Loan Services</h3>
                      <p>
                        Allow users to secure loans for real estate or personal
                        needs using crypto assets as collateral.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/decentralized_savings_accounts.svg"
                          alt="decentralized_savings_accounts"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Decentralized Savings Accounts</h3>
                      <p>
                        Provide interest-bearing savings accounts where users
                        earn passive income on held crypto.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/automated_tax_and_compliance_tools.svg"
                          alt="automated_tax_and_compliance_tools"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Automated Tax and Compliance Tools</h3>
                      <p>
                        Simplify tax reporting for crypto transactions and
                        ensure compliance with regional regulations.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/multi_currency_crypto_wallets.svg"
                          alt="multi_currency_crypto_wallets"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>
                        Multi-Currency Crypto Wallets with Fiat Integration
                      </h3>
                      <p>
                        Offer wallets that support multiple cryptocurrencies
                        along with easy conversion to and from fiat currencies.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/cross_border.svg"
                          alt="cross_border"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Cross-Border Remittance Platforms</h3>
                      <p>
                        Facilitate low-cost, instant international money
                        transfers using blockchain technology.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/p_to_p.svg"
                          alt="p_to_p"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>P2P Lending and Borrowing Platforms</h3>
                      <p>
                        Enable users to lend and borrow directly from each other
                        in a decentralized manner.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/tokenized_asset_portfolios.svg"
                          alt="tokenized_asset_portfolios"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Tokenized Asset Portfolios</h3>
                      <p>
                        Allow users to diversify their investments by accessing
                        tokenized stocks, commodities, and real estate within
                        the same platform.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item">
                      <div className="icon-box">
                        <img
                          src="assets/img/crypto-g-dev/decentralized_identity_verification.svg"
                          alt="decentralized_identity_verification"
                          height={40}
                          width={40}
                        />
                      </div>
                      <h3>Decentralized Identity Verification (KYC)</h3>
                      <p>
                        Implement blockchain-based identity verification for
                        secure and efficient KYC processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
