import React from "react";
import Header from "../Coman/Header";
import WhiteLabelWalletHero from "./WhiteLabelWalletHero";
import Footar from "../Coman/Footar";
import Navigating from "./Navigating";
import Bold from "./Bold";
import Powerful from "./Powerful";
import GrowYourB from "./GrowYourB";
import Exceptional from "./Exceptional";
import LookingD from "../About-Us/LookingD";
import Dedicated from "./Dedicated";
import { Helmet } from "react-helmet";

export default function WhiteLabelWallet() {
  return (
    <>
      <Helmet>
        <title>White Label Wallet Solutions | Xeer Technology</title>
        <meta
          name="description"
          content="Launch your crypto wallet instantly with Xeer’s customizable white-label solutions. Secure and scalable technology tailored to your needs."
        />
      </Helmet>
      <div>
        <Header />
        <WhiteLabelWalletHero />
        <Navigating />
        <Powerful />
        <GrowYourB />
        <Bold />
        <Exceptional />
        <Dedicated />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
