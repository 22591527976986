import React from "react";

export default function Ensuring() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Unleash new spectrum within your real-world asset ecosystem with
              our NFT development services
            </h2>
            <p className="pt-2">
              We empower businesses and individuals to tokenize tangible
              assets—such as real estate, art, luxury goods, and more—into
              secure, tradable NFTs.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/custom_nft_designs.svg"
                      alt="custom_nft_designs"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Custom NFT Designs</h3>
                  <p>
                    Create unique and secure NFTs tailored to your project,
                    whether it’s for art, gaming, real estate, or other sectors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/nft_contract_development.svg"
                      alt="nft_contract_development"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>NFT Contract Development</h3>
                  <p>
                    We build robust, secure, and tamper-proof smart contracts to
                    power your NFTs on blockchain platforms like Ethereum,
                    Binance Smart Chain, Solana, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/marketplace_integration.svg"
                      alt="marketplace_integration"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Marketplace Integration</h3>
                  <p>
                    Seamless integration with popular NFT marketplaces, enabling
                    you to list, trade, and auction your NFTs globally.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/token_standards_compliance.svg"
                      alt="token_standards_compliance"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Token Standards Compliance</h3>
                  <p>
                    Support for leading token standards such as ERC-721,
                    ERC-1155, and others, ensuring interoperability and
                    scalability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/nft_wallet_development.svg"
                      alt="nft_wallet_development"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>NFT Wallet Development</h3>
                  <p>
                    We also offer NFT Wallet Development to help your users
                    securely store, manage, and trade NFTs with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/cross_chain_nft_solutions.svg"
                      alt="cross_chain_nft_solutions"
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Cross-Chain NFT Solutions</h3>
                  <p>
                    Enhance the reach of your NFTs with cross-chain
                    compatibility, allowing users to trade NFTs across multiple
                    blockchains.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
