import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CMCListingHero from "./CMCListingHero";
import CoinMarketCap from "./CoinMarketCap";
import CompleteListing from "./CompleteListing";
import { Helmet } from "react-helmet";

export default function CMCListing() {
  return (
    <>
      <Helmet>
        <title>CoinGecko & CMC Listing Services | Xeer Technology</title>
        <meta
          name="description"
          content=" Boost your crypto project’s visibility with Xeer’s CoinGecko and CoinMarketCap listing services. Fast, reliable, and compliant listings."
        />
      </Helmet>
      <div>
        <Header />
        <CMCListingHero />
        <CoinMarketCap />
        <CompleteListing />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
