import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function P2PExchangeHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="P2P-Crypto-Exchange-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/P2P-Crypto-Exchange-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="">
                <h1 className="text-white pb-3">
                  P2P Crypto Exchange Development
                </h1>
                <p className="text-white">
                  Get your peer-to-peer (P2P) crypto exchange developed <br />
                  by our experienced team of developers.
                </p>
              </div>
              <div className="btn-box pt-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
