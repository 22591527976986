import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DefiYienldFarmingHero() {
  return (
    <>
      <section
        className="faq-style-three py_150"
        aria-label="DeFi-Yield-Farming-Platform-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/defi/DeFi-Yield-Farming-Platform-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row clearfix pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mb_55">
                <h2 className="text-white">
                  DeFi Yield Farming Platform Development
                </h2>
                <p className="text-white py-3">
                  At Xeer Technology, we specialize in developing seamless DeFi
                  yield farming platforms that empower users to maximize their
                  returns through innovative farming strategies. Our expertise
                  includes building robust, secure platforms where users can
                  provide liquidity, stake assets, and earn passive rewards in
                  decentralized ecosystems.
                </p>
              </div>

              <div className="btn-box">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
