import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import P2PExchangeHero from "./P2PExchangeHero";
import P2PCryptocurrency from "./P2PCryptocurrency";
import Features from "./Features";
import HowP2PWork from "./HowP2PWork";
import BenefitsP2P from "./BenefitsP2P";
import BenefitsOfOur from "./BenefitsOfOur";
import LookingD from "../About-Us/LookingD";
import WhyPtowP from "./WhyPtowP";
import { Helmet } from "react-helmet";

export default function P2PExchange() {
  return (
    <>
      <Helmet>
        <title>P2P Exchange Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Create a secure P2P exchange platform with advanced features. Empower users with decentralized trading solutions built by Xeer."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <P2PExchangeHero />
        <WhyPtowP />
        <P2PCryptocurrency />
        <Features />
        <HowP2PWork />
        <BenefitsP2P />
        <BenefitsOfOur />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
