import React from "react";

export default function Attributes() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our Crypto Wallet Mobile App</h2>
            <p>
              We create tailored mobile apps to ensure seamless management of
              digital assets, with top-tier security and an intuitive user
              experience.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/multi_currency_support.svg"
                      alt="multi_currency_support"
                    />
                  </div>
                  <h3>Multi-Currency Support</h3>
                  <p>
                    Our mobile wallet apps support multiple cryptocurrencies,
                    including Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB),
                    and a variety of altcoins and tokens. Users can store, send,
                    and receive digital assets all in one place.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/non_custodial_security.svg"
                      alt="non_custodial_security"
                    />
                  </div>
                  <h3>Non-Custodial Security</h3>
                  <p>
                    We provide non-custodial wallets, where users have full
                    control over their private keys. Security is ensured with
                    encryption, private key storage, and backup & recovery
                    options, offering complete safety for users' assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/cross_platform_compatibility.svg"
                      alt="cross_platform_compatibility"
                    />
                  </div>
                  <h3>Cross-Platform Compatibility</h3>
                  <p>
                    We develop mobile wallet apps for both iOS and Android
                    platforms, ensuring smooth performance and cross-platform
                    compatibility for all users.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/biometric_authentication.svg"
                      alt="biometric_authentication"
                    />
                  </div>
                  <h3>Biometric Authentication & 2FA</h3>
                  <p>
                    Our wallets feature biometric authentication (fingerprint or
                    facial recognition) and
                    <span>two-factor authentication (2FA)</span> for an added
                    layer of security, preventing unauthorized access.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/real_time_price_tracking.svg"
                      alt="real_time_price_tracking"
                    />
                  </div>
                  <h3>Real-Time Price Tracking & Alerts</h3>
                  <p>
                    Users can monitor real-time price changes and set alerts for
                    specific price movements of their favorite cryptocurrencies.
                    This feature helps users stay informed and make timely
                    decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "324px" }}>
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/qr_code_payments.svg"
                      alt="qr_code_payments"
                    />
                  </div>
                  <h3>QR Code Payments</h3>
                  <p>
                    Our mobile apps integrate QR code scanning for quick and
                    easy crypto payments, simplifying the process of sending and
                    receiving funds.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "410px" }}>
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/integration_with_decentralized_finance.svg"
                      alt=""
                    />
                  </div>
                  <h3>Integration with Decentralized Finance (DeFi)</h3>
                  <p>
                    For users engaging in DeFi activities, we provide wallet
                    integration with DeFi protocols, enabling features like
                    staking, yield farming, and DApp connectivity directly
                    within the mobile app.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "410px" }}>
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/transaction_history.svg"
                      alt=""
                    />
                  </div>
                  <h3>Transaction History & Management</h3>
                  <p>
                    Users can easily track their transaction history, with
                    detailed breakdowns of each transaction. This ensures
                    transparency and helps users manage their finances more
                    effectively.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "410px" }}>
                  <div className="icon-box">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/wallet/mwa/ui_ux.svg"
                      alt=""
                    />
                  </div>
                  <h3>Customizable User Interface (UI/UX)</h3>
                  <p>
                    We design wallets with a customizable and intuitive UI/UX
                    that ensures smooth navigation. Our designs are responsive
                    and provide a seamless experience for both novice and
                    advanced crypto users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
