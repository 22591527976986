import React from "react";

export default function SolanaPerfect() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Get Started with Solana Development</h2>
            <p className="text-white py-4">
              Unlock Solana's potential with Xeer Technology. Reach out to
              discuss your project needs, and let our team guide you in building
              high-performance, <br /> scalable solutions on Solana.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
