import React from "react";
import Header from "../Componet/Coman/Header";
import Footar from "../Componet/Coman/Footar";
import SecurityExchangeHero from "./SecurityExchangeHero";
import SecurityToken from "./SecurityToken";
import SecurityDevelopment from "./SecurityDevelopment";
import STOExchange from "./STOExchange";
import WhatisSTE from "./WhatisSTE";
import LookingD from "../Componet/About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function SecurityExchange() {
  return (
    <>
      <Helmet>
        <title>Security Token Exchange Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build a robust and compliant security exchange platform with Xeer Technology. Enhance trust with secure and transparent token trading."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <SecurityExchangeHero />
        <WhatisSTE />
        <SecurityToken />
        <SecurityDevelopment />
        <STOExchange />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
