import React from "react";
import Header from "../Coman/Header";
import NFTMarketplaceHero from "./NFTMarketplaceHero";
import Footar from "../Coman/Footar";
import FullNFTMarketplace from "./FullNFTMarketplace";
import Platform from "./Platform";
import NFTServices from "./NFTServices";
import ScratchNFT from "./ScratchNFT";
import LookingD from "../About-Us/LookingD";
import BuildingNFT from "./BuildingNFT";
import { Helmet } from "react-helmet";

export default function NFTMarketplace() {
  return (
    <>
      <Helmet>
        <title>NFT Marketplace Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build a robust NFT marketplace with Xeer Technology. Advanced features, multi-chain support, and secure transactions guaranteed."
        />
      </Helmet>
      <div>
        <Header />
        <NFTMarketplaceHero />
        <FullNFTMarketplace />
        <Platform />
        <NFTServices />
        <ScratchNFT />
        <BuildingNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
