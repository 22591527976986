import React from "react";
import Header from "../Coman/Header";
import DecentralizedExchangeHero from "./DecentralizedExchangeHero";
import Footar from "../Coman/Footar";
import CryptoNew from "./CryptoNew";
import CentralizedS from "./CentralizedS";
import OurCentralized from "./OurCentralized";
import DerivativesTrading from "./DerivativesTrading";
import Platform from "./Platform";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function DecentralizedExchange() {
  return (
    <>
      <Helmet>
        <title>Derivatives Exchange Development | Xeer Technology</title>
        <meta
          name="description"
          content="Launch your derivatives exchange platform with Xeer. Supporting futures, options, and perpetual trading with market-leading technology."
        />
      </Helmet>
      <div class="boxed_wrapper ltr" style={{ overflow: "hidden" }}>
        <Header />
        <DecentralizedExchangeHero />
        <Platform />
        <CryptoNew />
        <CentralizedS />
        <OurCentralized />
        <DerivativesTrading />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
