import React from "react";

export default function OurICODev() {
  return (
    <>
      <section className="contact-info-section centred py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Comprehensive ICO Development Solutions</h2>
            <p>
              Unlock the full potential of your ICO with end-to-end solutions
              that ensure security, compliance, & market impact.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/token-creation-smart-contract-development.svg"
                    alt="token-creation-smart-contract-development"
                    height={60}
                    width={60}
                  />
                  <h3>Token Creation & Smart Contract Development</h3>
                  <p>
                    We design and deploy secure, custom tokens aligned with your
                    project’s unique requirements. Whether you’re launching an
                    ERC-20, BEP-20, or a custom protocol, our blockchain
                    developers ensure the technical foundation is robust and
                    secure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/whitepaper-pitch-deck-creation.svg"
                    alt="whitepaper-pitch-deck-creation"
                    height={60}
                    width={60}
                  />
                  <h3>Whitepaper & Pitch Deck Creation</h3>
                  <p>
                    Our team collaborates with you to develop a compelling,
                    investor-ready whitepaper and pitch deck that effectively
                    communicates your project’s vision, market potential, and
                    tokenomics, inspiring confidence among potential investors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/kyc-aml-compliance-integration.svg"
                    alt="kyc-aml-compliance-integration"
                    height={60}
                    width={60}
                  />
                  <h3>KYC/AML Compliance Integration</h3>
                  <p>
                    We prioritize regulatory compliance by integrating KYC (Know
                    Your Customer) and AML (Anti-Money Laundering) processes,
                    safeguarding your ICO against fraudulent activity and
                    ensuring adherence to legal standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/ico-website-and-dashboard-development.svg"
                    alt="ico-website-and-dashboard-development"
                    height={60}
                    width={60}
                  />
                  <h3>ICO Website and Dashboard Development</h3>
                  <p>
                    We create secure, user-friendly ICO platforms and investor
                    dashboards tailored to your brand. With secure payment
                    gateways, multi-currency support, and real-time analytics,
                    our platforms are designed to simplify the investor journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/marketingand-community-management.svg"
                    alt="marketingand-community-management"
                    height={60}
                    width={60}
                  />
                  <h3>Marketing and Community Management</h3>
                  <p>
                    Our marketing specialists develop and execute tailored
                    strategies to boost your ICO's visibility and engagement. We
                    handle social media management, content marketing, and
                    community-building to build a strong investor base and
                    establish your project in the blockchain space.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <img
                    src="assets/img/coin-dev/post-ico-support-and-exchange-listing.svg"
                    alt="post-ico-support-and-exchange-listing"
                    height={60}
                    width={60}
                  />
                  <h3>Post-ICO Support and Exchange Listing</h3>
                  <p>
                    Our support doesn’t end at the ICO stage. We assist with
                    token distribution, listing on top exchanges, and ongoing
                    technical support, ensuring your project’s long-term growth
                    and sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
