import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import CryptoBankingHero from "./CryptoBankingHero";
import Seamless from "./Seamless";
import BankingServices from "./BankingServices";
import MarketExpansion from "./MarketExpansion";
import Essential from "./Essential";
import WhyChooseCryBanking from "./WhyChooseCryBanking";
import LookingD from "../About-Us/LookingD";
import Together from "./Together";
import { Helmet } from "react-helmet";

export default function CryptoBanking() {
  return (
    <>
      <Helmet>
        <title>Crypto Banking Solutions | Xeer Technology</title>
        <meta
          name="description"
          content="Transform traditional banking with blockchain. Xeer provides secure and compliant crypto banking solutions tailored to your needs."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoBankingHero />
        <Seamless />
        <BankingServices />
        <MarketExpansion />
        <Essential />
        <Together />
        <WhyChooseCryBanking />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
