import React from "react";
import Header from "../Coman/Header";
import DerivativesExchangeHero from "./DerivativesExchangeHero";
import Footar from "../Coman/Footar";
import Decentralized from "./Decentralized";
import WhiteLabelE from "./WhiteLabelE";
import DEXExchange from "./DEXExchange";
import BookBasedDEX from "./BookBasedDEX";
import DecentralizedES from "./DecentralizedES";
import WhyChooseXeer from "./WhyChooseXeer";
import WhyDEX from "./WhyDEX";
import CustodialTrading from "./CustodialTrading";
import LowerMaintenance from "./LowerMaintenance";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function DerivativesExchange() {
  return (
    <>
      <Helmet>
        <title>Decentralized Exchange Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build secure and scalable decentralized exchanges with Xeer. Unlock the future of trading with our advanced DEX development services."
        />
      </Helmet>
      <div>
        <Header />
        <DerivativesExchangeHero />
        <WhyDEX />
        <Decentralized />
        <WhiteLabelE />
        <DEXExchange />
        <BookBasedDEX />
        {/* add */}
        <DecentralizedES />
        <CustodialTrading />
        <LowerMaintenance />
        <WhyChooseXeer />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
