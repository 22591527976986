import React from "react";

export default function MarketReady() {
  return (
    <>
      <section className="feature-section pt_120 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Benefits of our White Label Exchange</h2>
                  <p className="pt-3">
                    Our white label crypto exchange offers astonishing
                    advantages that help to launch a crypto exchange in a quick
                    way. Just enter details in the admin panel, set your
                    branding & it's ready to serve millions of users!
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <div className="row">
                      <div className="col">
                        <li>
                          <h4>Quick Deployment </h4>
                          <p>
                            Launching is much faster compared to building it
                            from scratch.
                          </p>
                        </li>
                        <li>
                          <h4>Low Development Costs</h4>
                          <p>
                            Avoiding significant development cost with a
                            ready-made platform.
                          </p>
                        </li>
                        <li>
                          <h4>Pre-Integrated Features</h4>
                          <p>
                            Comes with pre-integrated features that are
                            essential for an exchange.
                          </p>
                        </li>
                        <li>
                          <h4>Compliance Tools</h4>
                          <p>
                            Often includes KYC/AML procedures and regulatory
                            compliance support.
                          </p>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="">
                  <figure className="">
                    <img
                      src="assets/img/Benefits-of-our-White-Label-Exchange2.png"
                      alt="Benefits-of-our-White-Label-Exchange2"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
