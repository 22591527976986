import React from "react";

export default function SmartContracts() {
  return (
    <>
      <section className="feature-section pt_120 pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25">
            <h2 className="text-centar">
              Tezos Blockchain Development Services by Xeer Technology
            </h2>
            <p className="pt-3 text-center">
              Our team of Tezos blockchain experts is here to guide your journey
              in Web3 innovation. Partner with Xeer Technology to create
              scalable, secure, and community-driven applications on Tezos.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <div className="row">
                      <div className="col">
                        <li>
                          <h4>Smart Contracts</h4>
                          <p>for secure and automated interactions</p>
                        </li>
                        <li>
                          <h4>Community-Driven Governance</h4>
                          <p>to enable flexible and sustainable growth</p>
                        </li>
                        <li>
                          <h4>Innovative Design</h4>
                          <p>for future-proof blockchain solutions</p>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="pe-2">
                <img
                  src="assets/img/framework/Tezos-Blockchain-Development-Services-by-Xeer-Technology-Section-Image.png"
                  alt="Tezos-Blockchain-Development-Services-by-Xeer-Technology-Section-Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
