import React from "react";
import Header from "../Coman/Header";
import DefiLotteryHero from "./DefiLotteryHero";
import Footar from "../Coman/Footar";
import NeedforDeFi from "./NeedforDeFi";
import Redefining from "./Redefining";
import LookingD from "../About-Us/LookingD";
import Diligently from "./Diligently";
import WCDeFiLottery from "./WCDeFiLottery";
import PopularofUse from "./PopularofUse";
import { Helmet } from "react-helmet";

export default function DefiLottery() {
  return (
    <>
      <Helmet>
        <title>DeFi Lottery Platform Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build secure and transparent DeFi lottery platforms with Xeer Technology. Enhance user engagement with decentralized lottery solutions."
        />
      </Helmet>
      <div>
        <Header />
        <DefiLotteryHero />
        <NeedforDeFi />
        <Diligently />
        <Redefining />
        <PopularofUse />
        <WCDeFiLottery />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
