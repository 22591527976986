import React from "react";

export default function Delivering() {
  return (
    <>
      <section className="feature-section pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="image-box">
                  <div className="image-inner">
                    <figure className="">
                      <img
                        src="assets/img/exchange/We-Deliver-the-Best-Crypto-Exchange-Development-Services-Section-Image.png"
                        alt="We-Deliver-the-Best-Crypto-Exchange-Development-Services-Section-Image"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>
                    We Deliver the Best Crypto Exchange Development Services
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    From custom UI/UX design to advanced trading features, we
                    deliver comprehensive solutions tailored to the
                    ever-evolving demands of the crypto market, helping you
                    launch your exchange with confidence and ease.
                  </p>
                  <ul className="list-style-one clearfix">
                    <li>6+ Years of Development Experience</li>
                    <li>End-to-End Customization & Integration</li>
                    <li>Multi-Layer Security Features</li>
                    <li>Comprehensive Post-Launch Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
