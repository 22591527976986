import React from "react";

export default function GrowYourB() {
  return (
    <>
      <section className="contact-info-section centred py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of our White Label Crypto Wallet</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/multi_currency_support.svg"
                      alt="multi_currency_support"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">Multi-Currency Support</h3>
                  <p>
                    Manage a variety of cryptocurrencies like Bitcoin, Ethereum,
                    and stablecoins (USDT, USDC) all in one place.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/custom_branding.svg"
                      alt="custom_branding"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">Custom Branding</h3>
                  <p>
                    Tailor the wallet’s appearance to reflect your brand
                    identity with your logo, color schemes, and design elements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/cross_device.svg"
                      alt="cross_device"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">Cross-Platform Access</h3>
                  <p>
                    Available on web, Android, and iOS, ensuring your users can
                    access their assets anytime, anywhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/security_enhancements.svg"
                      alt="security_enhancements"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">High-Level Security</h3>
                  <p>
                    Advanced encryption, two-factor authentication (2FA), and
                    cold storage integration to protect your assets and data.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/defi_platforms.svg"
                      alt="defi_platforms"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">Integrated DeFi Services</h3>
                  <p>
                    Enable staking, yield farming, and lending directly from the
                    wallet for more user engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box text-start">
                  <div className="num-box">
                    <img
                      src="assets/img/seamless_dapp_integration.svg"
                      alt="seamless_dapp_integration"
                      style={{ width: "50px" }}
                    />
                  </div>
                  <h3 className="pt-3">Quick Integrations</h3>
                  <p>
                    Add new tokens and blockchain networks quickly by simply
                    adding logos and chain integration data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
