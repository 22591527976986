import React from "react";

export default function LaunchYourW() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Launch Your Wallet on Popular Blockchain Networks</h2>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/coin-dev/ton.svg"
                    alt="ton"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/crypto-g-dev/eth.svg"
                    alt="eth"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/coin-dev/bnb_chain.svg"
                    alt="bnb_chain"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/blockchain-dev/SVG/base.svg"
                    alt="base"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/icon/polygon.svg"
                    alt="polygon"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/icon/solana.svg"
                    alt="solana"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3" style={{ width: "100px" }}>
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/blockchain-dev/SVG/sui.svg"
                    alt="sui"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <a href="/contact-us" className="theme-btn btn-one">
              Get Your DEX Script
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
