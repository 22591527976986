import React from "react";
import HeroAbout from "./HeroAbout";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import OurCompany from "./OurCompany";
import OurValues from "./OurValues";
import OurVision from "./OurVision";
import LookingD from "./LookingD";
import { Helmet } from "react-helmet";

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Xeer Technology | Blockchain Development Innovator</title>
        <meta
          name="description"
          content="Learn about Xeer Technology, a leader in blockchain innovation. Building reliable and scalable blockchain ecosystems for businesses globally."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <HeroAbout />
        <OurCompany />
        <OurVision />
        <OurValues />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
