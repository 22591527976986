import React from "react";

export default function WinWin() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <h1 className="text-center p-3">
              Our Crypto Launchpad Development Process
            </h1>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>01</h1>
                      </div>
                    </div>

                    <h5 className="py-3">Platform Strategy & Planning</h5>
                    <p>
                      Aligning your vision with a tailored development plan to
                      create a high-performing, secure, and compliant launchpad.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>02</h1>
                      </div>
                    </div>
                    <h5 className="py-3">
                      Custom Development & Blockchain Integration
                    </h5>
                    <p>
                      Developing the platform with advanced features and
                      integrating with multiple blockchains to maximize project
                      flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>03</h1>
                      </div>
                    </div>
                    <h5 className="py-3">
                      Security Audits & Quality Assurance
                    </h5>
                    <p>
                      Comprehensive testing and smart contract audits to ensure
                      platform security and smooth operation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>04</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Launch & Continuous Support</h5>
                    <p>
                      Supporting you through the launch and offering ongoing
                      assistance, updates, and enhancements to optimize platform
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
