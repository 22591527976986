import React from "react";

export default function TakeControl() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">Technology Stack</h1>
            <p className="text-white py-4">
              Our solutions leverage Ethereum, Binance Smart Chain, and other
              blockchain technologies to provide a secure, scalable foundation
              for managing crypto assets. With support for multi-wallet
              integration and secure custody options, Xeer Technology offers a
              future-ready asset management solution.
            </p>
          </div>
          <a href="/contact-us">
            Take control of your digital assets with Xeer Technology. Contact us
            today!
          </a>
        </div>
      </section>
    </>
  );
}
