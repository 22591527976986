import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CryptoLaunchpadHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="crypto-launchpad-platform-development"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/coin-dev/crypto-launchpad-platform-development.jpg)",
        }}
      >
        <div className="auto-container py-4">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Crypto Launchpad Platform Development
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology provides robust Crypto Launchpad Platform
                    Development services, empowering businesses and blockchain
                    startups to create powerful, user-friendly launchpads that
                    enable seamless token fundraising, early project exposure,
                    and investor engagement!
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
