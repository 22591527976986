import React from "react";
import Slider from "react-slick";

export default function Multiple() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="contact-info-section centred pt_80 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Blockchain Networks We Work on For Token Development</h2>
            <p className="pt-2">
              Xeer Technology uses & leverages multiple blockchain networks for
              cryptocurrency token development.
            </p>
          </div>
          <div className="slider-container ">
            <Slider {...settings}>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/crypto-g-dev/eth.svg"
                    alt="eth"
                  />
                  <p className="pt-2">Ethereum</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/crypto-g-dev/solana.svg"
                    alt="solana"
                  />
                  <p className="pt-2">Solana</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/coin-dev/bnb_chain.svg"
                    alt="bnb_chain"
                  />
                  <p className="pt-2">BNB Chain</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/coin-dev/ton.svg"
                    alt="ton"
                  />
                  <p className="pt-2">TON</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/coin-dev/avalanche.svg"
                    alt="avalanche"
                  />
                  <p className="pt-2">Avalanche</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/icon/polygon.svg"
                    alt="polygon"
                  />
                  <p className="pt-2">Polygon</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/coin-dev/optimism.svg"
                    alt="optimism"
                  />
                  <p className="pt-2">Optimism</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/icon/tron.svg"
                    alt="tron"
                  />
                  <p className="pt-2">Tron</p>
                </div>
              </div>
              <div className="slider-box">
                <div className="">
                  <img
                    className="rounded mx-auto d-block pt-3"
                    style={{ width: "70px" }}
                    src="assets/img/icon/near.svg"
                    alt="near"
                  />
                  <p className="pt-2">Near</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
