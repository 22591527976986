import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function Web3WalletHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="Web3-Wallet-Development-Hero-Section-Banner-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/wallet/Web3-Wallet-Development-Hero-Section-Banner-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column py-4">
              <div className="content-box ">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Web3 Wallet Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Empowering seamless, secure, and decentralized access to
                    digital assets and dApps, with advanced features for
                    enhanced user control and privacy.
                  </p>
                </div>
                <div className="btn-box">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
