import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import PolygonEdgeHero from "./PolygonEdgeHero";
import PolygonNetworkBlockchain from "./PolygonNetworkBlockchain";
import PolygonEdgefor from "./PolygonEdgefor";
import BuildPolygon from "./BuildPolygon";
import ApplicationsonPolygon from "./ApplicationsonPolygon";

import PolygonLabs from "./PolygonLabs";
import { Helmet } from "react-helmet";

export default function PolygonEdge() {
  return (
    <>
      <Helmet>
        <title>Polygon Edge Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Deploy efficient blockchain networks with Polygon Edge. Xeer offers end-to-end solutions for scalability and cross-chain compatibility."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <PolygonEdgeHero />
        <PolygonNetworkBlockchain />
        <BuildPolygon />
        <PolygonEdgefor />
        <PolygonLabs />
        <ApplicationsonPolygon />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
