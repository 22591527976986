import React from "react";

export default function Render() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Why choose Xeer Technology for DeFi wallet development?</h2>
            <p className="p-3">
              Leverage Xeer Technology’s expertise in building secure, scalable,
              and feature-rich DeFi wallet apps for Uniswap and 1inch. Our
              solutions are designed to provide users with seamless access to
              DeFi protocols while offering the security and flexibility
              required in today’s decentralized financial landscape. Reach out
              to us today to start building your DeFi wallet clone app!
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/se/standard_development_practice.svg"
                      alt="standard_development_practice"
                    />
                  </div>
                  <h3 className="pt-4">Fast Development & Deployment</h3>
                  <p>
                    We offer quick and efficient development of DeFi wallet
                    clone apps, enabling you to launch your platform without
                    delay.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/scalability.svg"
                      alt="scalability"
                    />
                  </div>
                  <h3 className="pt-4">Highly Scalable Solutions</h3>
                  <p>
                    Our wallet clone apps are designed to scale with your user
                    base, offering reliable performance even as transaction
                    volume grows.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/exchange/dse/customizable_user_interfaces.svg"
                      alt="customizable_user_interfaces"
                    />
                  </div>
                  <h3 className="pt-2">Full Customization</h3>
                  <p>
                    Whether you want to clone Uniswap or 1inch with additional
                    features or tailored UI/UX, we provide fully customizable
                    solutions to meet your specific requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
