import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function SupplyChainHero() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="ton-hero-dection-image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/layer/ton-hero-dection-image.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30">
                <h1 className="text-white">
                  Revolutionize Your Blockchain Projects with TON <br /> (The
                  Open Network)
                </h1>
                <p className="text-white">
                  Unlock the full potential of The Open Network with Xeer
                  Technology’s specialized TON blockchain development services,
                  built for speed, scalability, and a seamless user experience.
                </p>
                <div className="btn-box mt_40">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 " />
          </div>
        </div>
      </section>
    </>
  );
}
