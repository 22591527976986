import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import MarketMakingServicesHero from "./MarketMakingServicesHero";
import Pioneers from "./Pioneers";
import ByHarnessing from "./ByHarnessing";
import FosteringThriving from "./FosteringThriving";
import Exclusive from "./Exclusive";
import EIntegrations from "./EIntegrations";
import LookingD from "../About-Us/LookingD";
import WhatIsMarket from "./WhatIsMarket";
import WhyChooseCMMS from "./WhyChooseCMMS";
import Blog from "../Home/Blog";
import { Helmet } from "react-helmet";

export default function MarketMakingServices() {
  return (
    <>
      <Helmet>
        <title>
          Market-Making Services for Crypto Exchanges | Xeer Technology
        </title>
        <meta
          name="description"
          content="Boost liquidity and trading volume with Xeer’s market-making services. Customized strategies to enhance market efficiency."
        />
      </Helmet>
      <div>
        <Header />
        <MarketMakingServicesHero />
        <Pioneers />
        <WhatIsMarket />
        <FosteringThriving />
        <ByHarnessing />
        <Exclusive />
        <EIntegrations />
        <WhyChooseCMMS />
        {/* <Blog/> */}
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
