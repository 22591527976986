import React from "react";

export default function FrequentlyMlm() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Empower Your MLM Business with Xeer Technology</h2>
              <p>
                With Xeer Technology’s MLM Development & Services, you can bring
                your vision of a powerful network marketing platform to life.
                We’re here to deliver a secure, scalable, and engaging platform
                that adapts to your evolving business needs and fosters growth
                across your network.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
