import React from "react";

export default function Together() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Let’s build the future of crypto banking, together!
            </h2>
            <p className="text-white">
              At Xeer Technology, we’re driven by a passion for innovation and a
              commitment to security and compliance. With a team of blockchain
              experts and years of experience, we deliver end-to-end crypto
              banking solutions that empower users and drive business growth in
              the digital finance landscape.
            </p>
          </div>
          <div className="pt-5">
            <a href="/contact-us" className="theme-btn btn-one mr_20">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
