import React from "react";

export default function EthereumDevelopmentServices() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Begin Your Ethereum Journey with Xeer Technology
            </h2>
            <p className="text-white py-4">
              Tap into the power of Ethereum with Xeer Technology’s custom
              development solutions. Contact us to discuss your project and
              bring your blockchain vision to reality on the Ethereum network.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
