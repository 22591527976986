import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import AssetTokenizationHero from "./AssetTokenizationHero";
import MaximizeValue from "./MaximizeValue";
import ModulesofAsset from "./ModulesofAsset";
import WhyTokenize from "./WhyTokenize";
import TechnologyStack from "./TechnologyStack";
import { Helmet } from "react-helmet";

export default function AssetTokenization() {
  return (
    <>
      <Helmet>
        <title>Asset Tokenization Services | Xeer Technology</title>
        <meta
          name="description"
          content="Tokenize real-world assets with Xeer. Enable fractional ownership and blockchain-based liquidity solutions."
        />
      </Helmet>
      <div>
        <Header />
        <AssetTokenizationHero />
        <WhyTokenize />
        <MaximizeValue />
        <TechnologyStack />
        <ModulesofAsset />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
