import React from "react";

export default function TradingExchange() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-71.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features of Our Margin Trading Exchange</h2>
            <p className="pt-2">
              Our margin trading exchange platform provides a secure,
              high-performance, and flexible environment for traders and
              advanced tools for businesses to maximize their service offering.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "381px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/modular_architecture.svg"
                      alt="modular_architecture"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Modular Architecture</h3>
                    <p>
                      Our development approach utilizes a modular framework,
                      ensuring scalability and flexibility, so that new
                      features, tools, or asset classes can be easily integrated
                      into the exchange as needed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "381px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/integrated_risk_management.svg"
                      alt="integrated_risk_management"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Integrated Risk Management</h3>
                    <p>
                      Implemented with advanced risk management features such as
                      automated margin calls, stop-loss mechanisms, and
                      liquidation engines to protect both the platform and its
                      users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "381px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/high_performance_matching_engine.svg"
                      alt="high_performance_matching_engine"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">High-Performance Matching Engine</h3>
                    <p>
                      We provide an optimized order matching engine capable of
                      handling high-frequency trades with minimal latency,
                      ensuring a smooth and responsive trading experience for
                      users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "376px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/secure_lending_and_borrowing_pools.svg"
                      alt="secure_lending_and_borrowing_pools"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Secure Lending and Borrowing Pools</h3>
                    <p>
                      Secure lending pools that provide liquidity for margin
                      trading, with built-in smart contracts to manage interest
                      rates and loan collateralization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "376px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/api_integration.svg"
                      alt="api_integration"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">API Integration</h3>
                    <p>
                      Our platform offers comprehensive APIs, allowing seamless
                      integration with external services, including market data
                      providers, liquidity providers, and external trading bots.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "376px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/multi_layer_security.svg"
                      alt="multi_layer_security"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Multi-Layer Security</h3>
                    <p>
                      We implement multi-layer security protocols, including
                      encrypted communications, two-factor authentication (2FA),
                      and cold storage integration to safeguard user assets and
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/cross_platform_compatibility.svg"
                      alt="cross_platform_compatibility"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Cross-Platform Compatibility</h3>
                    <p>
                      The platform is designed to work seamlessly across
                      desktop, web, and mobile applications, ensuring a
                      consistent user experience across all devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "382px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/real_time_data_synchronization.svg"
                      alt="real_time_data_synchronization"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="pt-3 pb-2">
                      Real-Time Data Synchronization
                    </h3>
                    <p>
                      Developers can offer users real-time data synchronization
                      across their accounts, allowing instant updates on
                      positions, balances, and market movements without lag.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mte/compliance_tools.svg"
                      alt="compliance_tools"
                    />
                  </div>
                  <div className="table-header ">
                    <h3 className="py-3">Compliance Tools</h3>
                    <p>
                      Our platform integrates KYC and AML compliance modules,
                      making it easier for developers to adhere to regulatory
                      standards in different jurisdictions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box pt-5">
            <a
              href="https://t.me/Xeertechnology"
              target="_blank"
              className="theme-btn btn-one mr_20"
            >
              Connect on Telegram
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
