import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function ExchangeListingHero() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="list-your-coin-token-on-leading-crypto-exchange"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/listing/list-your-coin-token-on-leading-crypto-exchanges.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30 pt-5">
                <h1 className="text-white">
                  List Your Coin/Token on Leading Crypto Exchanges
                </h1>
                <p className="text-white pt-5">
                  We provide end-to-end support for crypto projects aiming to
                  list their tokens on top exchanges!
                </p>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 "></div>
          </div>
        </div>
      </section>
    </>
  );
}
