import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import PolygonBlockChainHero from "./PolygonBlockChainHero";
import PolygonBlockchaiService from "./PolygonBlockchaiService";
import OPStack from "./OPStack";
import OurOPStackSer from "./OurOPStackSer";
import BenefitsOfOPS from "./BenefitsOfOPS";
import PartnerWithXeer from "./PartnerWithXeer";
import { Helmet } from "react-helmet";

export default function PolygonBlockChain() {
  return (
    <>
      <Helmet>
        <title>Polygon Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content=" Scale your blockchain with Polygon. Xeer provides solutions for dApp development, Layer 2 scaling, and more."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <PolygonBlockChainHero />
        <PolygonBlockchaiService />
        <OPStack />
        <OurOPStackSer />
        <PartnerWithXeer />
        <BenefitsOfOPS />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
