import React from "react";
import Header from "../Coman/Header";
import LookingD from "../About-Us/LookingD";
import Footar from "../Coman/Footar";
import CryptoGamingPlatformHero from "./CryptoGamingPlatformHero";
import Keeps from "./Keeps";
import Features from "./Features";
import YourSecurityTO from "./YourSecurityTO";
import SecurityProtocols from "./SecurityProtocols";
import { Helmet } from "react-helmet";

export default function CryptoGamingPlatform() {
  return (
    <>
      <Helmet>
        <title>Blockchain-Based Securitization | Xeer Technology</title>
        <meta
          name="description"
          content=" Redefine financial instruments with blockchain-based securitization. Xeer delivers secure and transparent solutions."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoGamingPlatformHero />
        <YourSecurityTO />
        <Keeps />
        <SecurityProtocols />
        <Features />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
