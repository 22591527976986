import React from "react";

export default function Corporates() {
  return (
    <>
      <section
        className="integrations-section centred py_50"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white pb-3">
                Corporates & Businesses We Serve
              </h2>
              <p className="text-white">
                Empowering corporations and businesses across industries with
                customized blockchain solutions that drive innovation,
                efficiency, and growth in the digital era.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/gaming_studios.svg"
                        alt="gaming_studios"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Gaming Studios</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/nft_marketplaces.svg"
                        alt="nft_marketplaces"
                      />
                    </div>
                    <h5 className="pt-4 text-white">NFT Marketplaces</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/metaverse_developers.svg"
                        alt="metaverse_developers"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Metaverse Developers</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/esports_companies.svg"
                        alt="esports_companies"
                      />
                    </div>
                    <h5 className="pt-4 text-white">eSports Companies</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/corporate_clients.svg"
                        alt="corporate_clients"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Corporate Clients</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "60px" }}
                        src="assets/img/crypto-g-dev/svg/it_companies.svg"
                        alt="it_companies"
                      />
                    </div>
                    <h5 className="pt-4 text-white">IT Companies</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
