import React from "react";

export default function ReadyTransform() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">Technology Stack</h1>
            <p className="text-white py-4">
              Xeer’s solutions are built using blockchain technology, smart
              contracts, and APIs that connect with payment gateways and credit
              agencies, ensuring a comprehensive, secure, and scalable P2P
              lending experience.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Ready to transform lending with blockchain? Connect with us for your
            P2P platform needs.
          </a>
        </div>
      </section>
    </>
  );
}
