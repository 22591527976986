import React from "react";

export default function ComprehensiveDoc() {
  return (
    <>
      <section className="feature-style-two py_100 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of our Exchange Listing Service</h2>
            <p className="pt-3">
              Seamlessly navigate your token’s listing journey to top exchanges
              with tailored support, strategic market positioning, and <br />
              post-listing growth solutions.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/comprehensive_documentation_support.svg"
                      alt="comprehensive_documentation_support"
                    />
                  </div>
                  <h3>Comprehensive Documentation Support</h3>
                  <p>
                    Expert assistance in preparing required legal, technical,
                    and financial documents to meet exchange requirements and
                    compliance standards.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/exchange_network_access.svg"
                      alt="exchange_network_access"
                    />
                  </div>
                  <h3>Exchange Network Access</h3>
                  <p>
                    Leveraging established relationships with top exchanges,
                    Xeer Technology helps projects secure listings on platforms
                    best suited to their goals, whether high-liquidity CEXs or
                    popular DEXs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/listing_strategy_development.svg"
                      alt="listing_strategy_development"
                    />
                  </div>
                  <h3>Listing Strategy Development</h3>
                  <p>
                    Crafting a listing strategy tailored to maximize token
                    exposure, ensure liquidity, and align with long-term project
                    goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/token_liquidity_solutions.svg"
                      alt="token_liquidity_solutions"
                    />
                  </div>
                  <h3>Token Liquidity Solutions</h3>
                  <p>
                    Market-making services to enhance token liquidity
                    post-listing, facilitating a stable trading environment that
                    attracts new traders and investors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/marketing_promotion.svg"
                      alt="marketing_promotion"
                    />
                  </div>
                  <h3>Marketing & Promotion</h3>
                  <p>
                    Providing listing promotion services, including press
                    releases, social media, and targeted campaigns to maximize
                    reach and engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/listing/svg/post_listing_supports.svg"
                      alt="post_listing_supports"
                    />
                  </div>
                  <h3>Post-Listing Support</h3>
                  <p>
                    Continuous monitoring and reporting services to track token
                    performance, manage liquidity, and optimize token price
                    stability over time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
