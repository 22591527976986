import React from "react";

export default function DeFiSConDev() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer Technology for Your Smart Contract Needs?</h2>
              <p>
                With extensive experience and a dedicated focus on security,
                Xeer Technology is a trusted partner for smart contract
                development and auditing. We are committed to delivering
                cutting-edge blockchain solutions that prioritize your project’s
                integrity, performance, and user experience.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
