import React from "react";

export default function PartnerWithXeer() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Partner with Xeer Technology</h2>
            <p className="text-white py-4">
              Leverage the full potential of OP Stack for your project. With
              Xeer Technology’s expertise, build scalable, efficient, and
              Ethereum-compatible applications that meet today’s blockchain
              demands. Get in touch to start your OP Stack journey with Xeer
              today.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
