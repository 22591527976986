import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function NFTartH() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="defi-smart-contract-solutions-made-secure-and-scalable"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/smart-con/defi-smart-contract-solutions-made-secure-and-scalable.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30 pt-5">
                <h1 className="text-white">
                  DeFi Smart Contract Solutions Made Secure and Scalable
                </h1>
                <p className="text-white py-4">
                  Unlock the potential of decentralized finance with Xeer
                  Technology’s trusted smart contract development, providing
                  secure, transparent, and innovative DeFi solutions tailored to
                  meet your business needs.
                </p>

                <div className="btn-box mt_40">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 "></div>
          </div>
        </div>
      </section>
    </>
  );
}
