import React from "react";

export default function SaveBig() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_40">
              <h2>Get Started with DeFi Wallet Development Today</h2>
              <p>
                Empower your users with a secure, feature-rich solution for
                managing and interacting with decentralized finance platforms.
                Whether you’re a startup, fintech company, or established
                business, Xeer Technology offers tailor-made DeFi wallet
                solutions that provide seamless access to DeFi protocols,
                multi-chain support, and cutting-edge security features.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
