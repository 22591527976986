import React from "react";
import Marquee from "react-fast-marquee";

export default function Integrations() {
  return (
    <>
      <section
        className="integrations-section centred pb_150 teg-img-bgimg"
        aria-label="gradient_shape_02"
      >
        <div className="">
          <div className="sec-title mb_60">
            <div className="inner_wrap">
              <h2>Technologies we work with</h2>
              <span class="border_btm">
                We have worked on several leading blockchain networks including
                Ethereum, Solana, Polygon, BNB Chain, Tron, Arbitrum & many
                more.
              </span>
            </div>
          </div>
          <Marquee pauseOnHover="false">
            <ul className=" d-flex gap-3 align-items-center ">
              <div className="img-box-home" style={{ marginLeft: "0.5%" }}>
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/bsc.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/btc.svg"
                    alt="btc"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/cardano.svg"
                    alt="cardano"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/eth.svg"
                    alt="eth"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/hedera.svg"
                    alt="hedera"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/near.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/polkadot.svg"
                    alt="polkadot"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/polygon.svg"
                    alt="polygon"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/solana.svg"
                    alt="solana"
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/steller.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/tron.svg"
                    alt="tron"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/waves.svg"
                    alt="waves"
                  />
                </li>
              </div>
            </ul>
          </Marquee>

          <Marquee direction="right" speed="150" pauseOnHover="false">
            <ul className=" d-flex gap-3 align-items-center ">
              <div className="img-box-home" style={{ marginLeft: "0.5%" }}>
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Amazon.svg"
                    alt="Amazon"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Angular.svg"
                    alt="Angular"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Drupal.svg"
                    alt="Drupal"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Express_JS.svg"
                    alt="Express_JS"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Flutter.svg"
                    alt="Flutter"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Github.svg"
                    alt="Github"
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/GO.svg"
                    alt="GO"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/java.svg"
                    alt="java"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Laravel.svg"
                    alt="Laravel"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/MongoDB.svg"
                    alt="MongoDB"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/MySQL.svg"
                    alt="MySQL"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Next.JS.svg"
                    alt="Next.JS"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Node_JS.svg"
                    alt="Node_JS"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Nuxt.JS.svg"
                    alt="Nuxt.JS"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="">
                  <img
                    style={{ width: "55%" }}
                    src="assets/img/icon/icon1/Oracle.svg"
                    alt="Oracle"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/python.svg"
                    alt="python"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/react.svg"
                    alt="react"
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Solidity.svg"
                    alt="Solidity"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Swift.svg"
                    alt="Swift"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Three_JS.svg"
                    alt="Three_JS"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Typescript.svg"
                    alt="Typescript"
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/WebRTC.svg"
                    alt="WebRTC"
                  />
                </li>
              </div>
            </ul>
          </Marquee>
        </div>
      </section>
    </>
  );
}
