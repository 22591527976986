import React from "react";

export default function Borrowing() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Quick Insight For DeFi Lending Borrowing Industry</h2>
              <p>
                DeFi lending and borrowing protocols, which held roughly $20-30
                billion in Total Value Locked (TVL) by late 2023, are expected
                to continue their growth trajectory. Analysts estimate that TVL
                in DeFi lending could reach $40–60 billion by the end of 2024 as
                more institutions and individuals seek alternative financial
                solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
