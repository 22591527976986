import React from "react";

export default function Layer0BlockchainAsked() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Get Started with Xeer's BSC Development Services
            </h2>
            <p className="text-white py-4">
              Ready to take your project to Binance Smart Chain? Partner with
              Xeer Technology to access customized BSC development services,
              expert guidance, and a team dedicated to delivering results.
              Connect with us today to discuss your Binance Smart Chain journey.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
