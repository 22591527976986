import React from "react";

export default function SupplyChainSolutions() {
  return (
    <>
      <section className="feature-style-two py_50 centred">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Xeer’s TON Blockchain Development Services</h2>
            <p className="pt-3">
              Xeer Technology’s TON development services are tailored to bring
              innovative and efficient blockchain solutions to life, <br />{" "}
              empowering your business to thrive in the TON ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/dapps.svg"
                      alt="dapps"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>TON DApp Development</h3>
                  <p>
                    We build high-performance DApps on TON, taking full
                    advantage of its sharding capabilities and fast transaction
                    processing to ensure a smooth, scalable user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/smart_contracts.svg"
                      alt="smart_contracts"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    Our skilled developers design secure and reliable smart
                    contracts using TON’s unique architecture, ideal for
                    automated business logic across diverse applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/crypto_token.svg"
                      alt="crypto_token"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>TON Token Development</h3>
                  <p>
                    Whether you need utility tokens, security tokens, or NFTs,
                    our TON token development services create tokenized assets
                    to facilitate seamless transactions and rewards.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/nft_marketplace_development.svg"
                      alt="nft_marketplace_development"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>NFT Marketplace Development</h3>
                  <p>
                    We specialize in creating NFT marketplaces on TON,
                    supporting multi-chain compatibility, rapid transactions,
                    and secure asset transfers for digital collectibles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/cross_chain_bridge_development.svg"
                      alt="cross_chain_bridge_development"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>Cross-Chain Bridge Development</h3>
                  <p>
                    Our cross-chain bridge solutions allow interoperability with
                    other networks, enhancing the flexibility and usability of
                    your TON-based applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className>
                    <img
                      src="assets/img/consulting.svg"
                      alt="consulting"
                      style={{ width: "70px" }}
                    />
                  </div>
                  <h3>TON Consulting and Strategy</h3>
                  <p>
                    Our TON experts provide consulting and strategic guidance,
                    helping you leverage the TON network’s unique features to
                    align with your business goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
