import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DecentralizedExchangeHero() {
  return (
    <>
      <section
        className="faq-style-three py_150"
        aria-label="Crypto-Derivatives-Exchange-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Crypto-Derivatives-Exchange-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-4">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 pt_120 content-column">
              <div data-animation-box className="sec-title mb_25">
                <h2 className="text-white">
                  Crypto Derivatives Exchange Development
                </h2>
                <p className="text-white pt-3">
                  Build crypto derivatives exchange for seamless futures,
                  options, and perpetual swaps trading – tailored for the modern
                  crypto derivatives market.
                </p>
              </div>
              <div className="btn-box pt-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
