import React from "react";

export default function DEXExchange() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our White Label DEX Exchange:</h2>
          </div>
          <div className="d-flex gap-2 flex-wrap justify-content-center ">
            <div className="justify-content-center">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/advanced_trading_options.svg"
                        alt="advanced_trading_options"
                      />
                    </div>
                    <h5>Advanced Trading Options</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="justify-content-center">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/state_of_the_art_security_mechanisms.svg"
                        alt="state_of_the_art_security_mechanisms"
                      />
                    </div>
                    <h5 className="pt-2">
                      State-of-the-art Security Mechanisms
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-center">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/impenetrable_smart_contracts.svg"
                        alt="impenetrable_smart_contracts"
                      />
                    </div>
                    <h5>Impenetrable Smart Contracts</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-center">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/support_multiple_payment_gateways.svg"
                        alt="support_multiple_payment_gateways"
                      />
                    </div>
                    <h5>Support Multiple Payment Gateways</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-center">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/easy_to_navigate_platform.svg"
                        alt="easy_to_navigate_platform"
                      />
                    </div>
                    <h5>Easy-to-navigate Platform</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 flex-wrap pt-3 justify-content-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/simple_yet_intuitive_user_interface.svg"
                        alt="simple_yet_intuitive_user_interface"
                      />
                    </div>
                    <h5>Simple Yet Intuitive User Interface</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/real_time_alerts_and_notifications.svg"
                        alt="real_time_alerts_and_notifications"
                      />
                    </div>
                    <h5>Real-time Alerts and Notifications </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/multi_crypto_and_fiat_support.svg"
                        alt="multi_crypto_and_fiat_support"
                      />
                    </div>
                    <h5>Multi-Crypto and Fiat Support</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/customizable_and_scalable_platform.svg"
                        alt="customizable_and_scalable_platform"
                      />
                    </div>
                    <h5>100% Customizable and Scalable Platform</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{ height: "230px" }}>
                    <div className="icon-box">
                      <img
                        src="assets/img/exchange/dde/robust_admin_and_user_dashboard.svg"
                        alt="robust_admin_and_user_dashboard"
                      />
                    </div>
                    <h5>Robust Admin and User Dashboard</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
