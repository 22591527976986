import React from "react";

export default function RoleofOrderB() {
  return (
    <>
      <section className="contact-info-section centred py_80">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of an Order Book DEXs</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/dex/SVG/order_book_structure.svg"
                      alt="order_book_structure"
                    />
                  </div>
                  <h3>Order Book Structure</h3>
                  <p>
                    An order book is a digital ledger that lists all current buy
                    (bid) and sell (ask) orders for a particular trading pair.
                    It displays the price and quantity of assets that traders
                    are willing to buy or sell.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/dex/SVG/placing_orders.svg"
                      alt="placing_orders"
                    />
                  </div>
                  <h3>Placing Orders</h3>
                  <p>
                    Users can place different types of orders; Limit Orders,
                    market orders or stop orders. Traders can specify a price at
                    which a trader is willing to buy or sell an asset at
                    predetermined marks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/dex/SVG/placing_orders.svg"
                      alt="placing_orders"
                    />
                  </div>
                  <h3>Order Matching</h3>
                  <p>
                    The DEX matches buy and sell orders based on price and time
                    priority. When a buy order matches a sell order, a trade is
                    executed, and the assets are transferred directly between
                    the wallets of the involved parties.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="">
                    <img
                      style={{ width: "90px" }}
                      src="assets/img/dex/SVG/decentralize.svg"
                      alt="decentralize"
                    />
                  </div>
                  <h3>Decentralization</h3>
                  <p>
                    Order Book DEXs operate without a central authority, meaning
                    users maintain control over their funds throughout the
                    trading process. Transactions are recorded on the
                    blockchain, ensuring transparency and security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
