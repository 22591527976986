import React from "react";

export default function Industries() {
  return (
    <>
      <section
        className="feature-style-two pb_100 pt-5 centred bg-web3"
        aria-label="web3_section_image_02"
      >
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="border-Left-hero">
                <p className="text-start">
                  &nbsp;ENHANCE YOUR INDUSTRY WITH US
                </p>
                <h1 className="text-start" style={{ color: "white" }}>
                  &nbsp;Web3 <br />
                  &nbsp;solutions <br />
                  &nbsp;for your industry
                </h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex gap-3 pt-4 flex-wrap  justify-content-end">
                <div className="">
                  <div className="feature-block">
                    <div
                      className="feature-block-hero-glassmorphic"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/img/svg/healthcare_&_medicine.svg"
                            alt="healthcare_&_medicine"
                            style={{ width: "40%" }}
                          />
                        </div>
                        <h5 className="pt-3">
                          <a href="">Healthcare & Medicine</a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="feature-block">
                    <div
                      className="feature-block-hero-glassmorphic"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={900}
                    >
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/img/svg/fintech_&_banking.svg"
                            alt="fintech_&_banking"
                            style={{ width: "40%" }}
                          />
                        </div>
                        <h5 className="pt-3">
                          <a href="">Fintech & Banking</a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 pt-4 flex-wrap  justify-content-end  ">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/supply_chain.svg"
                        alt="supply_chain"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Supply Chain </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/edtech_&_elearning.svg"
                        alt="edtech_&_elearning"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">EdTech & Elearning</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/marketing_&_sales.svg"
                        alt="marketing_&_sales"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Marketing & Sales</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 pt-4 flex-wrap ps-3  justify-content-end justify-content-sm-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/ecommerce_&_retail.svg"
                        alt="ecommerce_&_retail"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">E-Commerce & Retail</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/arts_&_entertainment.svg"
                        alt="arts_&_entertainment"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Arts & Entertainment</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/customer_service_&_hr.svg"
                        alt="customer_service_&_hr"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Customer Service & HR</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/legal_&_compliance.svg"
                        alt="legal_&_compliance"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Legal & Compliance</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero-glassmorphic"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        src="assets/img/svg/legal_&_compliance_01.svg"
                        alt="legal_&_compliance_01"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h5 className="pt-3">
                      <a href="">Real estate</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
