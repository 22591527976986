import React from "react";

export default function SemiBenefits() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer for P2P Lending Platform Development?</h2>
              <p>
                With expertise in blockchain and fintech, Xeer Technology
                provides secure, scalable P2P lending solutions tailored to your
                business model. Our solutions focus on regulatory compliance,
                risk management, and user-centric design to maximize platform
                success.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
