import React from "react";
import Header from "../Coman/Header";
import CryptoLaunchpadHero from "./CryptoLaunchpadHero";
import Footar from "../Coman/Footar";
import Journey from "./Journey";
import ICOLaunchpad from "./ICOLaunchpad";
import LookingD from "../About-Us/LookingD";
import WinWin from "./WinWin";
import WhiteLCryL from "./WhiteLCryL";
import KnowYour from "./KnowYour";
import WhatMakes from "./WhatMakes";
import { Helmet } from "react-helmet";

export default function CryptoLaunchpad() {
  return (
    <>
      <Helmet>
        <title>Crypto Launchpad Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Build your token launch platform with Xeer. Comprehensive solutions for fundraising, user onboarding, and token distribution."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoLaunchpadHero />
        <Journey />
        <ICOLaunchpad />
        <KnowYour />
        <WinWin />
        <WhiteLCryL />
        <WhatMakes />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
