import React from "react";
import Header from "../Coman/Header";
import NFTWalletHero from "./NFTWalletHero";
import Footar from "../Coman/Footar";
import HowSecure from "./HowSecure";
import Custodia from "./Custodia";
import Requiremen from "./Requiremen";
import Achieving from "./Achieving";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";
export default function NFTWallet() {
  return (
    <>
      <Helmet>
        <title>NFT Wallet Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Enable seamless storage and trading of NFTs with Xeer’s NFT wallet solutions. Secure, scalable, and user-friendly platforms."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <NFTWalletHero />
        <HowSecure />
        <Custodia />
        <Requiremen />
        <Achieving />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
