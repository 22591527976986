import React from "react";
import Header from "../../Coman/Header";
import Layer0SolutionHero from "./Layer0SolutionHero";
import Layer0Blockchain from "./Layer0Blockchain";
import Layer0BlockchainTechnology from "./Layer0BlockchainTechnology";
import Layer0BlockchainAsked from "./Layer0BlockchainAsked";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Fundamental from "./Fundamental";
import { Helmet } from "react-helmet";

export default function Layer1Solution() {
  return (
    <>
      <Helmet>
        <title>Binance Smart Chain (BSC) Development | Xeer Technology</title>
        <meta
          name="description"
          content=" Build secure and scalable blockchain applications on Binance Smart Chain with Xeer’s expert development services."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <Layer0SolutionHero />
        <Layer0Blockchain />
        <Layer0BlockchainTechnology />
        <Layer0BlockchainAsked />
        <Fundamental />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
