import React from "react";

export default function HarshgraphDevelopmentHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="What-is-Hedera-Hashgraph"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/blockchain-dev/What-is-Hedera-Hashgraph.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center P_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">What is Hedera Hashgraph?</h2>
                </div>
                <div className="text-box">
                  <p className="text-white fs-5">
                    Hedera Hashgraph is a public distributed ledger that uses a
                    hashgraph consensus algorithm, setting it apart from
                    traditional blockchain’s linear structure. Leveraging a
                    Directed Acyclic Graph (DAG), Hedera Hashgraph processes
                    transactions in parallel, allowing high-speed consensus and
                    efficiency.
                  </p>
                </div>
              </div>
              <a href="/contact-us" className="theme-btn btn-one m_20">
                Schedule A Free Demo
              </a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
