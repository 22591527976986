import React from "react";

export default function DeFiConS() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              What to Expect From Our DeFi Yield Farming Platform Development
              Services
            </h2>
            <p>
              Xeer Technology delivers cutting-edge solutions with seamless
              smart contract automation, multi-asset support, advanced
              analytics, and cross-chain compatibility, ensuring a powerful and
              user-friendly yield farming experience.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/automated_smart_contracts.svg"
                      alt="automated_smart_contracts"
                    />
                  </div>
                  <h3>Automated Smart Contracts</h3>
                  <p>
                    Our platforms leverage automated smart contracts to handle
                    yield distribution with precision and efficiency. These
                    self-executing contracts ensure that rewards are distributed
                    accurately and transparently without the need for manual
                    intervention, reducing the risk of human error and
                    streamlining the entire process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/multi_asset_support.svg"
                      alt="multi_asset_support"
                    />
                  </div>
                  <h3>Multi-Asset Support</h3>
                  <p>
                    We design platforms with multi-asset support, enabling users
                    to stake a variety of cryptocurrencies and tokens. This
                    flexibility allows for diversified investment opportunities,
                    giving users more options to earn rewards from different
                    asset classes while ensuring compatibility with major
                    blockchain networks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/secure_liquidity_pools.svg"
                      alt="secure_liquidity_pools"
                    />
                  </div>
                  <h3>Secure Liquidity Pools</h3>
                  <p>
                    Security is our top priority. Our liquidity pools are
                    designed with advanced security protocols to safeguard user
                    funds. We implement rigorous auditing and multi-layered
                    encryption to protect assets from potential threats,
                    ensuring users can confidently participate in yield farming
                    without worrying about vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/flexible_staking_options.svg"
                      alt="flexible_staking_options"
                    />
                  </div>
                  <h3>Flexible Staking Options</h3>
                  <p>
                    Our platforms offer customizable staking options to
                    accommodate various reward systems and lock-up periods.
                    Users can choose from flexible or fixed-term staking,
                    maximizing their earning potential based on their individual
                    preferences, whether they are seeking high returns or
                    liquidity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/advanced_analytics_dashboards.svg"
                      alt="advanced_analytics_dashboards"
                    />
                  </div>
                  <h3>Advanced Analytics Dashboards</h3>
                  <p>
                    We integrate real-time analytics dashboards that provide
                    users with comprehensive insights into their yield farming
                    performance. These dashboards display vital data such as
                    reward history, APY (Annual Percentage Yield), pool
                    liquidity, and asset performance, empowering users to make
                    informed decisions and optimize their strategies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/cross_chain_compatibility.svg"
                      alt="cross_chain_compatibility"
                    />
                  </div>
                  <h3>Cross-Chain Compatibility</h3>
                  <p>
                    Our yield farming platforms are built with cross-chain
                    functionality, allowing seamless interaction between
                    multiple blockchain networks. This feature enables users to
                    farm yields across different ecosystems, enhancing liquidity
                    access and diversifying opportunities for earning rewards
                    while boosting overall platform utility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
