import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SolanaHero from "./SolanaHero";
import SolanaPerfect from "./SolanaPerfect";
import SolanaServices from "./SolanaServices";
import SolanaServicesPrice from "./SolanaServicesPrice";
import SolanaOffered from "./SolanaOffered";
import { Helmet } from "react-helmet";

export default function Solana() {
  return (
    <>
      <Helmet>
        <title>Solana Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content=" Build high-performance applications on Solana. Xeer specializes in scalable dApps and token development."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <SolanaHero />
        <SolanaServicesPrice />
        <SolanaServices />
        <SolanaPerfect />
        <SolanaOffered />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
