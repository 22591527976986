import React from "react";
import Header from "../../Coman/Header";
import Footar from "../../Coman/Footar";
import LookingD from "../../About-Us/LookingD";
import ExchangeListingHero from "./ExchangeListingHero";
import ComprehensiveDoc from "./ComprehensiveDoc";
import Binance from "./Binance";
import { Helmet } from "react-helmet";

export default function ExchangeListing() {
  return (
    <>
      <Helmet>
        <title>Crypto Exchange Listing Services | Xeer Technology</title>
        <meta
          name="description"
          content="Get your token listed on top crypto exchanges with Xeer. We ensure seamless listing, compliance, and promotion to maximize visibility and trading activity."
        />
      </Helmet>
      <div>
        <Header />
        <ExchangeListingHero />
        <ComprehensiveDoc />
        <Binance />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
