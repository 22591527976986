import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DerivativesExchangeHero() {
  return (
    <>
      <section
        className="faq-style-three py_150"
        aria-label="Decentralized-Exchange-hero"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/dex/Decentralized-Exchange-hero.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Decentralized Exchange (DEX) Development
                  </h2>
                  <p className="text-white pt-3">
                    Xeer Technology specializes in building secure, transparent,
                    and user-friendly decentralized exchange (DEX) where users
                    can trade cryptocurrencies directly without relying on
                    intermediaries. These exchanges operate on blockchain and
                    enable peer-to-peer trading through smart contracts,
                    ensuring full control over user funds.
                  </p>
                </div>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
