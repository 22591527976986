import React from "react";

export default function DecentralizedES() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Get Decentralized Exchange Script</h2>
            <h5 className="p-3">
              Kickstart your own platform with our scripts & launch a DEX within
              minutes!
            </h5>
            <p>
              A Decentralized Exchange (DEX) Script is a pre-built software
              package designed to facilitate the launch of a decentralized
              cryptocurrency exchange. This script includes core features,
              functionalities, and smart contract integration, enabling
              peer-to-peer trading without the need for intermediaries. The DEX
              script allows customization to meet specific project requirements
              and ensures compliance with decentralized finance standards.
            </p>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/dydx.svg"
                    alt="dydx"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/coinw.svg"
                    alt="coinw"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/bitmart.svg"
                    alt="bitmart"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/listing/svg/1_inch.svg"
                    alt="1_inch"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/bakeryswap.svg"
                    alt="bakeryswap"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/dodo.svg"
                    alt="dodo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/mdex.png"
                    alt="mdex"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center ">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/uniswape.svg"
                    alt="uniswape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 justify-content-center">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/dex/SVG/pancakeswap_cake.svg"
                    alt="pancakeswap_cake"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt_100">
            <a href="/contact-us" className="theme-btn btn-one">
              Get Your DEX Script
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
