import React from "react";

import HarshgraphDevelopmentHero from "./HarshgraphDevelopmentHero";
import WhatisHedera from "./WhatisHedera";
import HashgraphServicesOffered from "./HashgraphServicesOffered";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Header from "../../Coman/Header";
import { Helmet } from "react-helmet";

export default function HarshgraphDevelopment() {
  return (
    <>
      <Helmet>
        <title>Hedera Hashgraph Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Leverage the power of Hedera Hashgraph for secure and scalable dApp development. Trust Xeer for tailored solutions."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <HarshgraphDevelopmentHero />
        <WhatisHedera />
        <HashgraphServicesOffered />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
