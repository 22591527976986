import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function WhiteLabelNFTHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="NFT-Collection-Launch-Hero-Section-File"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/nft_dev/NFT-Collection-Launch-Hero-Section-File.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">NFT Collection Launch</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Bring your creative vision to life and enter the thriving
                    world of digital assets with a unique NFT collection. At
                    Xeer Technology, we provide expert guidance and end-to-end
                    development services to help you launch an NFT collection
                    that captivates collectors, artists, and investors alike.
                  </p>
                </div>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
