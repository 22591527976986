import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function TokenListingHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="get-your-token-recognized-on-leading-platforms"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/listing/get-your-token-recognized-on-leading-platforms.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Get Your Token Recognized on Leading Platforms
                  </h2>
                  <p className="pt-3 text-white">
                    End-to-end token listing services that streamline your
                    project’s entry into the global market.
                  </p>
                </div>
                <div className="btn-box pt-5">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
