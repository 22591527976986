import React from "react";

export default function Providers() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Stunning Features of Xeer Technology’s AMM DEX </h2>
            <p className="pt-3">
              Our custom-built AMM DEXs are equipped with industry-leading
              features, security protocols, and performance optimizations that
              deliver a superior decentralized trading experience.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/svg/external_liquidity_module.svg"
                      alt="external_liquidity_module"
                    />
                  </div>
                  <h3>Liquidity Pools for Efficient Market Making</h3>
                  <p>
                    Our AMM DEX platforms leverage liquidity pools, allowing
                    users to contribute assets to the pool in exchange for
                    rewards, creating continuous liquidity for seamless,
                    low-slippage trading.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/automated_pricing.svg"
                      alt="automated_pricing"
                    />
                  </div>
                  <h3>Automated Pricing Mechanisms</h3>
                  <p>
                    Our AMMs use automated pricing algorithms (e.g., Constant
                    Product Formula) that adjust prices based on supply and
                    demand, providing fair and transparent pricing without
                    intermediaries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/yield_programs.svg"
                      alt="yield_programs"
                    />
                  </div>
                  <h3>Yield Farming and Staking</h3>
                  <p>
                    We offer integrated yield farming and staking options,
                    enabling liquidity providers to earn additional rewards and
                    enhancing your platform’s attractiveness for DeFi users
                    seeking passive income.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/multi_chain.svg"
                      alt="multi_chain"
                    />
                  </div>
                  <h3>Cross-Chain Compatibility</h3>
                  <p>
                    Xeer Technology’s AMM DEXs can operate across multiple
                    blockchain networks like Ethereum, BNB Chain, and Polygon,
                    allowing users to trade assets from various ecosystems
                    within one platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/Seamless_ui_ux.svg"
                      alt="Seamless_ui_ux"
                    />
                  </div>
                  <h3>Intuitive and Customizable User Interface</h3>
                  <p>
                    Our AMM DEX platforms are designed for ease of use, with a
                    sleek and customizable UI that simplifies the trading and
                    liquidity provision experience for all users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "345px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/atb/real_time_monitoring.svg"
                      alt="real_time_monitoring"
                    />
                  </div>
                  <h3>Real-Time Data and Analytics</h3>
                  <p>
                    Integrated analytics tools allow users to view pool
                    performance, volume, and real-time price data, enabling them
                    to make informed trading decisions and track market trends.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
