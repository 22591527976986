import React from "react";

export default function AppWeUSE() {
  return (
    <>
      <section
        className="cta-style-two centred bg-black"
        style={{ paddingBlockEnd: "5%", paddingTop: "5%" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <div
              className="pattern-layer"
              style={
                {
                  // backgroundImage: "url(assets/img/O55GQZ0.jpg)",
                }
              }
            />
            <p>
              By leveraging an extensive array of blockchain protocols,
              development frameworks & advanced design tools, our skilled team
              at Xeer Technology provides customized NFT game development
              solutions designed to meet your specific needs!
            </p>
            <div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/unreal_engine.svg"
                    alt="unreal_engine"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/blender.svg"
                    alt="blender"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/maya.svg"
                    alt="maya"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/photoshop.svg"
                    alt="photoshop"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/substance_painter.svg"
                    alt="substance_painter"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 pt-4">
                  <img
                    style={{ width: "100px" }}
                    src="assets/img/nft_dev/game/unity.svg"
                    alt="unity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
