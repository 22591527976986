import React from "react";

export default function Layer2Presenting() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Polkadot for Your Blockchain Project?</h2>
              <p>
                Polkadot’s unique multi-chain framework allows for seamless
                interoperability between different blockchains, making it a
                game-changer for cross-chain applications. By enabling
                parachains and supporting decentralized networks, Polkadot
                creates a scalable, flexible environment for DApps, DeFi
                projects, and enterprise solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
