import React from "react";

export default function GetStarted() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_50 pb_50 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h3 className="text-white">
              Unlock the potential of cryptocurrency payments with Xeer
              Technology. Contact us today to learn more about our Crypto
              Payment Gateway!
            </h3>
          </div>
          <div className="">
            <a href="/contact-us" className="theme-btn btn-one mr_20">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
