import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MlmHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="mlm-developments-servicess"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/mlm-developments-servicess.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <h1 className="text-white">MLM Developments & Services</h1>
                <p className="text-white py-4">
                  Xeer Technology specializes in developing robust Multi-Level
                  Marketing (MLM) platforms tailored to fit any business model.
                  Whether you’re building a network marketing platform from the
                  ground up or enhancing an existing one, we provide end-to-end
                  solutions that empower your business to grow, scale, and
                  manage network members efficiently.
                </p>
              </div>
              <div className="btn-box pt-4">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
