import React from "react";
import Header from "../Coman/Header";
import DefiLendingAndBorrowingHero from "./DefiLendingAndBorrowingHero";
import Footar from "../Coman/Footar";
import LookingD from "../About-Us/LookingD";
import Strategic from "./Strategic";
import Borrowing from "./Borrowing";
import DefiBCon from "./DefiBCon";
import SelfCustody from "./SelfCustody";
import WhyXeerofDefiBorr from "./WhyXeerofDefiBorr";
import LaunchYourDeFi from "./LaunchYourDeFi";
import { Helmet } from "react-helmet";

export default function DefiLendingAndBorrowing() {
  return (
    <>
      <Helmet>
        <title>DeFi Lending & Borrowing Solutions | Xeer Technology</title>
        <meta
          name="description"
          content="Create seamless lending and borrowing platforms with Xeer. Secure smart contracts ensure trust in decentralized financial ecosystems."
        />
      </Helmet>
      <div>
        <Header />
        <DefiLendingAndBorrowingHero />
        <Borrowing />
        <Strategic />
        <DefiBCon />
        <SelfCustody />
        <LaunchYourDeFi />
        <WhyXeerofDefiBorr />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
