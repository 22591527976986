import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CryptoAssetManHero from "./CryptoAssetManHero";
import WhyArentYou from "./WhyArentYou";
import Handpicking from "./Handpicking";
import OurCryptoAsset from "./OurCryptoAsset";
import TakeControl from "./TakeControl";
import { Helmet } from "react-helmet";

export default function CryptoAssetManagement() {
  return (
    <>
      <Helmet>
        <title>Crypto Asset Management Services | Xeer Technology</title>
        <meta
          name="description"
          content="Manage your digital assets securely with Xeer’s innovative blockchain-based asset management solutions."
        />
      </Helmet>
      <div>
        <Header />
        <CryptoAssetManHero />
        <WhyArentYou />
        <Handpicking />
        <TakeControl />
        <OurCryptoAsset />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
