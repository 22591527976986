import React from "react";

export default function WhatOrderBookDEX() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is an Order Book DEX?</h2>
              <p>
                An Order Book DEX (Decentralized Exchange) is a type of
                decentralized exchange that operates on the principles of
                traditional financial markets, utilizing an order book system to
                facilitate the trading of cryptocurrencies and other digital
                assets. Unlike Automated Market Maker (AMM) DEXs, which use
                liquidity pools and automated pricing algorithms, Order Book
                DEXs match buy and sell orders directly between users.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
