import React from "react";
import Header from "../Coman/Header";
import IDPDevelopmentHero from "./IDPDevelopmentHero";
import Footar from "../Coman/Footar";
import InsightsOn from "./InsightsOn";
import LookingD from "../About-Us/LookingD";
import WhyIDPDev from "./WhyIDPDev";
import Striking from "./Striking";
import Fundraising from "./Fundraising";
import Turbocharge from "./Turbocharge";
import { Helmet } from "react-helmet";

export default function IDPDevelopment() {
  return (
    <>
      <Helmet>
        <title>IDO Platform Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Launch your project with secure and scalable IDO platforms by Xeer Technology. Empower your token sale with user-friendly interfaces."
        />
      </Helmet>
      <div>
        <Header />
        <IDPDevelopmentHero />
        <InsightsOn />
        <Striking />
        <Fundraising />
        <Turbocharge />
        <WhyIDPDev />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
