import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function ArbitrageTradingBotsHero() {
  return (
    <>
      <section
        className="faq-style-three py_150"
        aria-label="Arbitrage-Trading-Bot-Development-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Arbitrage-Trading-Bot-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row clearfix pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box  mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Arbitrage Trading Bot Development
                  </h2>
                  <p className="text-white pt-3">
                    Automate profit opportunities by leveraging price
                    differences across multiple exchanges with real-time
                    precision.
                  </p>
                </div>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column pb_150"></div>
          </div>
        </div>
      </section>
    </>
  );
}
