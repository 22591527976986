import React from "react";

export default function BlockchainGameDevelopmentHero() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="Blockchain-Game-Development-Company-Hero-Section-Image"
        style={{
          backgroundImage:
            "url(assets/img/blockchain-dev/Blockchain-Game-Development-Company-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mt_55">
                <h2 className="text-white">
                  Blockchain Game Development Company
                </h2>
                <p className="text-white py-3">
                  At Xeer, our blockchain developers and game designers create
                  optimized, gamified blockchain solutions that enhance gameplay
                  without sacrificing performance.
                </p>
                <div className="btn-box mt_40">
                  <a href="/contact-us" className="theme-btn btn-one mr_20">
                    Schedule A Free Demo
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 " />
          </div>
        </div>
      </section>
    </>
  );
}
