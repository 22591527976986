import React from "react";

export default function BenefitsOfOPS() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2>
                    Benefits of OP Stack with
                    <span className="xeer-color"> Xeer Technology</span>
                  </h2>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "260px" }}>
                        <div className="d-flex align-items-center gap-2">
                          <div className="icon-box mb-0">
                            <img
                              src="assets/img/flexible.svg"
                              alt="flexible"
                              style={{ width: "40px" }}
                            />
                          </div>
                          <h5>
                            Modularity & <br /> Flexibility
                          </h5>
                        </div>
                        <p>
                          OP Stack’s modular design allows for customized
                          solutions, supporting rapid deployment and optimized
                          scalability.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "260px" }}>
                        <div className="d-flex align-items-center">
                          <div className="icon-box mb-0">
                            <img
                              src="assets/img/polygon/reduced-costs.svg"
                              alt="reduced-costs"
                              width={40}
                              height={40}
                              className="img-fluid mb-2"
                            />
                          </div>
                          <h5 className="ms-3">Reduced Costs</h5>
                        </div>
                        <p>
                          Optimistic rollups on OP Stack significantly lower
                          transaction fees, enabling cost-effective DApp
                          solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "260px" }}>
                        <div className="d-flex align-items-center gap-2">
                          <div className="icon-box mb-0">
                            <img
                              src="assets/img/framework/SVG/ethereum.svg"
                              alt="ethereum"
                              width={25}
                              height={25}
                              className="img-fluid mb-2"
                            />
                          </div>
                          <h5>
                            Ethereum <br />
                            Compatibility
                          </h5>
                        </div>
                        <p>
                          OP Stack’s framework ensures easy migration of
                          Ethereum-based applications and protocols.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box" style={{ height: "260px" }}>
                        <div className="d-flex align-items-center gap-2">
                          <div className="icon-box mb-0">
                            <img
                              src="assets/img/blockchain-dev/SVG/enhanced_security.svg"
                              alt="enhanced_security"
                              width={30}
                              height={30}
                              className="img-fluid mb-2"
                            />
                          </div>
                          <h5>
                            Security & <br /> Efficiency
                          </h5>
                        </div>
                        <p>
                          OP Stack enhances network security while enabling
                          efficient, high-speed transactions across layer-2
                          networks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="assets/img/polygon/technology-section-image.png"
                  alt="technology-section-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
