import React from "react";

export default function BankingServices() {
  return (
    <>
      <section className="contact-info-section centred py_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features & Functionalities of a Crypto Banking Platform</h2>
            <p>
              Explore essential tools and innovative functionalities that
              empower users to seamlessly manage, transact, and grow their
              digital assets within a secure and compliant crypto banking
              ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "470px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/multi_currency_wallet_integration.svg"
                      alt="multi_currency_wallet_integration"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>

                  <h3>Multi-Currency Wallet Integration</h3>
                  <p>
                    Support for various cryptocurrencies, allowing users to
                    store, send, receive, and swap assets with ease.
                    Multi-signature and biometric security enhance asset safety.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "470px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/stake.svg"
                      alt="stake"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Staking and Yield Generation</h3>
                  <p>
                    Enable users to earn passive income by staking and farming
                    crypto assets, with multiple staking pools and
                    yield-generating options directly from the platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "470px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto_backed_loans_and_credit.svg"
                      alt="crypto_backed_loans_and_credit"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Crypto-Backed Loans and Credit</h3>
                  <p>
                    Decentralized lending and borrowing users can access credit
                    lines or loans against their crypto holdings, with
                    competitive interest rates and flexible repayment terms.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "470px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/fiat_on_ramp.svg"
                      alt="fiat_on_ramp"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Fiat On-Ramp and Off-Ramp</h3>
                  <p>
                    Easy integration with fiat gateways, allowing users to buy
                    or sell crypto assets using fiat currencies, making the
                    platform accessible to traditional banking users as well.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/automated_trading.svg"
                      alt="automated_trading"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Automated Trading and Investment Bots</h3>
                  <p>
                    Advanced trading and investment algorithms, enabling users
                    to automate strategies and maximize returns while reducing
                    market risks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/kyc.svg"
                      alt="kyc"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Regulatory Compliance and KYC/AML</h3>
                  <p>
                    Compliant with global regulations, incorporating seamless
                    KYC/AML procedures, data encryption, and real-time
                    monitoring for a secure and lawful user experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/crypto-g-dev/mobile_support.svg"
                      alt="mobile_support"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  </div>
                  <h3>Intuitive User Interface and Mobile Support</h3>
                  <p>
                    A modern, easy-to-navigate interface optimized for mobile
                    and desktop, ensuring users have a smooth experience in
                    managing their assets anytime, anywhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
