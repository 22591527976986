import React from "react";

export default function WhatisWeb3Wallet() {
  return (
    <>
      <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Web3 Wallet ?</h2>
              <p>
                A Web3 wallet is a decentralized digital wallet that allows
                users to securely store, manage, and interact with
                cryptocurrencies, tokens, and decentralized applications (dApps)
                on blockchain networks. Unlike traditional wallets, Web3 wallets
                give users full control over their private keys, enabling them
                to participate in decentralized finance (DeFi), non-fungible
                tokens (NFTs), and other blockchain-based ecosystems without
                relying on intermediaries.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
