import React from "react";

export default function NFTServices() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Functionalities of NFT Marketplace</h2>
            <p className="p-3">
              These functionalities collectively create a robust and
              user-friendly NFT marketplace that encourages participation from
              both creators and collectors, fostering a vibrant digital asset
              ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box-one">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/nft_dev/nft-m/nft_minting.svg"
                      alt="nft_minting"
                    />
                  </div>
                  <h3>NFT Minting</h3>
                  <p>
                    Users can create and mint their own NFTs directly on the
                    platform. This functionality allows creators to upload their
                    digital assets, set attributes, and establish ownership
                    rights through customizable smart contracts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box-one">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/nft_dev/nft-m/listing_and_trading.svg"
                      alt="listing_and_trading"
                    />
                  </div>
                  <h3>Listing and Trading</h3>
                  <p>
                    The marketplace facilitates the listing and trading of NFTs,
                    providing users with an easy way to buy, sell, or auction
                    their digital assets. Sellers can create listings that
                    include descriptions, images, and pricing details, while
                    buyers can browse through various categories, filter
                    results, and place bids or make purchases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box-one">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/nft_dev/nft-m/wallet_integration.svg"
                      alt="wallet_integration"
                    />
                  </div>
                  <h3>Wallet Integration</h3>
                  <p>
                    Seamless wallet integration is crucial for managing digital
                    assets. The marketplace should support multiple
                    cryptocurrency wallets, allowing users to connect their
                    preferred wallet for storing, sending, and receiving NFTs
                    and cryptocurrencies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "380px" }}>
                  <div className="icon-box-one">
                    <img
                      style={{ width: "50px" }}
                      src="assets/img/nft_dev/nft-m/royalties_&_creator_earnings.svg"
                      alt="royalties_&_creator_earnings"
                    />
                  </div>
                  <h3>Royalties & Creator Earnings</h3>
                  <p>
                    An essential feature of NFT marketplaces is the ability to
                    implement royalty systems that ensure creators earn a
                    percentage from secondary sales of their NFTs. This
                    functionality allows artists and creators to benefit
                    financially as their work appreciates in value, promoting a
                    sustainable ecosystem for digital artists.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
