import React from "react";

export default function Layer2Hero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="polkadots-multi-chain-blockchain-hero-section"
        style={{
          backgroundImage:
            "url(assets/img/layer/polkadots-multi-chain-blockchain-hero-section.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mb_55">
                <h2 className="text-white">
                  Transform Your Business with Polkadot’s Multi-Chain Blockchain
                </h2>
                <p className="text-white pt-3">
                  Leverage the interoperability and scalability of Polkadot with
                  Xeer Technology’s customized blockchain development services
                  for a connected Web3 ecosystem.
                </p>
              </div>
              <div className="btn-box mt_40">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Hire Blockchain Expert
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
