import React from "react";

export default function CentralizedS() {
  return (
    <>
      <section className="contact-info-section centred py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="pb-3">
              What does the Crypto Derivatives Exchange offer
            </h2>
            <p>
              Here are six key functionalities offered by Crypto Derivatives
              Exchange;
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/futures_&_options_trading.svg"
                      alt="futures_&_options_trading"
                    />
                  </div>
                  <h3>Futures & Options Trading</h3>
                  <p>
                    Provides a variety of derivative products, such as futures
                    contracts and options (right to buy/sell without
                    obligation), allowing traders to speculate on crypto
                    investments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/perpetual_swaps.svg"
                      alt="perpetual_swaps"
                    />
                  </div>
                  <h3>Perpetual Swaps</h3>
                  <p>
                    Enables continuous trading of cryptocurrency contracts
                    without an expiration date, allowing traders to hold
                    positions indefinitely and benefit from price fluctuations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/leverage_trading.svg"
                      alt="leverage_trading"
                    />
                  </div>
                  <h3>Leverage Trading</h3>
                  <p>
                    Offers leveraged positions, allowing traders to borrow
                    capital to increase their exposure to the market, magnifying
                    both potential profits and risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/risk_management_tools.svg"
                      alt="risk_management_tools"
                    />
                  </div>
                  <h3>Risk Management Tools</h3>
                  <p>
                    Provides features like stop-loss orders, take-profit orders,
                    and margin calls to help users manage risk effectively and
                    minimize losses during volatile market conditions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/high_liquidity_&_low_slippage.svg"
                      alt="high_liquidity_&_low_slippage"
                    />
                  </div>
                  <h3>High Liquidity & Low Slippage</h3>
                  <p>
                    Ensures that there is sufficient liquidity to execute large
                    trades quickly with minimal price impact, reducing slippage
                    and providing better trade execution for users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="pb-3 ">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/dse/advanced_charting_&_analytics.svg"
                      alt="advanced_charting_&_analytics"
                    />
                  </div>
                  <h3>Advanced Charting & Analytics</h3>
                  <p>
                    Offers sophisticated tools for technical analysis, including
                    real-time data, customizable charts, and various indicators
                    to help traders make informed decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
