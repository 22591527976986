import React from "react";
import Slider from "react-slick";

export default function TechnologiesweWork() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="integrations-section centred pb_150 pt-5">
        <div className="">
          <div className="sec-title mb_60">
            <span className="sub-title-two">
              STANDING ON THE EDGE OF INNOVATION
            </span>
            <h2 className="pb-2">Blockchain Platforms We Work On</h2>
            <p>
              From layer 0 to layer 3, we take pride in our expertise across all
              blockchain layers. However, we particularly excel in a
              cryptocurrency trading software development on the <br />
              following blockchains:
            </p>
          </div>
          <div className="slider-containe">
            <Slider {...settings}>
              <div className="slider-bgimg-1" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/bnb.svg"
                    alt="bnb"
                  />
                  <h3>Binance</h3>
                </div>
              </div>
              <div className="slider-bgimg-2" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/cardano.svg"
                    alt="cardano"
                  />
                  <h3>CARDANO</h3>
                </div>
              </div>
              <div className="slider-bgimg-3" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/eth.svg"
                    alt="eth"
                  />
                  <h3>ETHEREUM</h3>
                </div>
              </div>
              <div className="slider-bgimg-4" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/hedera.svg"
                    alt="hedera"
                  />
                  <h3>HEDERA</h3>
                </div>
              </div>
              <div className="slider-bgimg-5" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/near.svg"
                    alt="near"
                  />
                  <h3>NEAR</h3>
                </div>
              </div>
              <div className="slider-bgimg-6" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/polkadot.svg"
                    alt="polkadot"
                  />
                  <h3>POLKADOT</h3>
                </div>
              </div>
              <div className="slider-bgimg-7" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/polygon.svg"
                    alt="polygon"
                  />
                  <h3>POLYGON </h3>
                </div>
              </div>
              <div className="slider-bgimg-8" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/sol.svg"
                    alt="sol"
                  />
                  <h3>SOLANA </h3>
                </div>
              </div>
              <div className="slider-bgimg-9" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/stellar.svg"
                    alt="stellar"
                  />
                  <h3>STELLAR</h3>
                </div>
              </div>
              <div className="slider-bgimg-10" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/ton1.svg"
                    alt="ton1"
                  />
                  <h3>TON </h3>
                </div>
              </div>
              <div className="slider-bgimg-11" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/tron.svg"
                    alt="tron"
                  />
                  <h3>TRON</h3>
                </div>
              </div>
              <div className="slider-bgimg-12" aria-label="shape">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/icon/icon2/waves.svg"
                    alt="waves"
                  />
                  <h3>WAVES </h3>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
