import React from "react";

export default function CosmosSDKlaunch() {
  return (
    <>
      <section className="feature-section py_50">
        <div className="auto-container">
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="pb-4">
                    Why Xeer Technology for Cosmos SDK Development?
                  </h2>
                  <p>
                    Xeer Technology brings a wealth of expertise in blockchain
                    solutions to deliver top-notch Cosmos SDK services. With a
                    client-centric approach, we focus on building scalable,
                    high-performance applications that cater to diverse
                    industries, ensuring your project achieves optimal success
                    in the decentralized space.
                  </p>
                </div>
                <div className="text-box">
                  <p>
                    Partner with Xeer Technology for reliable, customized Cosmos
                    SDK development and join the growing network of Cosmos-based
                    projects transforming industries globally.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt="why_choose_xeer_technology_for_margin_trading"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
