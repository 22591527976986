import React from "react";

export default function ByHarnessing() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_40 pb_40 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="">
            <h2 className="text-white">
              Unlock the full potential of your digital assets with Xeer
              Technology's market making expertise with enhancing liquidity,
              stability, and watch your crypto project thrive!
            </h2>
          </div>
          <div className="p-3">
            <a href="/contact-us" className="theme-btn btn-one m_20">
              Connect Now
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
