import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import WhiteLabelHero from "./WhiteLabelHero";
import LookingD from "../About-Us/LookingD";
import DidYouKnow from "./DidYouKnow";
import MarketReady from "./MarketReady";
import KeyFeatures from "./KeyFeatures";
import SecurityWLabel from "./SecurityWLabel";
import CloneTheSuccess from "./CloneTheSuccess";
import OurBestSelling from "./OurBestSelling";
import ReadyToLaunch from "./ReadyToLaunch";
import { Helmet } from "react-helmet";

export default function WhiteLabelExchange() {
  return (
    <>
      <Helmet>
        <title>
          White Label Exchange Solutions | Launch Fast with Xeer Technology
        </title>
        <meta
          name="description"
          content="Launch your cryptocurrency exchange quickly with Xeer’s white-label solutions. Fully customizable and market-ready platforms."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <WhiteLabelHero />
        <DidYouKnow />
        <MarketReady />
        <ReadyToLaunch />
        <KeyFeatures />
        <CloneTheSuccess />
        <SecurityWLabel />
        <OurBestSelling />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
