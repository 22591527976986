import React from "react";

export default function Resource() {
  return (
    <>
      <section className="feature-section  py_80">
        <div className="sec-title text-center pb-5">
          <h2>Crypto Gaming App Development by Xeer Technology</h2>
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ps-md-5 ms-md-5">
                <img
                  style={{ width: "400px" }}
                  src="assets/img/crypto-g-dev/empowering-the-next-generation-of-gaming.png"
                  alt="empowering-the-next-generation-of-gaming"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box">
                <div className="sec-title mb_25">
                  <h2>Empowering the Next Generation of Gaming</h2>
                </div>
                <div className="text-box">
                  <p>
                    Xeer Technology offers comprehensive Crypto Gaming App
                    Development solutions, enabling developers and businesses to
                    harness the potential of blockchain in gaming. We design and
                    build cutting-edge gaming applications that integrate
                    features like play-to-earn models, NFTs, and decentralized
                    economies, creating engaging and profitable experiences for
                    players worldwide.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
