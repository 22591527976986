import React from "react";

export default function Features() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>STO Development Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  {/* icon-box */}
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/securitization/svg/token_creation_issuance.svg"
                      alt="token_creation_issuance"
                    />
                  </div>
                  <h3>Token Creation & Issuance</h3>
                  <p>
                    Launch custom security tokens on your preferred blockchain
                    (Ethereum, Tezos, Polkadot), ensuring tokens align with
                    regulatory requirements and provide full asset control.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/securitization/svg/regulatory_compliance.svg"
                      alt="regulatory_compliance"
                    />
                  </div>
                  <h3>Regulatory Compliance</h3>
                  <p>
                    We provide KYC/AML integration, regulatory guidance, and
                    jurisdictional compliance for legally sound STOs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/securitization/svg/investor_management_platform.svg"
                      alt="investor_management_platform"
                    />
                  </div>
                  <h3>Investor Management Platform</h3>
                  <p>
                    Build a seamless investor onboarding experience with
                    dashboards, automated KYC, and real-time reporting on token
                    performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/securitization/svg/secondary_market_integration.svg"
                      alt="secondary_market_integration"
                    />
                  </div>
                  <h3>Secondary Market Integration</h3>
                  <p>
                    Enable token liquidity with seamless integration into
                    approved secondary markets and exchanges for easy trading
                    post-STO.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/securitization/svg/token_marketing_outreach.svg"
                      alt="token_marketing_outreach"
                    />
                  </div>
                  <h3>Token Marketing & Outreach</h3>
                  <p>
                    Our STO experts help you build trust with potential
                    investors through focused outreach and digital marketing
                    strategies that enhance your offering's visibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
