import React from "react";

export default function Hassle() {
  return (
    <>
      <section className="contact-info-section centred  py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Benefits of Partnering With Xeer Technology for NFT Collection
              Launch
            </h2>
            <p className="pt-2">
              Partnering with Xeer Technology for your NFT collection launch
              offers a range of advantages that ensure your project stands out
              in the competitive digital space. Our end-to-end development
              process covers everything from initial concept to post-launch
              support, ensuring a smooth and successful launch.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div
                  className="inner-box text-start"
                  style={{ height: "350px" }}
                >
                  <div className="num-box pb-3">
                    <h1>01</h1>
                  </div>
                  <h3>End-to-End Development</h3>
                  <p>
                    From conceptualizing your NFT collection to the technical
                    launch and post-launch support, we handle every aspect of
                    the development process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div
                  className="inner-box text-start"
                  style={{ height: "350px" }}
                >
                  <div className="num-box pb-3">
                    <h1>02</h1>
                  </div>
                  <h3>Creative & Technical Expertise</h3>
                  <p>
                    Our team consists of experienced blockchain developers and
                    talented digital artists who bring innovation and creativity
                    to your NFT collection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div
                  className="inner-box text-start"
                  style={{ height: "350px" }}
                >
                  <div className="num-box pb-3">
                    <h1>03</h1>
                  </div>
                  <h3>User-centric Approach</h3>
                  <p>
                    We focus on user-friendly design, ensuring that both
                    creators and buyers can engage with your collection
                    effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
