import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import ExchangeHero from "./ExchangeHero";
import InvestCrypto from "./InvestCrypto";
import Decade from "./Decade";
import Delivering from "./Delivering";
import Cryptocurrency from "./Cryptocurrency";
import BoostBottom from "./BoostBottom";
import TypesCrypto from "./TypesCrypto";
import LookingD from "../About-Us/LookingD";
import TechnologiesweWork from "../Home/TechnologiesweWork";
import { Helmet } from "react-helmet";

export default function Exchange() {
  return (
    <>
      <Helmet>
        <title>A Custom Crypto Exchange Development | Xeer Technology</title>
        <meta
          name="description"
          content=" Build secure and scalable cryptocurrency exchanges with Xeer Technology. From spot trading to advanced features, we cater to your needs."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <ExchangeHero />
        <InvestCrypto />
        <Decade />
        <Delivering />
        <Cryptocurrency />
        <BoostBottom />
        <TypesCrypto />
        <TechnologiesweWork />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
