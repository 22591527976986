import React from "react";

import BlockchainGameDevelopmentHero from "./BlockchainGameDevelopmentHero";
import CryptoGameDevelopment from "./CryptoGameDevelopment";
import Web3GameDevelopment from "./Web3GameDevelopment";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Header from "../../Coman/Header";
import { Helmet } from "react-helmet";

export default function BlockchainGameDevelopment() {
  return (
    <>
      <Helmet>
        <title>Blockchain Game Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Transform gaming with blockchain. Xeer creates secure, engaging, and scalable blockchain-based gaming platforms with NFTs and in-game assets."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <BlockchainGameDevelopmentHero />
        <CryptoGameDevelopment />
        <Web3GameDevelopment />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
