import React from "react";

export default function Harness() {
  return (
    <>
      <section className="contact-info-section centred  py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="pb-3">
              Key Features Of Arbitrage Crypto Trading Bot
            </h2>
            <p>
              Arbitrage crypto trading bots help traders automate the process of
              profiting from price inefficiencies in the market.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "331px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/atb/automation.svg"
                      alt="automation"
                    />
                  </div>
                  <h3>Automation</h3>
                  <p>
                    The bot automatically executes buy and sell orders across
                    multiple exchanges based on predefined strategies,
                    minimizing the need for manual intervention.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "331px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/atb/real_time_monitoring.svg"
                      alt="real_time_monitoring"
                    />
                  </div>
                  <h3>Real-Time Monitoring</h3>
                  <p>
                    It constantly monitors cryptocurrency prices across
                    different exchanges in real-time to identify arbitrage
                    opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/atb/speed.svg"
                      alt="speed"
                    />
                  </div>
                  <h3>Speed</h3>
                  <p>
                    Since the price differences may only exist for a short time,
                    arbitrage bots are designed to execute trades quickly,
                    taking advantage of the time-sensitive nature of these
                    opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/atb/exchange_connectivity.svg"
                      alt="exchange_connectivity"
                    />
                  </div>
                  <h3>Exchange Connectivity</h3>
                  <p>
                    Arbitrage bots are usually connected to several
                    cryptocurrency exchanges via APIs to ensure they can access
                    price data and execute trades efficiently.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/atb/risk_management.svg"
                      alt="risk_management"
                    />
                  </div>
                  <h3>Risk Management</h3>
                  <p>
                    Advanced bots incorporate risk management tools to handle
                    potential issues like price slippage, transaction fees, and
                    withdrawal limits that could reduce profitability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
