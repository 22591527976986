import React from "react";
import NFTartH from "./NFTartH";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import ArtMarkCon from "./ArtMarkCon";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function NFTART() {
  return (
    <>
      <Helmet>
        <title>DeFi Smart Contract Development | Xeer Technology</title>
        <meta
          name="description"
          content="Build decentralized finance solutions with secure smart contracts. Xeer specializes in lending, staking, and yield farming dApps."
        />
      </Helmet>
      <div>
        <Header />
        <NFTartH />
        <ArtMarkCon />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
