import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function LookingD() {
  return (
    <>
      <section className="cta-section">
        <div className="bg-color" />
        <div className="auto-container">
          <div className="inner-container pb-5 pb-md-0">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-23.png)",
                }}
              />
              <div
                className="pattern-2 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-24.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-25.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-26.png)",
                }}
              />
            </div>
            <div className="inner-box">
              <div className="py-5">
                <h2>Looking for development or collabration?</h2>
                <br />
                <p className="text-white">
                  Unlock the full potential of blockchain technology and joint
                  knowledge by requesting a price or calling us today.
                </p>
              </div>
             
              <TalkToOurTeamBTN/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
