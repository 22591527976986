import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function ExchangeHero() {
  return (
    <>
      <section
        className="banner-style-six py_150"
        aria-label="Launch-your-own-Crypto-Exchange-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Launch-your-own-Crypto-Exchange-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-4">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30">
                <div data-animation-box="true" className="sec-title my_25">
                  <h2 className="text-white">
                    Launch your own Crypto Exchange
                  </h2>
                </div>
                <p className="text-white ">
                  We will design & develop a customized cryptocurrency exchange
                  for you with all essential features like spot trading, account
                  wallets, p2p window, user dashboard, admin panel & more.
                </p>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 "></div>
          </div>
        </div>
      </section>
    </>
  );
}
