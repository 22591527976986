import React from "react";

export default function Finance() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Crypto Gaming Industry Insight</h2>
              <p>
                The crypto gaming industry is rapidly transforming traditional
                gaming by integrating blockchain technology, creating new
                opportunities for players and developers alike. With the market
                expected to reach over $65 billion by 2027, play-to-earn models
                and NFTs are leading the way, allowing players to own, trade,
                and earn real value from in-game assets. In 2023 alone,
                blockchain games accounted for nearly 50% of all blockchain
                activity, attracting millions of users worldwide. This shift
                toward decentralized ownership and monetization is driving
                massive growth, positioning crypto gaming as a major force in
                the digital economy.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
