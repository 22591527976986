import React from "react";

export default function YourSecurityTO() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer for Your Security Token Offering?</h2>
              <p>
                Our approach ensures that your tokenization process meets
                regulatory standards and offers maximum security. With a team of
                blockchain and legal experts, we deliver compliant, end-to-end
                STO solutions tailored to your asset type, business needs, and
                investor goals.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
