import React from "react";

export default function SmartContractAuditHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="comprehensive-smart-contract-audits-for-complete-assurance"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/smart-con/comprehensive-smart-contract-audits-for-complete-assurance.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Comprehensive Smart Contract Audits for Complete Assurance
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Ensure the highest security and efficiency of your
                    blockchain applications with Xeer Technology’s expert audit
                    services. Our rigorous audits bring reliability,
                    transparency, and trust to your DeFi ecosystem.
                  </p>
                </div>
              </div>
              <a href="/contact-us" className="theme-btn btn-one m_20">
                Get Your Audit Today
              </a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
