import React from "react";

export default function PolygonEdgeHero() {
  return (
    <div>
      <section
        className="feature-section py_150"
        aria-label="Unlock-Scalable-Blockchain-Solutions-with-Polygon-EVM-Hero-Section-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/framework/Unlock-Scalable-Blockchain-Solutions-with-Polygon-EVM-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">
                    Unlock Scalable Blockchain Solutions with Polygon zkEVM
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology leverages Polygon zkEVM to deliver scalable,
                    secure, and high-performance blockchain solutions tailored
                    for modern enterprises.
                  </p>
                </div>
                <div className="btn-box">
                  <a href="/contact-us" className="theme-btn btn-one mr_20">
                    Hire Blockchain Expert
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
