import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import CoinDevelopmentHero from "./CoinDevelopmentHero";
import LookingD from "../About-Us/LookingD";
import BringsNext from "./BringsNext";
import CanYouBelieve from "./CanYouBelieve";
import Advisory from "./Advisory";
import Multiple from "./Multiple";
import Mastering from "./Mastering";
import { Helmet } from "react-helmet";

export default function CoinDevelopment() {
  return (
    <>
      <Helmet>
        <title>Cryptocurrency Token Development | Xeer Technology</title>
        <meta
          name="description"
          content="Create secure and scalable tokens with Xeer Technology. Expertise in ERC, BEP, and TRC standards for seamless blockchain integration."
        />
      </Helmet>
      <div>
        <Header />
        <CoinDevelopmentHero />
        <BringsNext />
        <Advisory />
        <Multiple />
        <CanYouBelieve />
        <Mastering />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
