import React from "react";

export default function StatisticsDYK() {
  return (
    <>
      <section className="glance-section centred pb_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is an Arbitrage Trading Bot?</h2>
              <p>
                An arbitrage crypto trading bot is an automated software tool
                designed to take advantage of price differences for the same
                cryptocurrency on different exchanges. Arbitrage opportunities
                arise when a cryptocurrency is priced higher on one exchange
                than on another. The bot identifies these price discrepancies,
                buys the crypto at a lower price from one exchange, and sells it
                at a higher price on another, earning a profit from the
                difference.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
