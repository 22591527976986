import React from "react";

export default function Web3GameDevelopment() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <div data-animation-box className="sec-title mt_55">
              <h2 className="text-center">
                Benefits of Blockchain Game Development
              </h2>
            </div>
            <div className="row clearfix pt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="">
                        <img
                          style={{ width: "80px" }}
                          src="assets/img/blockchain-dev/SVG/enhanced_security_and_transparency.svg"
                          alt="enhanced_security_and_transparency"
                        />
                      </div>
                    </div>

                    <h5 className="py-3">Enhanced Security and Transparency</h5>
                    <p>
                      Blockchain technology offers decentralized ownership,
                      secure transactions, and transparent operations, reshaping
                      gaming economies and enhancing user trust.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="">
                        <img
                          style={{ width: "80px" }}
                          src="assets/img/blockchain-dev/SVG/monetization_opportunities.svg"
                          alt="monetization_opportunities"
                        />
                      </div>
                    </div>
                    <h5 className="py-3">Monetization Opportunities</h5>
                    <p>
                      Blockchain gaming enables revenue through transaction
                      fees, item trades, royalties, and subscriptions, creating
                      a sustainable, monetized ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="">
                        <img
                          style={{ width: "80px" }}
                          src="assets/img/blockchain-dev/SVG/revenue_management.svg"
                          alt="revenue_management"
                        />
                      </div>
                    </div>
                    <h5 className="py-3">
                      Efficient Micro-Payments and Revenue Management
                    </h5>
                    <p>
                      Enable fast, low-cost micro-transactions to add real-world
                      value to games. Subscription options, spending limits, and
                      minimal fees enhance monetization without impacting the
                      gaming experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="">
                        <img
                          style={{ width: "80px" }}
                          src="assets/img/blockchain-dev/SVG/reduced_revenue_losses.svg"
                          alt="reduced_revenue_losses"
                        />
                      </div>
                    </div>
                    <h5 className="py-3">Reduced Revenue Losses</h5>
                    <p>
                      Blockchain’s immutability minimizes chargeback losses and
                      fraudulent transactions, allowing developers to secure
                      their earnings effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="">
                        <img
                          style={{ width: "80px" }}
                          src="assets/img/blockchain-dev/SVG/game_currency.svg"
                          alt="game_currency"
                        />
                      </div>
                    </div>
                    <h5 className="py-3">In-Game Currency</h5>
                    <p>
                      With blockchain, transactions are faster, safer, and more
                      economical. Players control their virtual assets, stored
                      directly in their wallets, creating a self-sustained
                      economy within the game.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
