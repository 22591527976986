import React from "react";

export default function Empowering() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <h1 className="text-center p-3">Type of Crypocurency Wallets</h1>
            <p className="text-center">
              Cryptocurrency wallets are mainly of three types; web wallets,
              mobile wallets and desktop wallets.
            </p>
            <div className="row clearfix ">
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one ">
                  <div className="inner-box">
                    <div className="">
                      <img
                        src="assets/img/web_wallets.svg"
                        alt="web_wallets"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <h3>Web Wallets</h3>
                    <p>Accessible through a browser, no download required.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one ">
                  <div className="inner-box">
                    <div className="">
                      <img
                        src="assets/img/mobile_wallets.svg"
                        alt="mobile_wallets"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <h3>Mobile Wallets</h3>
                    <p> Smartphone apps designed for on-the-go usage.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one ">
                  <div className="inner-box">
                    <div className="">
                      <img
                        src="assets/img/desktop_wallets.svg"
                        alt="desktop_wallets"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <h3>Desktop Wallets</h3>
                    <p>
                      Software installed on a personal computer, offering a
                      balance of convenience and security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
