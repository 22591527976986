import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SupplyChainHero from "./SupplyChainHero";
import BlockchainSupply from "./BlockchainSupply";
import TraditionalSupply from "./TraditionalSupply";
import SupplyChainSolutions from "./SupplyChainSolutions";
import BlockchaininSupply from "./BlockchaininSupply";
import { Helmet } from "react-helmet";

export default function SupplyChain() {
  return (
    <>
      <Helmet>
        <title>TON Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Leverage TON for high-speed blockchain applications. Xeer builds custom dApps and smart contracts on the TON ecosystem."
        />
      </Helmet>
      <div>
        <Header />
        <SupplyChainHero />
        <BlockchainSupply />
        <SupplyChainSolutions />
        <TraditionalSupply />
        <BlockchaininSupply />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
