import React from "react";
import Header from "../Coman/Header";
import DefiDevelopmentHero from "./DefiDevelopmentHero";
import Footar from "../Coman/Footar";
import TheParadigm from "./TheParadigm";
import DevGlobalS from "./DevGlobalS";
import TopTierDeFi from "./TopTierDeFi";
import Simplification from "./Simplification";
import AssetManagement from "./AssetManagement";
import BusinessGoals from "./BusinessGoals";
import ByPartnering from "./ByPartnering";
import Blog from "../Home/Blog";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function DefiDevelopment() {
  return (
    <>
      <Helmet>
        <title>DeFi Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Transform your business with decentralized finance solutions. Xeer Technology specializes in DeFi apps, wallets, and staking platforms."
        />
      </Helmet>
      <div>
        <Header />
        <DefiDevelopmentHero />
        <TheParadigm />
        <DevGlobalS />
        <TopTierDeFi />
        <Simplification />
        <AssetManagement />
        <BusinessGoals />
        {/* <Blog/> */}
        <ByPartnering />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
