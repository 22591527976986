import React from "react";

export default function TopSolutions() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Bitcoin Wallet Offered by Xeer Technology</h2>
            <p className="pt-2">
              Xeer Technology provides a Bitcoin wallet solution that combines
              security, usability, and cutting-edge technology. Below are the{" "}
              <br />
              key features that make our wallets stand out:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/multi_signature_support.svg"
                      alt="multi_signature_support"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Multi-Signature Support</h3>
                  <p>
                    Enhance the security of your Bitcoin wallet with
                    multi-signature functionality, which requires multiple
                    private keys to authorize a transaction. This adds an extra
                    layer of protection, particularly for business or group
                    transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/full_control_of_private_keys.svg"
                      alt="full_control_of_private_keys"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Full Control of Private Keys</h3>
                  <p>
                    Xeer Technology ensures you have full control over your
                    private keys, empowering you with complete ownership and
                    access to your Bitcoin. Your keys remain encrypted and never
                    leave your device, providing maximum security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/lightning_network_integration.svg"
                      alt="lightning_network_integration"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Lightning Network Integration</h3>
                  <p>
                    Our wallets support the Lightning Network, enabling faster
                    and low-fee Bitcoin transactions. Ideal for users who
                    frequently conduct small, everyday transactions with
                    near-instant settlement times.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/segwit_and_bech_compatibility.svg"
                      alt="segwit_and_bech_compatibility"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>SegWit and Bech32 Compatibility</h3>
                  <p>
                    Enjoy lower transaction fees and faster confirmations with
                    support for SegWit and Bech32 wallets, which optimize
                    Bitcoin transactions and improve scalability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/advanced_security_features.svg"
                      alt="advanced_security_features"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Advanced Security Features</h3>
                  <p>
                    Xeer wallets come with built-in security measures like
                    two-factor authentication (2FA), biometric login options,
                    and encryption, protecting your assets from unauthorized
                    access or malicious threats.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "390px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/cross_platform_syncing.svg"
                      alt="cross_platform_syncing"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Cross-Platform Syncing</h3>
                  <p>
                    Our Bitcoin wallets work seamlessly across multiple
                    platforms, including web, mobile, and desktop, allowing you
                    to manage your assets from any device with real-time
                    syncing.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/transaction_tracking.svg"
                      alt="transaction_tracking"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3>Real-Time Transaction Tracking</h3>
                  <p>
                    Stay updated with real-time tracking of your Bitcoin
                    transactions. Our wallet provides detailed transaction
                    history and monitoring tools to keep you informed every step
                    of the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/backup_and_recovery_options.svg"
                      alt="backup_and_recovery_options"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <h3> Backup and Recovery Options</h3>
                  <p>
                    Easily backup your wallet with secure recovery options, such
                    as seed phrases and encrypted backups, to ensure that your
                    Bitcoin can be restored in case of device loss or failure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
