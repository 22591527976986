import React from "react";

export default function WhatOrderDex() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Order Book DEX Developed by Xeer Technology</h2>
            <p>
              Our custom Order Book DEX solutions provide users a seamless
              trading experience while ensuring security, transparency, and
              decentralization!
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/margin_spot_trading.svg"
                      alt="margin_spot_trading"
                    />
                  </div>
                  <h3>Traditional Order Book Functionality</h3>
                  <p>
                    Our DEXs mimic traditional exchange mechanisms, allowing
                    users to place limit orders, market orders, and stop-loss
                    orders, enhancing trading flexibility and user control.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/real_time_monitoring.svg"
                      alt="real_time_monitoring"
                    />
                  </div>
                  <h3>Real-Time Order Matching</h3>
                  <p>
                    With efficient order matching algorithms, our Order Book
                    DEXs ensure fast and reliable execution of trades,
                    minimizing latency and providing users with a seamless
                    trading experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/se/user_friendly_interface.svg"
                      alt="user_friendly_interface"
                    />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    We design intuitive user interfaces that simplify the
                    trading process, making it easy for both novice and
                    experienced traders to navigate the platform and execute
                    trades.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/multi_chain.svg"
                      alt="multi_chain"
                    />
                  </div>
                  <h3>Cross-Chain Compatibility</h3>
                  <p>
                    Our Order Book DEXs can facilitate trades across multiple
                    blockchain networks, allowing users to trade a wide range of
                    assets and improving liquidity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/functionality.svg"
                      alt="functionality"
                    />
                  </div>
                  <h3>Advanced Trading Features</h3>
                  <p>
                    We incorporate features such as limit and stop orders, order
                    history, and trade analytics to provide users with
                    comprehensive tools for effective trading strategies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "310px" }}>
                  <div className="pb-3">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/defi/defi-y-f/liquidity_&_incentives.svg"
                      alt="liquidity_&_incentives"
                    />
                  </div>
                  <h3>Liquidity Incentives</h3>
                  <p>
                    To attract liquidity providers, our DEXs offer rewards for
                    market makers and liquidity suppliers, enhancing liquidity
                    depth and improving trading conditions for all users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
