import React from "react";

export default function AMMSolution() {
  return (
    <>
      <section className="about-style-three py_100">
        <div className="auto-container">
          <div className="sec-title mb_50 text-center">
            <h2>AMM DEX Development by Xeer Technology</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box ms-md-5">
                  <figure className="image ps-md-5">
                    <img
                      style={{ width: "400px" }}
                      src="assets/img/dex/Transform-Your-Trading-Experience-with-Automated-Market-Makers-Section-Image.png"
                      alt="Transform-Your-Trading-Experience-with-Automated-Market-Makers-Section-Image"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="sec-title mb_25">
                    <h1>
                      Transform Your Trading Experience with Automated Market
                      Makers
                    </h1>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      At Xeer Technology, we specialize in developing
                      cutting-edge Automated Market Maker (AMM) Decentralized
                      Exchanges (DEXs) that redefine the way users trade and
                      provide liquidity in the decentralized finance (DeFi)
                      ecosystem. Our AMM DEX solutions leverage innovative
                      algorithms and blockchain technology to facilitate
                      seamless trading, enabling users to swap tokens directly
                      against liquidity pools without relying on traditional
                      order books.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
