import React from "react";

export default function InvestCrypto() {
  return (
    <>
      <section className="about-style-three py_100">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Why build your own crypto exchange?</h2>
            <p className="p-3">
              The cryptocurrency adoption is increasing rapidly with millions of
              new users signing-up at crypto exchanges regularly. With this much
              exposure and potential for wider expansion, launching a crypto
              exchange seems feasible in the ever-increasing market demand.
            </p>
          </div>
          <div className="content_block_four">
            <div className="content-box ml_40">
              <div className="inner-box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-item my_10">
                      <div className="icon-box">
                        <img
                          src="assets/img/exchange/svg/crypto_adoption_growth_rate.svg"
                          alt="crypto_adoption_growth_rate"
                        />
                      </div>
                      <h4>Crypto Adoption Growth Rate (2024)</h4>
                      <p className="pt-2">
                        Global crypto adoption is expected to grow by 13-15% in
                        2024, with an estimated 500 million users globally by
                        the end of the year.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/exchange/svg/investments_in_crypto_startups.svg"
                          alt="investments_in_crypto_startups"
                        />
                      </div>
                      <h4>Investments in Crypto Startups</h4>
                      <p className="pt-2">
                        Venture capital investment in cryptocurrency and
                        blockchain startups is projected to surpass $30 billion
                        in 2024, fueled by growing institutional interest and
                        innovations in decentralized finance (DeFi) and
                        blockchain technology.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/exchange/svg/crypto_exchange_earnings.svg"
                          alt="crypto_exchange_earnings"
                        />
                      </div>
                      <h4>Crypto Exchange Earnings</h4>
                      <p className="pt-2">
                        Major crypto exchanges like Binance, Coinbase, and
                        Kraken are projected to earn a combined total of $15-20
                        billion in revenue by the end of 2024, driven by
                        increased trading volumes and expanded services.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/exchange/svg/total_cryptocurrency_market_value.svg"
                          alt="total_cryptocurrency_market_value"
                        />
                      </div>
                      <h4>Total Cryptocurrency Market Value</h4>
                      <p className="pt-2">
                        The global market capitalization of cryptocurrencies is
                        projected to reach $2.5 trillion by the end of 2024,
                        with Bitcoin and Ethereum maintaining dominance but
                        increasing competition from newer blockchains and
                        altcoins.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img
                          src="assets/img/exchange/svg/institutional_adoption.svg"
                          alt="institutional_adoption"
                        />
                      </div>
                      <h4>Institutional Adoption</h4>
                      <p className="pt-2">
                        Over 40% of institutional investors are expected to have
                        exposure to cryptocurrency assets in 2024, showing
                        increasing mainstream acceptance of digital currencies
                        as part of investment portfolios.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
