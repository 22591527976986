import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SmartContractAuditHero from "./SmartContractAuditHero";
import TheHighlights from "./TheHighlights";
import SoliditySmartCon from "./SoliditySmartCon";
import { Helmet } from "react-helmet";

export default function SmartContractAudit() {
  return (
    <>
      <Helmet>
        <title>Smart Contract Audit Services | Xeer Technology</title>
        <meta
          name="description"
          content=" Ensure the security and efficiency of your smart contracts with Xeer. Comprehensive audits to safeguard your blockchain applications."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <SmartContractAuditHero />
        <TheHighlights />
        <SoliditySmartCon />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
