import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import TezosHero from "./TezosHero";
import TezosLeads from "./TezosLeads ";
import HowtoGetTez from "./HowtoGetTez";
import SmartContracts from "./SmartContracts";
import { Helmet } from "react-helmet";

export default function Tezos() {
  return (
    <>
      <Helmet>
        <title>Tezos Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Harness the power of Tezos for secure and decentralized solutions. Xeer builds scalable dApps and smart contracts on the Tezos network."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <TezosHero />
        <TezosLeads />
        <HowtoGetTez />
        <SmartContracts />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
