import React from "react";

export default function HowSecure() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>NFT Wallet Development Service</h2>
              <p>
                Xeer Technology offers a comprehensive NFT Wallet Development
                service designed to provide users with a seamless, secure, and
                scalable experience for managing their digital assets. Our
                expert team builds custom NFT wallets that support multiple
                blockchain networks, including Ethereum, Solana, and others,
                ensuring compatibility with a wide range of decentralized
                applications (dApps).
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
