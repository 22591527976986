import React from "react";

import BlockchainDevelopmentHero from "./BlockchainDevelopmentHero";
import DevelopmentSolutions from "./DevelopmentSolutions";
import BlockchainMarketForecast from "./BlockchainMarketForecast";
import BlockchainDevelopmentServices from "./BlockchainDevelopmentServices";

import BlockchainFrameworks from "./BlockchainFrameworks";
import BlockchainDevelopmentPlatforms from "./BlockchainDevelopmentPlatforms";
import Footar from "../../Coman/Footar";
import LookingD from "../../About-Us/LookingD";
import Header from "../../Coman/Header";
import { Helmet } from "react-helmet";

export default function BlockchainDevelopment() {
  return (
    <>
      <Helmet>
        <title>Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Build transformative blockchain solutions with Xeer Technology. Expertise in custom dApp, smart contract, and protocol development."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <BlockchainDevelopmentHero />
        <DevelopmentSolutions />
        <BlockchainMarketForecast />
        <BlockchainDevelopmentServices />
        <BlockchainFrameworks />
        <BlockchainDevelopmentPlatforms />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
