import React from "react";
import Header from "../Coman/Header";
import BitcoinWalletHero from "./BitcoinWalletHero";
import Footar from "../Coman/Footar";
import TopSolutions from "./TopSolutions";
import AdvancedBitcoin from "./AdvancedBitcoin";
import SecurelyStore from "./SecurelyStore";
import LookingD from "../About-Us/LookingD";
import BitcoinWDevService from "./BitcoinWDevService";
import GetAQuote from "./GetAQuote";
import { Helmet } from "react-helmet";

export default function BitcoinWallet() {
  return (
    <>
      <Helmet>
        <title>Bitcoin Wallet Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Build secure Bitcoin wallets with Xeer Technology. Advanced encryption for safe and seamless transactions."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <BitcoinWalletHero />
        <BitcoinWDevService />
        <TopSolutions />
        <AdvancedBitcoin />
        <GetAQuote />
        <SecurelyStore />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
