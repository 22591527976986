import React from "react";

export default function Partner() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">
              Partner with Xeer Technology for Web3 Innovation
            </h1>
            <p className="py-3 text-white">
              Whether you’re a startup or an established studio, Xeer
              Technology’s expertise in Web3 game development can elevate your
              project, combining blockchain innovation with engaging gameplay to
              captivate modern players.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
