import React from "react";

export default function ICOLaunchpad() {
  return (
    <>
      <section className="about-style-three pb_150">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-67.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25">
            <h2 className="text-center">
              Key Highlights of our Crypto Launchpad Development Service
            </h2>
            <p className="text-center pt-3">
              Following features of crypto launchpad developed by us creates a
              reliable, secure, and scalable launchpad platform, providing both
              project teams and investors with a comprehensive ecosystem for
              successful token launches.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="inner-box">
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/multi_chain_staking_support.svg"
                          alt="multi_chain_staking_support"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Multi-Chain Compatibility</h3>
                      <p>
                        Supports multiple blockchains (e.g., Ethereum, Binance
                        Smart Chain, Solana) to allow projects to launch tokens
                        on the network that best suits their needs.
                      </p>
                    </div>
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/secure_smart_contracts.svg"
                          alt="secure_smart_contracts"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Secure Smart Contracts</h3>
                      <p>
                        Custom-built, audited smart contracts that ensure
                        secure, transparent, and automated token sales, fund
                        allocation, and distribution processes.
                      </p>
                    </div>
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/aml_compliance.svg"
                          alt="aml_compliance"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>KYC/AML Compliance</h3>
                      <p>
                        Integrated KYC and AML checks to verify investor
                        identities and maintain regulatory compliance, fostering
                        a secure environment for all participants.
                      </p>
                    </div>
                    <div className="single-item">
                      <div className="icon-box">
                        <img
                          src="assets/img/investments_in_crypto_startups.svg"
                          alt="investments_in_crypto_startups"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Tiered Investment Levels</h3>
                      <p>
                        Allows for tier-based access to token sales, offering
                        different levels of investment options and perks based
                        on user participation and loyalty.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="inner-box">
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/project_verification_&_onboarding.svg"
                          alt="project_verification_&_onboarding"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Project Verification & Onboarding</h3>
                      <p>
                        Comprehensive onboarding tools for project teams,
                        including automated verification and due diligence to
                        maintain a high standard of quality for listed projects.
                      </p>
                    </div>
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/real_time_analytics_&_reporting.svg"
                          alt="real_time_analytics_&_reporting"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Real-Time Analytics & Reporting</h3>
                      <p>
                        Detailed insights into platform performance, investor
                        engagement, and fundraising milestones, allowing both
                        admins and project teams to monitor success in real
                        time.
                      </p>
                    </div>
                    <div className="single-item mb_40">
                      <div className="icon-box">
                        <img
                          src="assets/img/user_friendly_interface_and_customizable_dashboards.svg"
                          alt="user_friendly_interface_and_customizable_dashboards"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>User-Friendly Dashboard</h3>
                      <p>
                        An intuitive, all-in-one dashboard for investors and
                        project teams, simplifying navigation, token sale
                        participation, fund tracking, and project management.
                      </p>
                    </div>
                    <div className="single-item">
                      <div className="icon-box">
                        <img
                          src="assets/img/community_building.svg"
                          alt="community_building"
                          style={{ width: "50px" }}
                        />
                      </div>
                      <h3>Community Building & Marketing Support</h3>
                      <p>
                        Tools for building a strong community presence,
                        including whitelist management, promotional options, and
                        integration with social media channels to maximize
                        engagement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
