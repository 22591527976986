import React from "react";
import Header from "../Coman/Header";
import DefiWalletHero from "./DefiWalletHero";
import Footar from "../Coman/Footar";
import Remunerative from "./Remunerative";
import Comprehensive from "./Comprehensive";
import SaveBig from "./SaveBig";
import Render from "./Render";
import Fintech from "./Fintech";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function DefiWallet() {
  return (
    <>
      <Helmet>
        <title>DeFi Wallet Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Enable decentralized finance with Xeer's DeFi wallet solutions. Empower users with secure, seamless, and scalable wallet platforms."
        />
      </Helmet>
      <div>
        <Header />
        <DefiWalletHero />
        <Remunerative />
        <Comprehensive />
        <SaveBig />
        <Fintech />
        <Render />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
