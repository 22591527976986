import React from "react";

export default function WhyYield() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology For Yield Farming Platform Development?
            </h2>
            <p className="p-3">
              Xeer Technology stands out as a trusted partner for yield farming
              platform development due to our deep expertise in decentralized
              finance (DeFi) and our commitment to delivering secure, scalable,
              and innovative solutions. We tailor platforms to your specific
              needs, incorporating advanced features like automated smart
              contracts, multi-asset support, and cross-chain compatibility.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain_development.svg"
                      alt="blockchain_development"
                    />
                  </div>
                  <h3 className="pt-4">Dedicated Support</h3>
                  <p>
                    Our team provides round-the-clock support, ensuring that any
                    issues or questions are promptly addressed to keep your
                    platform running smoothly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/dex/SVG/Seamless_ui_ux.svg"
                      alt="Seamless_ui_ux"
                    />
                  </div>
                  <h3 className="pt-4">Experienced Team</h3>
                  <p>
                    With years of expertise in blockchain and exchange
                    development, our skilled developers create reliable,
                    high-performance margin trading platforms tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/wallet/dw/end_to_end_services.svg"
                      alt="end_to_end_services"
                    />
                  </div>
                  <h3 className="pt-4">Standard Development Practice</h3>
                  <p>
                    We follow industry-standard development practices, ensuring
                    secure, compliant, and scalable platforms with robust
                    functionality and future-ready infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
