import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function NFTLoanHero() {
  return (
    <section
      className="feature-section  py_150"
      aria-label="NFT-Marketplace-Development-Hero-Section-Image"
      style={{
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundImage:
          "url(assets/img/nft_dev/NFT-Marketplace-Development-Hero-Section-Image.jpg)",
      }}
    >
      <div className="auto-container py-5">
        <div className="row align-items-center py-4">
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content-box mr_40">
              <div data-animation-box className="sec-title mb_75">
                <h2 className="text-white">
                  NFT Development & Launching Services
                </h2>
                <p className="text-white pt-3">
                  Join the revolution & partner with us to launch your own NFTs
                  in the market! We design, develop & mint NFTs on different
                  blockchain ecosystems!
                </p>
              </div>
              <TalkToOurTeamBTN />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
        </div>
      </div>
    </section>
  );
}
