import React from "react";

export default function TheHighlights() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Why Smart Contract Audits Are Essential</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/smart-con/risk_mitigation.svg"
                      alt="risk_mitigation"
                    />
                  </div>
                  <h3>Risk Mitigation</h3>
                  <p>
                    Smart contracts handle significant value in the DeFi
                    ecosystem, making security a top priority. Our audit process
                    rigorously tests for vulnerabilities, helping mitigate
                    financial and operational risks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/smart-con/improved_performance.svg"
                      alt="improved_performance"
                    />
                  </div>
                  <h3>Improved Performance</h3>
                  <p>
                    Beyond security, we assess your contract’s performance and
                    efficiency, offering optimization recommendations that can
                    enhance speed and reduce gas fees.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="pb-2">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/smart-con/compliance_transparency.svg"
                      alt="compliance_transparency"
                    />
                  </div>
                  <h3>Compliance & Transparency</h3>
                  <p>
                    Our audits ensure that your smart contracts are compliant
                    with industry standards. By working with Xeer Technology,
                    you instill confidence in users and investors by providing a
                    secure and compliant DeFi product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
