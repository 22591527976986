import React from "react";

export default function Journey() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Crypto Launchpad?</h2>
              <p>
                A crypto launchpad is a platform that enables blockchain
                projects to raise capital by offering early-stage token sales to
                investors, often at a discounted price before the token is
                publicly available on exchanges. Launchpads are typically used
                for Initial DEX Offerings (IDOs), Initial Coin Offerings (ICOs),
                or other token sale models.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
