import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CosmosHero from "./CosmosHero";
import CosmosSDK from "./CosmosSDK";
import CosmosSDKlaunch from "./CosmosSDKlaunch";
import CosmosSuite from "./CosmosSuite";
import CosmosNadcab from "./CosmosNadcab";
import { Helmet } from "react-helmet";

export default function Cosmos() {
  return (
    <>
      <Helmet>
        <title> Cosmos Blockchain Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Build scalable and interoperable blockchain solutions with Cosmos SDK. Xeer specializes in creating efficient dApps and protocols."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <CosmosHero />
        <CosmosSDK />
        <CosmosNadcab />
        <CosmosSuite />
        <CosmosSDKlaunch />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
