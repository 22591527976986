import React from "react";

export default function WeGoBeyond() {
  return (
    <>
      <section className="contact-info-section centred pt_80 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Aspects of our NFT Collection Launch Service</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/custom_nft_design_&_creation.svg"
                      alt="custom_nft_design_&_creation"
                    />
                  </div>
                  <h3>Custom NFT Design & Creation</h3>
                  <p>
                    From concept to creation, our team helps you develop
                    visually stunning and one-of-a-kind NFTs tailored to your
                    project’s theme and goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/smart_contract_development.svg"
                      alt="smart_contract_development"
                    />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    We create secure and efficient smart contracts that govern
                    the minting, ownership transfer, and royalties for your NFT
                    collection, ensuring transparency and trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/blockchain_integration.svg"
                      alt="blockchain_integration"
                    />
                  </div>
                  <h3>Blockchain Integration</h3>
                  <p>
                    We offer multi-chain support, allowing you to choose from
                    top blockchain networks like Ethereum, Solana, Binance Smart
                    Chain, and more for launching your NFT collection.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/marketplace_listing.svg"
                      alt="marketplace_listing"
                    />
                  </div>
                  <h3>Marketplace Listing & Launch Strategy</h3>
                  <p>
                    We assist you in listing your NFTs on popular marketplaces
                    such as OpenSea, Rarible, and Foundation or help you build a
                    custom marketplace.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/royalty_management.svg"
                      alt="royalty_management"
                    />
                  </div>
                  <h3>Royalty Management</h3>
                  <p>
                    Automate royalty payments for secondary sales, ensuring you
                    benefit from the continued success of your NFTs as they are
                    bought and sold on the market.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="">
                    <img
                      className="pb-4"
                      style={{ width: "80px" }}
                      src="assets/img/nft_dev/n_c_l/security_&_scalability.svg"
                      alt="security_&_scalability"
                    />
                  </div>
                  <h3>Security & Scalability</h3>
                  <p>
                    We prioritize the security of your collection with rigorous
                    audits and encryption, while ensuring scalability to support
                    high traffic during your launch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
