import React from "react";

export default function Platform() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Crypto Derivatives Exchange?</h2>
              <p>
                A crypto derivatives exchange is a trading platform where users
                can trade financial contracts based on the value of
                cryptocurrencies, without actually owning the underlying assets.
                These contracts, called derivatives, include futures, options,
                and perpetual swaps, allowing traders to speculate on the price
                movements of cryptocurrencies like Bitcoin or Ethereum.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
