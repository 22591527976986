import React from "react";

export default function BlockchaininTransport() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "rgb(7, 21, 34)" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Why Choose Xeer Technology for Your Enterprise Blockchain Needs?
            </h2>
            <p className="text-white py-3">
              Our expertise across industries ensures a deep understanding of
              your business needs, providing innovative, compliant, and scalable
              blockchain solutions that enhance efficiency and trust within your
              organization.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one m_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
