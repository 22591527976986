import React from "react";

export default function PresentingNFT() {
  return (
    <>
      <section className="feature-section py_150 bg-black">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-inner">
                <figure className="image">
                  <img
                    src="assets/img/nft_dev/nft-gaming-platform-development-by-xeer-technology.png"
                    alt="nft-gaming-platform-development-by-xeer-technology"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column pt-5">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    NFT Gaming Platform Development By Xeer Technology
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    At Xeer Technology, we specialize in NFT Gaming Platform
                    Development that merges the excitement of gaming with the
                    innovative power of blockchain technology. Our dedicated
                    team is committed to creating immersive gaming experiences
                    where players can truly own, trade, and earn from their
                    in-game assets through non-fungible tokens (NFTs).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
