import React, { useState } from "react";

export default function LowerMaintenance() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      <section
        className="faq-section testimonial-page-one pt_140 pb_150"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/010.png)",
        }}
      >
        <div className="auto-container">
          <div data-animation-box className="sec-title centred mb_55">
            <h2 className="">Benefits of Launching a Decentralized Exchange</h2>
            <p className="pt-3">
              Decentralized exchange brings attractive benefits for developers
              such as reducing lower operational cost, revenue opportunities,
              and fostering long-term innovation.
            </p>
          </div>
          <div className="inner-box mr_30">
            <ul className="accordion-box">
              <li className="accordion block active-block">
                <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(0)} />
                  <h4 className="">Lower Maintenance and Operational Costs</h4>
                </div>
                <div className={`acc-content ${toggle === 0 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      A DEX operates on smart contracts, reducing the need for
                      extensive back-end infrastructure and maintenance
                      typically required for centralized exchanges. This results
                      in lower ongoing operational costs, especially as there
                      are no intermediaries or centralized servers to manage.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(1)} />
                  <h4 className="">Automated Processes</h4>
                </div>
                <div className={`acc-content ${toggle === 1 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Smart contracts automate essential functions like trade
                      execution, liquidity provision, and transaction
                      settlements, minimizing the need for manual intervention.
                      This reduces the risk of human error and cuts down on
                      development resources post-launch.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(2)} />
                  <h4 className="">Increased Security with Decentralization</h4>
                </div>
                <div className={`acc-content ${toggle === 2 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Since DEXs don’t custody user funds, developers can focus
                      less on building complex systems for fund management and
                      more on securing smart contracts. This makes the platform
                      less vulnerable to large-scale hacks and security breaches
                      that often plague centralized exchanges.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(3)} />
                  <h4 className="">Scalability with Modular Architecture</h4>
                </div>
                <div className={`acc-content ${toggle === 3 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Developers can design DEXs with a modular architecture,
                      allowing individual components (like trading, liquidity,
                      and governance) to be scaled independently. This enables
                      the platform to handle growing user demands and higher
                      transaction volumes more efficiently.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(4)} />
                  <h4 className="">
                    Global User Access with Fewer Legal Barriers
                  </h4>
                </div>
                <div className={`acc-content ${toggle === 4 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Without a central authority, DEXs typically have fewer
                      legal requirements related to customer onboarding, such as
                      KYC/AML. This can simplify the launch process and allow
                      for greater global user participation without the overhead
                      of extensive legal compliance in multiple regions.
                    </p>
                  </div>
                </div>
              </li>

              <li className="accordion block">
                <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(5)} />
                  <h4 className="">Customizability and Innovation</h4>
                </div>
                <div className={`acc-content ${toggle === 5 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Developers can implement innovative DeFi features like
                      yield farming, staking, or governance tokens, enhancing
                      the platform's functionality and attracting more users.
                      The open-source nature of many DEX protocols also allows
                      for rapid customization and innovation.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 6 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(6)} />
                  <h4 className="">Revenue Through Tokenization and Fees</h4>
                </div>
                <div className={`acc-content ${toggle === 6 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Developers can generate revenue by creating a native token
                      for the DEX, used for governance, rewards, or transaction
                      fees. Additionally, transaction fees collected from trades
                      or liquidity provisioning can provide ongoing revenue
                      streams.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 7 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(7)} />
                  <h4 className="">Community-Driven Growth</h4>
                </div>
                <div className={`acc-content ${toggle === 7 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      By launching a DEX, developers can foster a
                      community-driven project through decentralized governance.
                      Users can vote on protocol upgrades, new features, or
                      token listings, allowing the platform to evolve based on
                      market needs without the burden of centralized
                      decision-making.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 8 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(8)} />
                  <h4 className="">Cross-Chain Expansion Opportunities</h4>
                </div>
                <div className={`acc-content ${toggle === 8 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Developers can build cross-chain capabilities into the
                      DEX, enabling users to trade assets across multiple
                      blockchains. This flexibility attracts a broader audience,
                      enhancing platform usability and creating new development
                      opportunities for interoperable solutions.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
