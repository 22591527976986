import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import DexAggregatorHero from "./DexAggregatorHero";
import WhatInch from "./WhatInch";
import LookingD from "../About-Us/LookingD";
import CloneApp from "./CloneApp";
import CloneSoftware from "./CloneSoftware";
import Supported from "./Supported";
import HighLiquidity from "./HighLiquidity";
import WhyChoDexA from "./WhyChoDexA";
import PartnerWithXeer from "./PartnerWithXeer";
import { Helmet } from "react-helmet";

export default function DexAggregator() {
  return (
    <>
      <Helmet>
        <title>DEX Aggregator Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Aggregate liquidity across multiple platforms. Xeer offers cutting-edge DEX aggregator solutions for seamless trading experiences."
        />
      </Helmet>
      <div>
        <Header />
        <DexAggregatorHero />
        <WhatInch />
        <CloneApp />
        <CloneSoftware />
        <HighLiquidity />
        <Supported />
        <PartnerWithXeer />
        <WhyChoDexA />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
