import React from "react";

export default function Exclusive() {
  return (
    <>
      <section className="contact-info-section centred py_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Major Functionalities of Ethereum Wallet</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block ">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <img
                      src="assets/img/secure_storage_for_eth_and_tokens.svg"
                      alt="secure_storage_for_eth_and_tokens"
                      style={{ height: "64px", width: "64px" }}
                    />
                  </div>
                  <h3 className="text-start pt-3">
                    Secure Storage For ETH and Tokens
                  </h3>
                  <p className="text-start">
                    Safely stores Ether (ETH) and Ethereum-based tokens (ERC-20,
                    ERC-721), ensuring secure access through encryption and
                    private key management.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block ">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <img
                      src="assets/img/sending_&_receiving_assets.svg"
                      alt="sending_&_receiving_assets"
                      style={{ height: "64px", width: "64px" }}
                    />
                  </div>
                  <h3 className="text-start pt-3">
                    Sending & Receiving Assets
                  </h3>
                  <p className="text-start">
                    Facilitates the easy transfer of Ether and tokens between
                    users by sending and receiving funds with simple address
                    inputs and real-time confirmations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 info-block ">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <img
                      src="assets/img/interaction_with_smart_contracts_&_dapps.svg"
                      alt="interaction_with_smart_contracts_&_dapps"
                      style={{ height: "64px", width: "64px" }}
                    />
                  </div>
                  <h3 className="text-start pt-3">
                    Interaction with Smart Contracts & dApps
                  </h3>
                  <p className="text-start">
                    Enables users to interact with Ethereum smart contracts and
                    decentralized applications (dApps) for activities like DeFi,
                    NFTs, and token swaps directly from the wallet.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 info-block ">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="text-start">
                    <img
                      src="assets/img/custom_gas_fee_management_01.svg"
                      alt="custom_gas_fee_management_01"
                      style={{ height: "64px", width: "64px" }}
                    />
                  </div>
                  <h3 className="text-start pt-3">Custom Gas Fee Management</h3>
                  <p className="text-start">
                    Allows users to set and adjust gas fees for transactions,
                    optimizing speed and cost based on network conditions and
                    user preferences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
