import React from 'react'
import Header from '../Header'
import Footar from '../Footar'
import ContactHero from './ContactHero'
import ContactUs from '../ContactUs'


export default function ContactUsP() {
  return (
    <div>
      <Header/>
      <ContactUs/>
      {/* <ContactHero/> */}
      <Footar/>
    </div>
  )
}
