import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import TronWalletHero from "./TronWalletHero";
import Effective from "./Effective";
import Exclusive from "./Exclusive";
import OurTalented from "./OurTalented";
import Harness from "./Harness";
import FuturisticTRON from "./FuturisticTRON";
import LookingD from "../About-Us/LookingD";
import { Helmet } from "react-helmet";

export default function TronWallet() {
  return (
    <>
      <Helmet>
        <title>Ethereum Wallet Development | Xeer Technology</title>
        <meta
          name="description"
          content=" Develop robust Ethereum wallets for secure transactions. Xeer ensures high performance and advanced security measures."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <TronWalletHero />
        <Effective />
        <Exclusive />
        <OurTalented />
        <Harness />
        <FuturisticTRON />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
