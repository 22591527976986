import React from "react";

export default function KeyFeatures() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2  py_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-71)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Elevate your exchange with our ready-to-use white label crypto
              exchange software
            </h2>
            <p>
              Transform your vision into a fully branded crypto exchange with
              our customizable, user-friendly platform, ready for instant
              deployment and seamless scalability.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/spot_margin_&_futures_trading.svg"
                      alt="spot_margin_&_futures_trading"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Spot, Margin & Futures Trading</h3>
                    <p>
                      Empower your users with a comprehensive suite of trading
                      options, including spot, margin, and futures trading,
                      allowing them to maximize their investment strategies and
                      navigate market fluctuations with confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/unlimited_crypto_assets_support.svg"
                      alt="unlimited_crypto_assets_support"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Unlimited Crypto Assets Support</h3>
                    <p>
                      Offer an extensive range of cryptocurrencies to meet the
                      diverse needs of your users, ensuring they can trade
                      popular coins as well as emerging tokens, all from a
                      single, easy-to-use platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "399px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/easy_new_blockchain_integration.svg"
                      alt="easy_new_blockchain_integration"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Easy New Blockchain Integration</h3>
                    <p>
                      Seamlessly integrate new blockchain technologies to expand
                      your exchange’s capabilities, enabling support for the
                      latest tokens and decentralized applications, enhancing
                      your platform's competitiveness in the ever-evolving
                      crypto landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "388px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/kyc _aml_window.svg"
                      alt="kyc _aml_window"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">KYC & AML Window</h3>
                    <p>
                      Implement robust security measures with our integrated
                      Know Your Customer (KYC) and Anti-Money Laundering (AML)
                      features, ensuring compliance with regulatory standards
                      while protecting your platform and users from fraudulent
                      activities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "388px" }}>
                  <div className="">
                    <img
                      style={{ width: "53px" }}
                      src="assets/img/exchange/wle/staking_launchpad.svg"
                      alt="staking_launchpad"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Staking & Launchpad</h3>
                    <p>
                      Engage your user base with enticing staking options that
                      provide passive income opportunities, alongside a
                      dedicated launchpad for new tokens, facilitating initial
                      coin offerings (ICOs) and attracting innovative projects
                      to your exchange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/custom_trading_window.svg"
                      alt="custom_trading_window"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Custom Trading Window</h3>
                    <p>
                      Enhance the user experience with a fully customizable
                      trading window, allowing users to personalize their
                      layout, choose preferred trading pairs, and access
                      advanced tools that cater to their unique trading styles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/news_article_section.svg"
                      alt="news_article_section"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">News & Article Section</h3>
                    <p>
                      Keep your users informed and engaged with a dynamic news
                      and article section that provides real-time updates,
                      insightful analyses, and educational resources, empowering
                      them to make well-informed trading decisions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/custom_pages.svg"
                      alt="custom_pages"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Custom Pages</h3>
                    <p>
                      Create a personalized experience for your users with fully
                      customizable pages, allowing you to add unique content,
                      features, and resources that cater to specific community
                      needs and enhance user engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table">
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/wle/easy_branding_settings.svg"
                      alt="easy_branding_settings"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Easy Branding Settings</h3>
                    <p>
                      Effortlessly tailor your platform’s appearance with
                      intuitive branding settings, enabling you to customize
                      logos, color schemes, and layouts that reflect your unique
                      brand identity and resonate with your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-box pt-5">
            <a href="/contact-us" className="theme-btn btn-one mr_20">
              Get Your White-label Exchange Now
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
