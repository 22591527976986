import React from "react";

export default function Enhance() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>
                Crypto Payment Industry: Transforming Financial Transactions
              </h2>
              <p>
                The crypto payment industry is revolutionizing the way
                individuals and businesses conduct transactions by leveraging
                the power of blockchain technology and digital currencies. As
                cryptocurrencies gain acceptance, the industry is reshaping
                traditional payment models, enhancing efficiency, security, and
                accessibility for users worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
