import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function TransportandLogisticsHero() {
  return (
    <div>
      <section
        className="banner-style-six py_150"
        aria-label="enterprise-blockchain-solutions-for-various-industries"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/securitization/enterprise-blockchain-solutions-for-various-industries.jpg)",
        }}
      >
        <div className="auto-container ">
          <div className="row align-items-center pb_150">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column pt-5">
              <div className="content-box ml_30 pt-%">
                <h1 className="text-white">
                  Enterprise Blockchain Solutions for Various Industries
                </h1>
                <p className="text-white py-4">
                  At Xeer Technology, we empower businesses across diverse
                  sectors by integrating blockchain technology, creating
                  tailored, secure, and efficient solutions that drive
                  innovation, enhance transparency, and optimize operations.
                </p>
                <div className="btn-box mt_40">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 " />
          </div>
        </div>
      </section>
    </div>
  );
}
