import React from "react";

export default function Layer0BlockchainTechnology() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: 'url("assets/images/shape/shape-71")' }}
        />
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Xeer's Binance Smart Chain Development Services</h2>
            <p className="pt-3">
              Xeer Technology provides comprehensive development services for
              projects seeking to harness the benefits of Binance Smart Chain.
              Our experienced blockchain developers specialize in designing
              custom solutions tailored to your specific business goals.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className="py-3">BSC DApp Development</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      We build fast, efficient decentralized applications on
                      BSC, delivering seamless user experiences with optimized
                      performance for DeFi, NFT, and gaming platforms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className="py-3">
                      Smart Contract Development and Audit
                    </h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      Our team develops secure, reliable smart contracts on BSC
                      and conducts thorough audits to safeguard your project
                      from potential vulnerabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className="py-3 ">DeFi Platform Development</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      Xeer Technology creates cutting-edge DeFi solutions on
                      BSC, including lending platforms, yield farming, and
                      staking applications, with a focus on low fees and
                      high-speed transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className="py-3">BSC Token Development (BEP-20)</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      Launch your own BEP-20 tokens on BSC, fully customized to
                      your project’s needs with features like token staking,
                      liquidity pools, and burn mechanisms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className="py-3">NFT Marketplace Development</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      We design and develop NFT marketplaces on BSC, enabling
                      users to trade, mint, and manage NFTs with cost-effective,
                      high-speed transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className=" py-3">Cross-Chain Solutions</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      Xeer specializes in cross-chain solutions to enhance asset
                      interoperability, ensuring your project seamlessly
                      integrates with other blockchains like Ethereum, Polygon,
                      and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pricing-table p-4 pri-hover"
                  style={{ height: "270px" }}
                >
                  <div className="d-flex align-items-center border border-2 rounded-5 px-3 pri-hover ">
                    <h5 className=" py-3">Exchange Development</h5>
                  </div>
                  <div className="table-header mt-3">
                    <p>
                      Our expertise in building secure, high-performance crypto
                      exchanges on BSC allows for efficient trading, seamless
                      integration with DeFi protocols, and support for
                      multi-chain transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
