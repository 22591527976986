import React from "react";

export default function MaximizeValue() {
  return (
    <>
      <section className="about-section py_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ps-md-5">
                <img
                  src="assets/img/asset-tok/asset-tokenization-services-by-xeer-tchnology.png"
                  alt="asset-tokenization-services-by-xeer-tchnology"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_40">
                  <div data-animation-box className="sec-title mb_25">
                    <h2>Asset Tokenization Services by Xeer Technology</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      At Xeer Technology, we empower businesses to transform
                      their physical and digital assets into secure, tradeable
                      tokens. Our asset tokenization services bridge the gap
                      between traditional finance and blockchain, enabling
                      transparency, liquidity, and broader investment
                      opportunities across various asset classes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
