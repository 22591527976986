import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SmartContractDevHero from "./SmartContractDevHero";
import DeFiSConDev from "./DeFiSConDev";
import Disintermediation from "./Disintermediation";
import { Helmet } from "react-helmet";

export default function SmartContractDev() {
  return (
    <>
      <Helmet>
        <title> Smart Contract Development Services | Xeer Technology</title>
        <meta
          name="description"
          content="Create secure and efficient smart contracts with Xeer. Trusted development for Ethereum, BSC, and more."
        />
      </Helmet>
      <div>
        <Header />
        <SmartContractDevHero />
        <Disintermediation />
        <DeFiSConDev />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
