import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import TokenListingHero from "./TokenListingHero";
import TokenListingServices from "./TokenListingServices";
import DetailedListing from "./DetailedListing";
import { Helmet } from "react-helmet";

export default function TokenListing() {
  return (
    <>
      <Helmet>
        <title>Token Listing Services | Xeer Technology</title>
        <meta
          name="description"
          content="List your cryptocurrency on global platforms like CoinGecko and CoinMarketCap. Xeer offers complete token listing solutions for maximum exposure."
        />
      </Helmet>
      <div>
        <Header />
        <TokenListingHero />
        <TokenListingServices />
        <DetailedListing />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
