import React from "react";

export default function SecurityPractices() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for Margin Trading Exchange
              Development?
            </h2>
            <p className="p-3">
              Xeer Technology stands out as a premier choice for blockchain
              development due to our commitment to delivering tailored solutions
              that meet your unique business needs. Our experienced team of
              developers brings years of expertise in blockchain technology,
              ensuring that your platform is built on a foundation of
              reliability and performance.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/se/dedicated_support.svg"
                      alt="dedicated_support"
                    />
                  </div>
                  <h3 className="pt-4">Dedicated Support</h3>
                  <p>
                    Our team provides round-the-clock support, ensuring that any
                    issues or questions are promptly addressed to keep your
                    platform running smoothly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/se/experienced_team.svg"
                      alt="experienced_team"
                    />
                  </div>
                  <h3 className="pt-4">Experienced Team</h3>
                  <p>
                    With years of expertise in blockchain and exchange
                    development, our skilled developers create reliable,
                    high-performance margin trading platforms tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/se/standard_development_practice.svg"
                      alt="standard_development_practice"
                    />
                  </div>
                  <h3 className="pt-4">Standard Development Practice</h3>
                  <p>
                    We follow industry-standard development practices, ensuring
                    secure, compliant, and scalable platforms with robust
                    functionality and future-ready infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
