import React from "react";
import Header from "../../Coman/Header";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Layer2Hero from "./Layer2Hero";
import Layer2Solutions from "./Layer2Solutions";
import Layer2Mechanisms from "./Layer2Mechanisms";
import Layer2Performance from "./Layer2Performance";
import Layer2Presenting from "./Layer2Presenting";
import { Helmet } from "react-helmet";

export default function Layer2BlockchanNetwork() {
  return (
    <>
      <Helmet>
        <title>
          Polkadot Blockchain Development Services | Xeer Technology
        </title>
        <meta
          name="description"
          content="Build interoperable blockchain networks with Polkadot. Xeer provides custom parachains and relay chain solutions."
        />
      </Helmet>
      <div class="boxed_wrapper ltr">
        <Header />
        <Layer2Hero />
        <Layer2Presenting />
        <Layer2Solutions />
        <Layer2Performance />
        <Layer2Mechanisms />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
