import React from "react";

export default function BenefitsOfOur() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <h1 className="text-center p-3">
              Benefits of our P2P Exchange Software
            </h1>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/institutional_grade_security.svg"
                        alt="institutional_grade_security"
                      />
                    </div>
                    <h5 className="pt-4 ps-3">Institutional-grade security</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/scalability.svg"
                        alt="scalability"
                      />
                    </div>
                    <h5 className="p-3">Scalability</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/lightening_speed_transactions.svg"
                        alt="lightening_speed_transactions"
                      />
                    </div>
                    <h5 className="pt-2 ps-2">Lightening speed transactions</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/quick_time_to_market.svg"
                        alt="quick_time_to_market"
                      />
                    </div>
                    <h5 className="p-3">Quick time-to-market</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/self_explanatory_interface.svg"
                        alt="self_explanatory_interface"
                      />
                    </div>
                    <h5 className="p-3">Self-explanatory interface</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "50px" }}
                        src="assets/img/exchange/ptop/built_by_blockchain_experts.svg"
                        alt="built_by_blockchain_experts"
                      />
                    </div>
                    <h5 className="p-3">Built by blockchain experts</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
