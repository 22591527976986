import React from "react";

export default function CryptoNew() {
  return (
    <>
      <section className="feature-section pb_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="image-inner">
                  <figure className="image">
                    <img
                      src="assets/img/how_crypto_derivatives_exchange_functions.png"
                      alt="how_crypto_derivatives_exchange_functions"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>How Crypto Derivatives Exchange Functions?</h2>
                </div>
                <p>
                  Crypto derivatives exchanges provide leveraged trades,
                  enabling users to amplify their potential gains or losses by
                  borrowing funds to trade larger positions than they could with
                  their own capital. These platforms are popular for hedging,
                  speculation, and risk management.
                </p>
                <p className="py-3">
                  Key features of a crypto derivatives exchange:
                </p>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Futures</h4>
                      <p className="pt-2">
                        Contracts to buy or sell a cryptocurrency at a specific
                        price on a future date.
                      </p>
                    </li>
                    <li>
                      <h4>Options</h4>
                      <p className="pt-2">
                        Contracts that give traders the right, but not the
                        obligation, to buy or sell at a predetermined price
                        before a certain date.
                      </p>
                    </li>
                    <li>
                      <h4>Perpetual Swaps</h4>
                      <p className="pt-2">
                        Similar to futures, but without an expiry date, allowing
                        continuous trading.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
