import React from "react";

export default function PassiveIncome() {
  return (
    <>
      <section className="glance-section centred py_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a DeFi Staking Platform?</h2>
              <p>
                A DeFi Staking Platform is a decentralized financial application
                that allows users to stake (lock up) their cryptocurrency assets
                in a smart contract in exchange for rewards. These platforms
                enable users to earn passive income by participating in the
                network’s consensus process, liquidity pools, or specific
                decentralized finance (DeFi) protocols without needing
                intermediaries like traditional banks.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
