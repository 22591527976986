import React from "react";

export default function WhatMakes() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Why Choose Xeer Technology for Web3 Developments?</h2>
            <p className="p-3">
              By choosing Xeer Technology for your Web3 development needs,
              you're not just investing in technology; you're partnering with a
              forward-thinking team dedicated to driving innovation and
              delivering exceptional results.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/web3/expertise in_decentralized_technologies.svg"
                      alt="expertise in_decentralized_technologies"
                    />
                  </div>
                  <h3 className="pt-4">
                    Expertise in Decentralized Technologies
                  </h3>
                  <p>
                    Xeer Technology boasts a team of experienced developers and
                    blockchain specialists who are well-versed in the latest
                    Web3 technologies, ensuring high-quality solutions tailored
                    to your specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/wallet/dw/end_to_end_services.svg"
                      alt="end_to_end_services"
                    />
                  </div>
                  <h3 className="pt-4">End-to-End Development Services</h3>
                  <p>
                    From initial consultation and ideation to deployment and
                    ongoing support, we offer comprehensive services that cover
                    every aspect of Web3 application development, making the
                    process seamless for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/web3/focus_on_security.svg"
                      alt="focus_on_security"
                    />
                  </div>
                  <h3 className="pt-4">Focus on Security</h3>
                  <p>
                    We prioritize security in all our projects. Our robust
                    security measures, including smart contract audits and
                    secure coding practices, protect your application and its
                    users from vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/wallet/mwa/customized_solutions.svg "
                      alt="customized_solutions"
                    />
                  </div>
                  <h3 className="pt-4">Customized Solutions</h3>
                  <p>
                    We understand that every business is unique. Our team
                    collaborates closely with clients to develop customized Web3
                    applications that align with their goals and address
                    specific challenges in their industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/web3/innovative_approach.svg"
                      alt="innovative_approach"
                    />
                  </div>
                  <h3 className="pt-4">Innovative Approach</h3>
                  <p>
                    We stay ahead of industry trends and continually explore new
                    technologies and methodologies. Our innovative mindset
                    allows us to implement cutting-edge features that set your
                    Web3 applications apart from the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "370px" }}>
                  <div>
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/exchange/svg/fiat_on.svg"
                      alt="fiat_on"
                    />
                  </div>
                  <h3 className="pt-4">Ongoing Support and Maintenance</h3>
                  <p>
                    Our partnership doesn't end at deployment. We provide
                    ongoing support, maintenance, and updates to ensure your
                    Web3 application remains current and functional in the
                    rapidly evolving digital landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
