import React from "react";

export default function Supported() {
  return (
    <>
      <section className="feature-style-two py_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Clone For Popular DEX Aggregators</h2>
            <p className="pt-3">
              Xeer Technology offers powerful clone solutions for popular DEX
              aggregators, providing DeFi platforms with a shortcut to building
              advanced decentralized trading ecosystems.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 justify-content-center pt-3">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/listing/svg/1_inch.svg"
                    alt="1_inch"
                  />
                  <h5 className="py-3">1inch</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-3">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/listing/svg/jupiter.svg"
                    alt="jupiter"
                  />
                  <h5 className="py-3">Jupiter</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-3">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/listing/svg/matcha.svg"
                    alt="matcha"
                  />
                  <h5 className="py-3">Matcha</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-3">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/listing/svg/para_swap.svg"
                    alt="para_swap"
                  />
                  <h5 className="py-3">ParaSwap</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 justify-content-center pt-3">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "80px" }}
                    src="assets/img/listing/svg/multichain.svg"
                    alt="multichain"
                  />
                  <h5 className="py-2">Multichain </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
