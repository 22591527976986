import React from "react";

export default function Handpicking() {
  return (
    <>
      <section className="about-section pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_40">
                  <div data-animation-box className="sec-title mb_25">
                    <h2>
                      Crypto Asset Management Solutions by Xeer Technology
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      As digital assets transform the global financial
                      landscape, effective crypto asset management has become
                      essential for businesses, institutional investors, and
                      financial service providers. At Xeer Technology, we
                      provide end-to-end crypto asset management solutions,
                      helping you securely manage, monitor, and optimize your
                      digital assets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <img
                  src="assets/img/asset-tok/crypto-asset-management-solutions-by-xeer-technology.png"
                  alt="crypto-asset-management-solutions-by-xeer-technology"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
