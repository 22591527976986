import React from "react";
import TalkToOurTeamBTN from "../Componet/Coman/TalkToOurTeamBTN";

export default function SecurityExchangeHero() {
  return (
    <>
      <section
        className="feature-section py_150"
        aria-label="Security-Token-Exchange-Development-Hero-Section-Banner-Image"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Security-Token-Exchange-Development-Hero-Section-Banner-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box">
                <h1 className="text-white">
                  Security Token Exchange Development
                </h1>
                <p className="text-white py-5">
                  At Xeer Technology, we specialize in developing robust
                  security token trading exchanges that empower businesses and
                  investors to leverage the benefits of blockchain technology.
                </p>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
