import React from "react";

export default function Fundraising() {
  return (
    <>
      <section className="centred pt_120 pb_150">
        <div className="auto-container">
          <div className="content-box">
            <div data-animation-box className="sec-title mb_55">
              <h2>Our IDO Development Process</h2>
              <p className="pt-3">
                We will build your project from concept to launch with a
                structured, secure, and scalable IDO process designed for
                seamless fundraising and maximum market impact.
              </p>
            </div>
          </div>
          <div className="py-5">
            <img
              src="assets/img/coin-dev/our_ido_development_process_section_icon_file.svg"
              alt="our_ido_development_process_section_icon_file"
            />
          </div>
        </div>
      </section>
    </>
  );
}
