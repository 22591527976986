import React from "react";

export default function BlockchainFrameworks() {
  return (
    <>
      <section className="feature-style-two py_100 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Blockchain Frameworks We Utilize for L2/L3 Development</h2>
            <p className="pt-3">
              Our blockchain developers are skilled in building on robust
              frameworks for L2 and L3 blockchains.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/polygon_evm.svg"
                      alt="polygon_evm"
                    />
                  </div>
                  <h3>Polygon zkEVM</h3>
                  <p>
                    Ethereum-compatible scaling using zero-knowledge technology
                    for smart contracts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/sync_hyperchains.svg"
                      alt="sync_hyperchains"
                    />
                  </div>
                  <h3>zkSync Hyperchains</h3>
                  <p>
                    Parallel zkEVM instances achieving consensus on Ethereum
                    Layer 1.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/avalanche_subnets.svg"
                      alt="avalanche_subnets"
                    />
                  </div>
                  <h3>Avalanche Subnets</h3>
                  <p>
                    Custom blockchains supporting scalable, sovereign networks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/sdk.svg"
                      alt="sdk"
                    />
                  </div>
                  <h3>Cosmos SDK</h3>
                  <p>Toolkit for multi-asset PoS and PoA blockchains</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/polkadot_parachains.svg"
                      alt="polkadot_parachains"
                    />
                  </div>
                  <h3>Polkadot Parachains</h3>
                  <p>
                    Permissionless platform supporting Layer 2 and Layer 3
                    custom chains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/arbitrum_orbit.svg"
                      alt="arbitrum_orbit"
                    />
                  </div>
                  <h3>Arbitrum Orbit</h3>
                  <p>
                    Platform supporting custom Layer 2 and Layer 3 blockchains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/parity_substrate.svg"
                      alt="parity_substrate"
                    />
                  </div>
                  <h3>Parity Substrate</h3>
                  <p>
                    Flexible, modular framework for building customized
                    blockchains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "277px" }}>
                  <div className="">
                    <img
                      style={{ width: "70px" }}
                      src="assets/img/blockchain-dev/SVG/op.svg"
                      alt="op"
                    />
                  </div>
                  <h3>OP Stack</h3>
                  <p>
                    Development stack for creating Layer 2 blockchains on the OP
                    Mainnet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
